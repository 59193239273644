import { Box, Step, StepLabel, Stepper, Typography } from "@mui/material";

const MAX_WIDTH = 1280

export default function StagesPanel({stages, maxWidth}) {

    const toLocale = (date) => {
        const options = {
            'month': 'long',
            'day': 'numeric'
        }
        const dateObj =  new Date(date)

        return dateObj.toLocaleDateString('ru-RU', options)
    }

    return (
        <Box sx={{bgcolor: "white.main", width: "100%"}}>
            <Box  sx={{mx: "auto", textAlign: "left", minHeight: 250, py: 2, maxWidth: maxWidth, mx: "auto"}}>
                <Typography variant="h4" color="black" my={2} mx={2} sx={{fontFamily: "MTSWide", fontWeight: "bold"}}>
                    ЭТАПЫ
                </Typography>
                <Box sx={{display: 'flex', position: "static",
                    justifyContent: 'center',
                    alignItems: 'center', maxWidth: MAX_WIDTH, mx: 'auto'}}
                >
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }}}>
                        <Stepper alternativeLabel sx={{py: 2}} activeStep={2}>
                            {stages.map(stage => (
                                <Step key={stage.id}>
                                    <StepLabel >
                                        <Box >
                                            { stage.start_date === stage.end_date ?
                                                <Typography variant="h5" sx={{textAlign: "left", px: 2, fontFamily: "MTSText", fontWeight: "bold"}}>
                                                    до {toLocale(stage.start_date)}
                                                </Typography> : <>
                                                <Typography variant="h5" sx={{textAlign: "left", pl: 2, fontFamily: "MTSText", fontWeight: "bold", display: "inline"}}>
                                                    {toLocale(stage.start_date)} -
                                                </Typography>
                                                <Typography variant="h5" sx={{textAlign: "left", px: 2, fontFamily: "MTSText", fontWeight: "bold", display: "inline"}}>
                                                    {toLocale(stage.end_date)}
                                                </Typography>
                                                </>
                                            }
                                            <Typography variant="h6" sx={{ fontFamily: "MTSText", maxWidth: 300, textAlign: 'left', pl: 2}}>
                                                {stage.description}
                                            </Typography>
                                        </Box>
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                    <Box sx={{ ml: 2, flexGrow: 1, display: { xs: 'none', md: 'flex'}, width: "100%"}}>
                        <Stepper alternativeLabel sx={{py: 2, mx: "auto"}} activeStep={2}>
                            {stages.map(stage => (
                                <Step key={stage.id}>
                                    <StepLabel >
                                        <Box >
                                            { stage.start_date === stage.end_date ?
                                                <Typography variant="h5" sx={{textAlign: "left", pl: 2, fontFamily: "MTSText", fontWeight: "bold"}}>
                                                    до {toLocale(stage.start_date)}
                                                </Typography> : <>
                                                <Typography variant="h5" sx={{textAlign: "left", pl: 2, fontFamily: "MTSText", fontWeight: "bold"}}>
                                                    {toLocale(stage.start_date)} - {toLocale(stage.end_date)} 
                                                </Typography>
                                                </>
                                            }
                                            <Typography variant="h6" sx={{ fontFamily: "MTSText", textAlign: 'left', pl: 2}}>
                                                {stage.description}
                                            </Typography>
                                        </Box>
                                    
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                </Box> 
            </Box>
        </Box>
    )
}
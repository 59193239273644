import { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Link, Typography } from "@mui/material";
import { getNovelty } from "../../middleware/api";
import { ContactPanel } from "../../layouts/Footer";
import { GeneralBanner } from "./HomePage";

const MAX_WIDTH = 1280

export default function NoveltyPage() {

    const params = useParams()
    const navigate = useNavigate()

    const [novelty, setNovelty] = useState({
        id: 0,
        header: "Заголовок",
        summery: "Содержание",
        link_name: "",
        link: ""
    })
    const [objects, setObjects] = useState([])

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const updateNoveltyInfo = useCallback((noveltyInfo) => {
        const nv = {
            id: noveltyInfo['id'],
            header: noveltyInfo['header'],
            summery: noveltyInfo['summary'],
            link_name: noveltyInfo['link_name'],
            link: noveltyInfo['link']
        }
        setNovelty(nv)
        setObjects(noveltyInfo['object_urls'])
    }, [])

    const fetchData = useCallback(async (noveltyId) => {
        setLoading(true)
        try {
            const {data, code} = await getNovelty(noveltyId)
            if (code === 200) {
                updateNoveltyInfo(data)
            }
        } catch(error) {
            
            setError(true)
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        Number(params.noveltyId) >= 0 
            ? fetchData(parseInt(params.noveltyId)) 
            : navigate('/error')
    }, [params.noveltyId])
    

    return (
        <Box 
            sx={{width: "100%", bgcolor: 'secondary.light'}}
        >
            <GeneralBanner 
                title=""
                detials={novelty.header}
                imgUrl={objects[0]}
            />
            <Box sx={{maxWidth: MAX_WIDTH, mx: "auto"}}>
                {novelty.summery.split("[%]").map(paragraph => 
                    <Box key={paragraph}
                        sx={{mt: 4, px: 2}}
                    >
                        <Box sx={{textAlign: "left"}}>
                            {paragraph.split("").map((small_p, i) => 
                                <Typography key={small_p} variant="h6" sx={{fontFamily: "MTSText"}}> 
                                    {small_p}
                                </Typography>
                            )}
                        </Box> 
                    </Box>
                )}
                <Box sx={{textAlign: "left", width: "100%", px: 2}}>
                    <Typography variant="h6" sx={{fontWeight: "bold"}}>
                        <Link
                            underline="none"
                            href={novelty.link}
                        >
                            {novelty.link_name}
                        </Link>
                    </Typography>
                   
                </Box>
                <br />
                <br />
                <ContactPanel />
            </Box> 
        </Box>
    )
}
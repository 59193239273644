import { Box, Button, MenuItem, MenuList, Stack, Typography } from "@mui/material";
import { deleteEstimate, getContestExperts, getMark, postEstimate, postMark } from "../../../middleware/api";
import { useCallback, useEffect, useState } from "react";
import { SetMeal } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const ExpertItem = ({expert, estimateId, callback}) => {

    const handleClick = () => {
        callback(expert?.id, estimateId)
    }

    return (
        <MenuItem sx={{bgcolor: "transparent", width: "100%"}}
            onClick={handleClick}
        >
            <Stack direction="column" sx={{textOverflow: "ellipsis", width: "100%"}}>
                <Typography>
                    {expert.fullname}
                </Typography>
                <Typography color="secondary.main"
                    sx={{width: "100%", overflow: "hidden", textOverflow: "ellipsis"}}
                >
                    {expert.about}
                </Typography>
            </Stack>
        </MenuItem>
    )
}

export default function ContestExpertsSelector(props) {

    const {contestId, proposal, mark, setMark, callback} = props

    const navigate = useNavigate()

    const [contestExperts, setContestExperts] = useState([])
    const [filteredExperts, setFilteredExperts] = useState([])

    const [loading, setLoading] = useState(false)

    const fetchMark = useCallback(async () => {
        setLoading(true)
        try {
            const {data, code} = await getMark(proposal.id)
            if (code === 200) {
                setMark(data)
            }
        } catch(error) {
            console.log(error)
        }
        setLoading(false)
    }, [proposal, setMark])

    const fetchExperts = useCallback(async () => {
        setLoading(true)
        try {
            const {data, code} = await getContestExperts(contestId)
            if (code === 200) {
                setContestExperts(data.experts)
            }
        } catch(error) {
            console.log(error)
        }
        setLoading(false)
    }, [contestId])

    const addExpert = useCallback(async (expertId, estimateId) => {
        setLoading(true)
        const formData = new FormData()
        formData.append("mark_id", proposal.id)
        formData.append('expert_id', expertId)
        try {
            const {data, code} = await postEstimate(formData)
            if (code === 201) {
                setMark(data)
            }
        } catch(error) {
            console.log(error)
        }
        setLoading(false)
    }, [proposal, setMark])

    const delExpert = useCallback(async (expertId, estimateId) => {
        setLoading(true)
        try {
            const {data, code} = await deleteEstimate(estimateId)
            if (code === 201) {
                setMark(data)
            }
        } catch(error) {
            console.log(error)
        }
        setLoading(false)
    }, [setMark])

    const filterExperts = useCallback((newExperts) => {
        let selectedExpertsId = []
        mark?.estimates.map(estimate => selectedExpertsId.push(estimate.expert.id))
        let outputExperts = []
        newExperts.map(newExperts => !selectedExpertsId.includes(newExperts.id) 
            && outputExperts.push(newExperts)
        )
        return outputExperts
    }, [mark?.estimates])

    useEffect(() => {
        !mark && proposal?.status === "approved" && fetchMark()
    }, [mark, proposal?.status, fetchMark])

    useEffect(() => {
        fetchExperts()
    }, [contestId, fetchExperts])

    useEffect(() => {
      setFilteredExperts(filterExperts(contestExperts))
    }, [mark?.estimates, contestExperts, filterExperts])
    
    return (
        <Box sx={{width: "100%"}}>
            {mark && 
                <Box sx={{mb: 3}}>
                    <Typography variant="h6"
                        sx={{fontWeight: "bold"}}
                    >
                        Оценочный лист №{mark.id}
                    </Typography>
                    <Box sx={{my: 2}}>
                        <Typography variant="body"
                            sx={{mt: 2, fontWeight: "bold"}}
                        >
                            Присвоенные эксперты:
                        </Typography>
                        {mark?.estimates?.length > 0 ?
                            <Stack direction="column"
                                sx={{width: "100%"}}
                            >
                                {mark.estimates.map(estimate =>
                                    <ExpertItem key={estimate.expert.id}
                                        expert={estimate.expert}
                                        estimateId={estimate.id}
                                        callback={delExpert}
                                    />
                                )}
                            </Stack>
                        :
                            <Typography sx={{mx: 3}}>
                                -
                            </Typography>
                        }
                    </Box>
                    <Box>
                        <Typography variant="body"
                            sx={{fontWeight: "bold"}}
                        >
                            Эксперты конкурса:
                        </Typography>
                        {filteredExperts && mark?.estimates?.length < 14?
                            <MenuList
                                sx={{width: "100%"}}
                            >
                                {filteredExperts.map(expert =>
                                    <ExpertItem key={expert.id}
                                        expert={expert}
                                        estimateId={0}
                                        callback={addExpert}
                                    />
                                )}
                            </MenuList>
                        :
                            <Typography sx={{mx: 3}}>
                                -
                            </Typography>
                        }
                    </Box>
                    {/* {mark?.estimates?.length === 3 &&
                        <Box sx={{width: "100%", px: 10}}>
                            <Button variant="outlined"
                                onClick={() => navigate('/moder-area/2')}
                                sx={{width: "100%", fontWeight: "bold", minHeight: 50, borderRadius: 5}}
                            >
                                К оценочным листам
                            </Button>
                        </Box>
                    } */}
                </Box>
            }
        </Box>
    )

}
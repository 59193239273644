import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useEffect } from "react";

export default function ProtectedRoute({children}) {

    const navigate = useNavigate()

    const isAuthed = useSelector(state => state.auth.isAuthed)

    useEffect(() => {
        isAuthed && navigate('/settings')
    }, [isAuthed, navigate]);

    return children
}
import { useCallback, useEffect, useState } from "react";
import { Box, Button, Link, Stack, Typography } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import { getStreams } from "../../../middleware/api";
import { VideoPlayer } from "./EducationVideosPanel";

const StreamItem = ({video}) => {
    const [playerOpen, setPlayerOpen] = useState(false)

    return (<>
        <Typography sx={{textAlign: "left", mx: 6}}>
            <CircleIcon sx={{fontSize: 10, mr: 2}} />
            <Link variant="h5" onClick={() => setPlayerOpen(true)}>
                {video.title}
            </Link>
        </Typography>
        <VideoPlayer 
            open={playerOpen}
            setOpen={setPlayerOpen}
            videoUrl={video.objects[0].url}
            videoType={video.objects[0].type}
            videoTitle={video.title}
        />
    </>)
}

export default function StreamsPanel() {
    const [streams, setStreams] = useState([])

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    const fetchData = useCallback(async () => {
        try {
            const {data, code} = await getStreams()
            if(code === 200) {
                setStreams(data.videos)
            }
        } catch(error) {
            console.log(error)
            setError(true)
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])
    
    return (<>
        <Typography variant="h4" 
            color="black" 
            sx={{textAlign: 'left', fontFamily: "MTSWide", fontWeight: "bold", mx: 2}}
        >
            ЗАПИСИ ТРАНСЛЯЦИЙ
        </Typography>
        <br />
        <Stack direction="column">
            {streams.map((video) => 
                <StreamItem key={video.id} video={video} />
            )}
        </Stack>
    </>)

}
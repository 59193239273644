import { useCallback, useEffect, useState } from "react";
import { Box, IconButton, LinearProgress, Stack, Typography } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getNovelties } from "../../middleware/api";
import { ContactPanel } from "../../layouts/Footer";
import { NoveltyItem } from "./components/NoveltiesPanel";
import { rightGradientStyle } from "./HomePage";

const MAX_WIDTH = 1280

const LastNoveltyPanel = ({novelties}) => {

    const maxPage = novelties.length - 1

    const [page, setPage] = useState(0)

    const handlePrevPage = () => {
        if (page === 0) {
            setPage(maxPage)
        } else {
            setPage(page-1)
        }
    }

    const handleNextPage = () => {
        if (page === maxPage) {
            setPage(0)
        } else {
            setPage(page+1)
        }
    }

    const HeaderBox = ({title}) => {
        return (
            <Box sx={{p: 2}}>
                <Box 
                    sx={{width: "100%", textAlign: "left", mx: "auto", py: 4}}
                >
                    <Typography variant="h4" color='white.main' 
                        sx={{textShadow: 2, mt: 2, mb: 4}}
                    >
                       {title}
                    </Typography>
                </Box>
            </Box>       
        )
    }

    return (
        <Box style={rightGradientStyle}
            sx={{bgcolor: "lightblue", borderRadius: 4, position: "relative"}}
        >
            <Stack direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{width: "100%", height: "100%"}}
            >
                <IconButton
                    disabled={novelties.length < 2}
                    onClick={handlePrevPage} 
                    sx={{zIndex: 2}}
                    color="white"
                >
                    <ArrowBackIosNewIcon />
                </IconButton>
                <Box 
                    sx={{
                        bgcolor: "transparent", 
                        width: "86%", 
                        textAlign: "left",
                        zIndex: 0
                    }}
                >
                    <Box sx={{display: 'flex', position: "static",
                        justifyContent: 'center',
                        alignItems: 'center', maxWidth: MAX_WIDTH, mx: 'auto'}}
                    >
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }}}>
                            <Stack direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={4}
                                sx={{width: "100%"}}
                            >
                                <HeaderBox 
                                    title={novelties[page]?.header}
                                />
                                {novelties[page]?.object_urls[0] &&
                                    <img 
                                        src={novelties[page]?.object_urls[0]}
                                        alt="general-preview"
                                        style={{
                                            objectFit: "cover",
                                            maxWidth: "100%", 
                                            maxHeight: 350, color: 'transparent'
                                    }}
                                />
                                }
                            </Stack>
                        </Box>
                        <Box sx={{ ml: 2, flexGrow: 1, display: { xs: 'none', md: 'flex'}, width: "100%"}}>
                            <Stack direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{width: "100%", mt: 4}}
                            >
                                <HeaderBox 
                                    title={novelties[page]?.header}
                                />
                                {novelties[page]?.object_urls[0] &&
                                    <img 
                                        src={novelties[page]?.object_urls[0]}
                                        alt="general-preview"
                                        style={{
                                            objectFit: "cover",
                                            maxHeight: 280
                                        }}
                                    />
                                }
                            </Stack>
                        </Box>
                    </Box>
                </Box>
                <IconButton color="white"
                    disabled={novelties.length < 2}
                    onClick={handleNextPage}
                    sx={{zIndex: 2}}
                >
                    <ArrowForwardIosIcon />
                </IconButton>
            </Stack>  
        </Box>
    )
}

export default function NewsPage() {

    const [novelties, setNovelties] = useState([])
    

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        document.title = "Мы услышим • Новости"
    }, []);

    const fetchData = useCallback(async () => {
        const queryData = {'page': 1, 'per_page': 15}
        try {
            const {data, code} = await getNovelties(queryData)
            if (code === 200) {
                setNovelties(data.novelties)
                setLoading(false)
            }
        } catch(error) {
            switch(error.response.status) {
                case 403:
                    setError(true)
                    break;
                default:
                    console.log(error.response)
            }
            setLoading(false)
        }
    }, [],)


    useEffect(() => {
        fetchData()
    }, [fetchData]);
    

    return (
        <Box sx={{bgcolor: "secondary.light", py: 4}}>
            <Box sx={{maxWidth: MAX_WIDTH, mx: "auto"}}>
                <LastNoveltyPanel 
                    novelties={novelties}
                />
                <br />
                <br />
                <Box sx={{display: "flexbox"}}>
                    {novelties.map(novelty =>
                        <NoveltyItem key={novelty.id}
                            novelty={novelty}
                        />
                    )}
                </Box>
                <br />
                <br />
                <ContactPanel />
            </Box>
        </Box>
 
    )
}
import { useEffect, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Stack, Typography, Link } from "@mui/material";
import { Header } from "./Header";
import { Footer } from "./Footer";
import CookieBanner from "./CookieBanner";
import { CssBaseline } from "@mui/material";
import appInfo from "../app.json"

const Layout = () => {
  const ref = useRef()
  const navigate = useNavigate()

  useEffect(() => {
    try {

    } catch(error) {
        navigate("/error")
    };

    }, [navigate])

    return (
        <div className="App" style={{minWidth: 480}}>
            <CssBaseline />
            <Header />
            <Outlet />
            <CookieBanner />
            <Footer />
            <Stack direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              mx={1}
            >
              <Typography variant="body2" 
                sx={{color: 'secondary.main'}} 
              >
                v:{appInfo["appVersion"]} 
              </Typography>       
              <Link 
                variant="body2"
                underline="hover" 
                sx={{color: 'secondary.main'}}
                href="https://moskva.mts.ru/about/investoram-i-akcioneram/korporativnoe-upravlenie/dokumenti-pao-mts/politika-obrabotka-personalnih-dannih-v-pao-mts"
              >
                {appInfo["appOwner"]} 
              </Link>
              <Typography variant="body2" 
                sx={{color: 'secondary.main'}} 
              >
                2024
              </Typography>   
            </Stack>
        </div>
    )
}

export {Layout}
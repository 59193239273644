import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Alert, Divider, Snackbar, Stack, Typography, Box } from "@mui/material"
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import LoadingButton from '@mui/lab/LoadingButton'
import CenterBox from "../../layouts/CenterBox"
import { PasswordField, RepeatPasswordField } from "./components/fields"
import { recoverUser } from "../../middleware/api";

const TOKEN_REGEX = /^[a-zA-Z0-9_.-]+$/

function RecoverUserPage() {

    const params = useParams()
    const navigate = useNavigate()

    const [password, setPassword] = useState("")
    const [repeatPassword, setRepeatPassword] = useState("")

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [err, setErr] = useState(false)
    const [rejected, setRejected] = useState(false)

    const [errors, setErrors] = useState({
        'password': false, 'repeatPassword': false
    })

    const isError = () => {
        for (const key of Object.keys(errors)) {
            if (errors[key]) {
                return true
            }
        }
        return false
    }

    const resetFields = () => {
        setPassword("")
        setRepeatPassword("")
        setErrors({
            'password': false, 'repeatPassword': false
        })
    }

    const onSuccess = () => {
        setSuccess(true)
        resetFields()
        navigate("/")
    }

    useEffect(() => {
        if (!TOKEN_REGEX.test(params.recover_code) || params.recover_code === "") {
            navigate('/not-found')
        }
    }, [params.recover_code, navigate]);

    useEffect(() => {
        document.title = "Восстановление пользователя"
    }, []);

    const handleError = (field, value) => {
        let updatedValue = {}
        updatedValue[field] = value
        setErrors(errors => ({
            ...errors,
            ...updatedValue
        }))
    }

    const handleSubmit = async () => {
        setLoading(true)

        const formData = new FormData()
        formData.append('new_password', password)
        formData.append('recover_code', params.recover_code)

        try {
            const {code} = await recoverUser(formData)
            if (code === 204) {
                onSuccess()
            
            }
        } catch(error) {
            switch(error.response.status) {
                case 404:
                    setErr(true)
                    break;
                case 409:
                    setRejected(true)
                    break;
                default:
                    console.log(error.response)
            }
            setLoading(false)
        }
    }

    return (
        <CenterBox childen={
            <Box sx={{maxWidth: 500, mx: "auto"}}>
                <Typography
                    variant='subtitle1' color="secondary" sx={{ mb: 1, textAlign: 'left'}}
                >
                    Здесь вы можете изменить пароль от своего аккаунта и, тем самым, восстановить его
                </Typography>
                <Divider variant="middle"/>
                <Stack
                    direction='column'
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    my={2}
                >
                    <PasswordField 
                        password={password}
                        setPassword={setPassword}
                        required
                        disabled={loading}
                        error={errors['password']}
                        setError={handleError}
                    />
                    <RepeatPasswordField 
                        repeatPassword={repeatPassword}
                        setRepeatPassword={setRepeatPassword}
                        original={password}
                        required
                        disabled={password === "" || loading}
                        error={errors['repeatPassword']}
                        setError={handleError}
                    />
                </Stack>
                <LoadingButton
                    onClick={handleSubmit} 
                    disabled={repeatPassword === "" || isError()}
                    variant="outlined" 
                    size="large" 
                    sx={{borderRadius: 5, float: 'right'}}
                    endIcon={<ArrowCircleRightIcon />}
                    loadingPosition="end"
                    loading={loading}
                >
                    Восстановиться
                </LoadingButton>
                <Snackbar
                    open={success} 
                    autoHideDuration={6000} 
                    onClose={() => setSuccess(false)}
                >
                    <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
                        Вы успешно изменили пароль и восстановили аккаунт
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={err} 
                    autoHideDuration={6000} 
                    onClose={() => setErr(false)}
                >
                    <Alert onClose={() => setErr(false)} severity="error" sx={{ width: '100%' }}>
                        Неверный код восстановления
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={rejected} 
                    autoHideDuration={6000} 
                    onClose={() => setRejected(false)}
                >
                    <Alert onClose={() => setRejected(false)} severity="error" sx={{ width: '100%' }}>
                        Указанный пользователь не заморожен для сброса пароля
                    </Alert>
                </Snackbar>
            </Box>
        } />
    )
}

export default RecoverUserPage
import { Box, Button, Stack, Typography, Snackbar, Alert } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { MemberExperienceField, LegalTitleField, MemberAchievementsField, MemberTeamField, MemberTitleField, MemberTypeRadio, TaxpayerField, LegalAddressField, FactAddressField, LegalContactField, LegalEmailField, LegalExperienceField, LegalAchievementsField } from "./fields";
import { useDispatch, useSelector } from "react-redux";
import { deleteMember, getMember, postMember } from "../../../middleware/api";
import { addMemberRole, delMemberRole } from "../../../strore/authSlice";

export default function CreateMemberPanel({currentPage, setMember}) {

    const dispatch = useDispatch()

    const userId = useSelector(state => state.auth.userId)
    const role = useSelector(state => state.auth.role)

    const [type, setType] = useState("individual")
    const [title, setTitle] = useState("")
    const [experience, setExperience] = useState("")
    const [achievements, setAchievements] = useState("")
    const [team, setTeam] = useState("")

    const [taxpayer, setTaxpayer] = useState("")
    const [legalAddress, setLegalAddress] = useState("")
    const [factAddress, setFactAddress] = useState("")
    const [contact, setContact] = useState("")
    const [email, setEmail] = useState("")

    const [loading, setLoading] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [success, setSuccess] = useState(false)
    const [rejected, setRejected] = useState(false)
    const [errAccording, setErrAccording] = useState(false)
    const [errExisting, setErrExisting] = useState(false)

    const [errors, setErrors] = useState({
        "title": false, "experience": false, "achievemennts": false, 
        "team": false, "taxpayer": false, "legaladdress": false, 
        "factAddress": false, "contact": false, "email": false
    })

    let isEmpty = title === "" && experience === "" && 
        achievements === "" && team === "" ? true : false

    let isFilled = title !== "" && experience !== "" && 
        achievements !== "" && team !== "" ? true : false

    const handleError = (field, value) => {
        let updatedValue = {}
        updatedValue[field] = value
        setErrors(errors => ({
            ...errors,
            ...updatedValue
        }))
    }

    const isError = () => {
        for (const key of Object.keys(errors)) {
            if (errors[key]) {
                return true
            }
        }
        return false
    }

    const updateMemberData = useCallback((data) => {
        setType(data.type)
        setTitle(data.title)
        setExperience(data.experience)
        setAchievements(data.achievements)
        setTeam(data.team)
        setTaxpayer(data.taxpayer_number)
        setLegalAddress(data.legal_address)
        setFactAddress(data.fact_address)
        setContact(data.phone)
        setEmail(data.email)
    }, [])

    const clearMemberData = () => {
        setType("individual")
        setTitle("")
        setExperience("")
        setAchievements("")
        setTeam("")
        setTaxpayer("")
        setLegalAddress("")
        setFactAddress("")
        setContact("")
        setEmail("")
    }
    
    const fetchData = useCallback(async () => {
        try {
            const {data, code} = await getMember(userId)
            if (code === 200) {
                updateMemberData(data)
                setMember(true)
                setLoaded(true)
                dispatch(addMemberRole())
               
            }
        } catch(error) {
            console.log(error)
            setMember(false)
            dispatch(delMemberRole())
            setLoaded(false)
        }
        setLoading(false)
    }, [dispatch, setMember, updateMemberData])

    useEffect(() => {
        role === 'member' && currentPage === 0 && fetchData() 
    }, [userId, role, currentPage])

    const handleSubmit = async () => {
        setLoading(true)

        const formData = new FormData()
        formData.append('type', type)
        formData.append('title', title)
        taxpayer !== "" && formData.append('taxpayer_number', taxpayer)
        legalAddress !== "" && formData.append('legal_address', legalAddress)
        factAddress !== "" && formData.append('fact_address', factAddress)
        contact !== "" && formData.append('phone', contact)
        email !== "" && formData.append('email', email)
        formData.append('experience', experience)
        formData.append('achievements', achievements)
        formData.append('team', team)

        try {
            const {data, code} = await postMember(formData)
            if (code === 201) {
                updateMemberData(data)
                setMember(true)
                setLoaded(true)
                dispatch(addMemberRole())
                setSuccess(true)
            }
        } catch(error) {
            switch(error.response.status) {
                case 403:
                    setRejected(true)
                    break;
                case 405:
                    setErrAccording(true)
                    break;
                case 409:
                    setErrExisting(true)
                    break;
           }
            setLoaded(false)
        }
        setLoading(false)
    }

    const handleDelete = async () => {
        setLoading(true)
        try {
            if (role === 'member') {
                const {data, code} = await deleteMember(userId)
                if (code === 201) {
                    clearMemberData()
                    setLoaded(false)
                    setMember(false)
                } 
            } else {
                clearMemberData()
                setLoaded(false)
            }
           
        } catch(error) {
            
        }
        setLoading(false)
    }
    
    return (<>
        <Box sx={{width: "100%"}}>
            <Typography variant="h6" sx={{my: 1}}>
                Информация о заявителе проекта:
            </Typography>
            <Stack direction="column" spacing={1.5}>
                <MemberTypeRadio 
                    type={type}
                    setType={setType}
                    disabled={loading || loaded}
                />
                { type === 'individual' 
                    ? <>
                        <MemberTitleField 
                            title={title}
                            setTitle={setTitle}
                            required
                            disabled={loading || loaded}
                            error={errors['title']}
                            setError={handleError}
                        />
                        <MemberAchievementsField 
                            achievements={achievements}
                            setAchievements={setAchievements}
                            required
                            disabled={loading || loaded}
                            error={errors['achievements']}
                            setError={handleError}
                        />
                        <MemberExperienceField 
                            experience={experience}
                            setExperience={setExperience}
                            required
                            disabled={loading || loaded}
                            error={errors['experience']}
                            setError={handleError}
                        />
                    </> 
                    : <>
                        <LegalTitleField 
                            title={title}
                            setTitle={setTitle}
                            required
                            disabled={loading || loaded}
                            error={errors['title']}
                            setError={handleError}
                        />
                        <TaxpayerField 
                            taxpayer={taxpayer}
                            setTaxpayer={setTaxpayer}
                            disabled={loading || loaded}
                            error={errors['taxpayer']}
                            setError={handleError}
                        />
                        <LegalAddressField
                            legalAddress={legalAddress}
                            setLegalAddress={setLegalAddress}
                            disabled={loading || loaded}
                            error={errors['legalAddress']}
                            setError={handleError}
                        />
                        <FactAddressField 
                            factAddress={factAddress}
                            setFactAddress={setFactAddress}
                            disabled={loading || loaded}
                            error={errors['factAddress']}
                            setError={handleError}
                        />
                        <LegalContactField 
                            contact={contact}
                            setContact={setContact}
                            disabled={loading || loaded}
                            error={errors['contact']}
                            setError={handleError}
                        />
                        <LegalEmailField 
                            email={email}
                            setEmail={setEmail}
                            disabled={loading || loaded}
                            error={errors['email']}
                            setError={handleError}
                        />
                        <LegalExperienceField 
                            experience={experience}
                            setExperience={setExperience}
                            required
                            disabled={loading || loaded}
                            error={errors['experience']}
                            setError={handleError}
                        />
                        <LegalAchievementsField 
                            achievements={achievements}
                            setAchievements={setAchievements}
                            required
                            disabled={loading || loaded}
                            error={errors['achievements']}
                            setError={handleError}
                        />
                    </>
                }
                <MemberTeamField 
                    team={team}
                    setTeam={setTeam}
                    required
                    disabled={loading || loaded}
                    error={errors['team']}
                    setError={handleError}
                />
            </Stack>
            <Stack direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                sx={{my: 2}}
            >
                <Button 
                    variant="contained"
                    disabled={!isFilled || isError() || isEmpty || loaded}
                    onClick={handleSubmit}
                    sx={{fontFamily: "MTSWide", fontWeight: "bold"}}
                >
                    Сохранить участника
                </Button>
                <Button disabled={!loaded}
                    onClick={handleDelete}
                    sx={{fontFamily: "MTSWide", fontWeight: "bold"}}
                >
                    очистить участника
                </Button>
            </Stack>
        </Box>
        <Snackbar
            open={success} 
            autoHideDuration={6000} 
            onClose={() => setSuccess(false)}
        >
            <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
                Вы успешно зарегистрировали участника
            </Alert>
        </Snackbar>
        <Snackbar
            open={rejected} 
            autoHideDuration={6000} 
            onClose={() => setRejected(false)}
        >
            <Alert onClose={() => setRejected(false)} severity="error" sx={{ width: '100%' }}>
                У вас нет прав для создания участника
            </Alert>
        </Snackbar>
        <Snackbar
            open={errAccording} 
            autoHideDuration={6000} 
            onClose={() => setErrAccording(false)}
        >
            <Alert onClose={() => setErrAccording(false)} severity="error" sx={{ width: '100%' }}>
                Вы уже зарегистрировали участника
            </Alert>
        </Snackbar>
        <Snackbar
            open={errExisting} 
            autoHideDuration={6000} 
            onClose={() => setErrExisting(false)}
        >
            <Alert onClose={() => setErrExisting(false)} severity="error" sx={{ width: '100%' }}>
                ИНН, контактынй телефон или жлектронная почта уже существуют в системе
            </Alert>
        </Snackbar>
    </>)
}

import { Route, Routes } from 'react-router-dom'
import { ThemeProvider } from '@emotion/react'
import {PrivateRoute} from './routes'
import { mainTheme } from './assets/theme'
import { Layout } from './layouts/Main'
import { HomePage, InclusiveLecturePage, NewsPage, NoveltyPage, ShowCinemaPage } from './pages/main'
import { ConfirmUserPage, RecoverUserPage, SettingsPage } from './pages/auth'
import { CurrentContestPage, UserPrivatePage } from './pages/competition'
import NotFoundPage from './pages/NotFoundPage'

import './App.css'
import ProtectedRoute from './routes/ProtectedRoute'
import { CssBaseline } from '@mui/material'
import ModerPrivatePage from './pages/competition/ModerPrivatePage'
import ExpertPrivatePage from './pages/competition/ExpertPrivatePage'
import VotingPage from './pages/competition/VotingPage'
import TranslationPage from './pages/main/TranslationPage'

function App() {
  
  return (
    <ThemeProvider theme={mainTheme}>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={
            <HomePage />} 
          />
          <Route path="/show-cinema" element={
            <ShowCinemaPage />
          } />
          <Route path="/inclusive-lecture" element={
            <InclusiveLecturePage />
          } />
          <Route path="/contest" element={
            <CurrentContestPage />
          } />
          <Route path="/news" element={
            <NewsPage />
          } />
          <Route path="/news/:noveltyId" element={
            <NoveltyPage />
          } />
          {/* <Route path="/stream/:page?" element={
            <TranslationPage />
          } /> */}
          <Route path="/user-area/:page?" element={
            <PrivateRoute>
              <UserPrivatePage />
            </PrivateRoute>
          } />
          <Route path='/voting' element={
            <PrivateRoute>
              <VotingPage />
            </PrivateRoute>
          } />
          <Route path="/moder-area/:page?" element={
            <PrivateRoute>
              <ModerPrivatePage />
            </PrivateRoute>
          } />
           <Route path="/expert-area/:page?" element={
            <PrivateRoute>
              <ExpertPrivatePage />
            </PrivateRoute>
          } />
          <Route path="/settings" element={
            <PrivateRoute>
              <SettingsPage />
            </PrivateRoute>
          } />
          <Route path='/confirm-user/:token' element={
            <ProtectedRoute>
              <ConfirmUserPage />
            </ProtectedRoute>
          } />
          <Route path='/recover-user/:recover_code' element={
            <ProtectedRoute>
              <RecoverUserPage />
            </ProtectedRoute>
          } />
          <Route path="*" element={
            <NotFoundPage />} 
          />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import jwtDecode from "jwt-decode"
import { patchToken, postToken } from '../middleware/api'

const decodeJWT = (token) => {
    const decodedToken = jwtDecode(token)
    const user = JSON.parse(decodedToken.sub.replaceAll("'", '"'))
    return [decodedToken.exp, user.id, user.fullname, user.role]
}

export {decodeJWT}

const sendLogin = createAsyncThunk(
    'auth/sendLogin',
    async function(props, {rejectWithValue, dispatch}) {
        const {
            formData, setRejectUser, setRejectEmail, setRejectPassword, setLoading
        } = props
        try {
            const {data, code} = await postToken(formData)
            if (code === 202) {
                dispatch(updateToken(data))
            }
            return data
        } catch(error) {
            setLoading(false)
            switch(error.response.status) {
                case 401: 
                    setRejectPassword(true)
                    break;
                case 403: 
                    setRejectUser(true)
                    break;
                case 404: 
                    setRejectEmail(true)
                    break;
                default:
                    console.log(error)
            }
            return rejectWithValue(error.message)
        }
    }
)

export {sendLogin}

const refreshAccess = createAsyncThunk(
    'auth/refreshAccess',
    async function(_, {rejectWithValue, dispatch}) {
        try {
            const {data, code} = await patchToken()
            if (code === 202) {
                dispatch(updateToken(data))
            }
            return data
        } catch(error) {
            switch(error.response.status) {
                case 400: 
                    dispatch(logOut())
                    break;
                case 401: 
                    dispatch(logOut())
                    break;
                default:
                    console.log(error)
            }
            return rejectWithValue(error.message)
        }
    }
)

export {refreshAccess}

const setError = (state, action) => {
    state.status = 'rejected'
    state.error = action.payload
}

const authSlice = createSlice({
    name: 'auth',
    initialState: { 
        status: null, isAuthed: false, userId: null, fullname: null, role: null, 
        exp: null, timeToUpdate: false
    },
    reducers: {
        updateToken(state, action) {
            const payload = action.payload
            localStorage.setItem('accessToken', payload.access_token)
            const [exp, userId, fullname, role] = decodeJWT(payload.access_token)
            state.isAuthed = true
            state.userId = userId
            state.fullname = fullname
            state.role = role
            state.exp = exp
            state.timeToUpdate = false
        },
        addMemberRole(state) {
            state.role = 'member'
        },
        delMemberRole(state) {
            state.role = 'user'
        },
        updateFullname(state, action) {
            state.fullname = action.payload
        },
        logOut(state) {
            state.status = null
            state.isAuthed = false
            state.userId = null
            state.fullname = null
            state.role = null
            state.exp = null
            state.timeToUpdate = false
            localStorage.removeItem('accessToken')
        },
        checkAccess(state, action) {
            var nowUtc = new Date().toUTCString()
            var nowSeconds = Date.parse(nowUtc) / 1000
            if (nowSeconds > state.exp) {
                state.isAuthed = false
                state.userId = null
                state.fullname = null
                state.role = null
                state.exp = null
                state.timeToUpdate = false
                localStorage.removeItem('accessToken')
            } 
            else if (nowSeconds + 30 > state.exp) {
                state.timeToUpdate = true
            }
        }
    },
    extraReducers: {
        [sendLogin.rejected]: setError,
        [refreshAccess.rejected]: setError,
    }
})

export const { 
    updateToken, updateFullname, logOut, checkAccess, addMemberRole, delMemberRole
} = authSlice.actions;
export default authSlice.reducer;

import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import { leftGradientStyle, rightGradientStyle } from "./HomePage";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const MAX_WIDTH = 1280

const SmallHall = () => {

    const StreamHTML = '<iframe id="9qboro" onload="fc_load_iframe(this)" width="100%" height="570" style="border:none" allow="fullscreen" allowfullscreen></iframe>'

    return (
        <Box
            sx={{p: 2}}
        >
            <div id={1}  dangerouslySetInnerHTML={{__html: StreamHTML}} />
        </Box>
    )
}

const Audience = () => {
    
    const StreamHtml = '<iframe id="2vok96" onload="fc_load_iframe(this)" width="100%" height="570" style="border:none" allow="fullscreen" allowfullscreen></iframe>'
    
    return (
        <Box
            sx={{p: 2}}
        >
            <div id={2} dangerouslySetInnerHTML={{__html: StreamHtml}}/>
        </Box>
    )
}

const Lecture = () => {

    const StreamHTML = '<iframe id="1sus1v" onload="fc_load_iframe(this)" width="100%" height="570" style="border:none" allow="fullscreen" allowfullscreen></iframe>'

    return (
        <Box 
            sx={{p: 2}}
        >
            <div id={3} dangerouslySetInnerHTML={{__html: StreamHTML}}/>
        </Box>
    )
}

const NavigationButtonGroup = ({currentPage, setCurrentPage}) => {

    const navigate = useNavigate()

    const buttonVariant = "contained"
    const buttonColor = "navigationBatton"
    const buttonActiveColor = "primary"
    const buttonPref = {
        borderRadius: 4,
        height: 60,
        fontFamily: "MTSText", 
        fontWeight: "bold",
        width: "100%"
    }

    return (<>
        <Box
            sx={{ px: 2, flexGrow: 1, display: { xs: 'flex', md: 'none', width: "100%"}}}
        >
            <ButtonGroup orientation="vertical"
                sx={{width: "100%"}}
            >
                <Button 
                    // disabled
                    variant={buttonVariant} 
                    sx={buttonPref}
                    color={currentPage === 0 ? buttonActiveColor : buttonColor}
                    href="/stream/1"
                    // onClick={() => setCurrentPage(0)}
                >
                    Главный зал
                </Button>
                <Button
                    variant={buttonVariant} 
                    sx={buttonPref}
                    color={currentPage === 1 ? buttonActiveColor : buttonColor}
                    href="/stream/2"
                    // onClick={() => setCurrentPage(1)}
                >
                    Аудитория
                </Button>
                <Button
                    variant={buttonVariant} 
                    sx={buttonPref}
                    color={currentPage === 2 ? buttonActiveColor : buttonColor}
                    href="/stream/3"
                    // onClick={() => setCurrentPage(2)}
                >
                    Лекторий
                </Button>
            </ButtonGroup>
        </Box>
        <Box sx={{flexGrow: 1, display: { xs: 'none', md: 'flex'}, width: "100%"}}>
            <ButtonGroup 
                sx={{width: "98%", mx: "auto"}}
            >
                <Button
                    // disabled
                    variant={buttonVariant} 
                    sx={buttonPref}
                    color={currentPage === 0 ? buttonActiveColor : buttonColor}
                    href="/stream/1"
                    // onClick={() => setCurrentPage(0)}
                >
                    Главный зал
                </Button>
                <Button
                    variant={buttonVariant} 
                    sx={buttonPref}
                    color={currentPage === 1 ? buttonActiveColor : buttonColor}
                    href="/stream/2"
                    // onClick={() => setCurrentPage(1)}
                >
                    Аудитория
                </Button>
                <Button
                    variant={buttonVariant} 
                    sx={buttonPref}
                    color={currentPage === 2 ? buttonActiveColor : buttonColor}
                    // onClick={() => setCurrentPage(2)}
                    href="/stream/3"
                >
                    Лекторий
                </Button>
            </ButtonGroup>
        </Box>
    </>)
}

const StreamTabs = ({currentPage, setCurrentPage}) => {
    return (
        <>
            {currentPage === 0 && <SmallHall 
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />}
            {currentPage === 1 && <Audience 
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />}
            {currentPage === 2 && <Lecture 
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />}
        </>
    )
}

export default function TranslationPage() {

    const {page} = useParams()
    const navigate = useNavigate()

    const [currentPage, setCurrentPage] = useState(0)


    useEffect(() => {
        document.title = "Мы услышим • Трансляция"
    }, [])


    useEffect(() => {
        try {
            page === "1" && setCurrentPage(0)
            page === "2" && setCurrentPage(1)
            page === "3" && setCurrentPage(2)
        } catch(error) {
            navigate("/error")
        };
    }, [page, navigate])
    
    return (
        <Box style={rightGradientStyle}>
            <Box maxWidth={MAX_WIDTH} sx={{mx: "auto", minHeight: "84vh"}}>
                <Typography variant="h4"
                    sx={{textAlign: "left", color: "white.main", fontFamily: "MTSWide", mx: 2, pt: 6, pb: 2}}
                >
                    ТРАНСЛЯЦИЯ
                </Typography>
                <StreamTabs 
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
                <NavigationButtonGroup 
                    currentPage={currentPage} 
                    setCurrentPage={setCurrentPage} 
                />
            </Box>
            <br />
        </Box>
    )
}

import { useParams } from "react-router-dom";
import { Box, Button, Stack, Typography } from "@mui/material";
import HelloPanel from "../main/components/HelloPanel";
import { useEffect, useState } from "react";
import CreateProposalPanel from "./components/CreateProposalPanel";
import MyProposalsPanel from "./components/MyProposalsPanel";
import { ContactPanel } from "../../layouts/Footer";
import EducationVideosPanel from "../main/components/EducationVideosPanel";

const MAX_WIDTH = 1280

const NavigationButtonGroup = ({currentPage, setCurrentPage}) => {

    const buttonVariant = "contained"
    const buttonColor = "navigationBatton"
    const buttonActiveColor = "primary"
    const buttonPref = {
        borderRadius: 4,
        height: 60,
        fontFamily: "MTSText", 
        fontWeight: "bold"
    }

    return (
        <Stack spacing={2}>
            <Button 
                variant={buttonVariant} 
                sx={buttonPref}
                color={currentPage === 0 ? buttonActiveColor : buttonColor}
                onClick={() => setCurrentPage(0)}
            >
                Конкурс «Мы услышим» 
            </Button>
            <Button 
                variant={buttonVariant} 
                sx={buttonPref}
                color={currentPage === 1 ? buttonActiveColor : buttonColor}
                onClick={() => setCurrentPage(1)}
            >
                Мои заявки
            </Button>
            <Button 
                variant={buttonVariant} 
                sx={buttonPref}
                color={currentPage === 2 ? buttonActiveColor : buttonColor}
                onClick={() => setCurrentPage(2)}
            >
                Видеоинструкции для участников
            </Button>
        </Stack>
    )
}

const UserAreaTabs = ({currentPage, setCurrentPage}) => {

    return (
        <> 
            {currentPage === 0 && <CreateProposalPanel 
                currentPage={currentPage}
                setCurrentPage={setCurrentPage} 
            />}
            {currentPage === 1 && <MyProposalsPanel 
                setCurrentPage={setCurrentPage}
            />}
            {currentPage === 2 && <EducationVideosPanel />}
        </>
    )
}

export default function UserPrivatePage() {

    const {page} = useParams()

    const [currentPage, setCurrentPage] = useState(0)

    useEffect(() => {
        page === "1" && setCurrentPage(0)
        page === "2" && setCurrentPage(1)
        page === "3" && setCurrentPage(2)
    }, [page])
    

    return (
        <Box sx={{bgcolor: "secondary.light", minHeight: "90vh"}}>
            <Box sx={{maxWidth: MAX_WIDTH, mx: 'auto'}}>
                <br/>
                <HelloPanel />
                <br/>
                <Box sx={{display: 'flex', position: "static",
                    justifyContent: 'center',
                    alignItems: 'center', maxWidth: MAX_WIDTH, mx: 'auto'}}
                >
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none', width: "100%"}}}>
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={4}
                            sx={{width: "100%"}}
                        >
                            <Box sx={{width: "100%", px: 2}}>
                                <NavigationButtonGroup 
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </Box>
                            <Box sx={{width: "100%", px: 2}}>
                                <UserAreaTabs 
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </Box>
                        </Stack>
                    </Box>
                    <Box sx={{flexGrow: 1, display: { xs: 'none', md: 'flex'}, width: "100%"}}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="space-between"
                            spacing={4}
                            sx={{width: "100%"}}
                        >
                            <Box sx={{width: "28%"}}>
                                <NavigationButtonGroup 
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </Box>
                            <Box sx={{width: '72%'}}> 
                                <UserAreaTabs 
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </Box>
                        </Stack>
                    </Box>
                </Box> 
                <br/>
            </Box>
            <ContactPanel maxWidth={MAX_WIDTH}/>
            <br />
        </Box>
    )
}
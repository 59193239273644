import { Grid } from "@mui/material"

const CenterBox = ({childen}) => {
    return (
        <Grid container
            position='relative'
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{height: '86vh'}}
        >
            <Grid item p={2} sx={{bgcolor: 'inherit', width: 860, boxShadow: 0}}>
                {childen}
            </Grid>
        </Grid>
    )
}

export default CenterBox
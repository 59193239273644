import { useState, useEffect, useCallback } from "react"
import { Box, Stack, Typography, Button } from "@mui/material"
import { getCinemas } from "../../../middleware/api"

const CinemaItem = ({cinema}) => {

    const toLocale = (date) => {
        const options = {
            'month': 'long',
            'day': 'numeric'
        }
        const dateObj =  new Date(date)

        return dateObj.toLocaleDateString('ru-RU', options)
    }

    return (
        <Box 
            sx={{
                bgcolor: "white.main", m: 1, width: 400, 
                display: "inline-block", borderRadius: 10
            }}
        >
            <Stack direction="column" 
                alignItems="flex-start" 
                justifyContent="space-between"
                spacing={2}
                sx={{width: "100%", height: "100%", p: 5}}
            >
                <Typography variant="h4" 
                    sx={{fontFamily: "MTSText", fontWeight: "bold", color: "primary.main"}}
                >
                    {toLocale(cinema.show_date).toUpperCase()}
                </Typography>
                <Typography variant="h4"
                    sx={{fontFamily: "MTSText", fontWeight: "bold", color: "black.main"}}
                >
                    г. {cinema.city}
                </Typography>
                <Box sx={{textAlign: "left"}}>
                    <Typography
                        variant="h5" 
                    >
                        {cinema.theater}
                    </Typography>
                    <Typography
                        variant="h5"
                    >
                        {cinema.address}
                    </Typography>
                </Box>
                <Button variant="contained"
                    onClick={() => window.open(cinema.link, '_blank')}
                    color="blackButton"
                    sx={{color: "white.main", fontFamily: "MTSWide", fontWeight: "bold", p: 1.5, borderRadius: 5}}
                >
                    Зарегистрироваться
                </Button>
            </Stack>
        </Box>
    )
}

export default function CinemasPanel() {

    const [cinemas, setCinemas] = useState([])

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const fetchData = useCallback(async () => {
        setLoading(true)
        try {
            const {data, code} = await getCinemas()
            if (code === 200) {
                setCinemas(data.cinemas)
            }
        } catch(error) {
            setError(true)
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        fetchData()
    }, [])
    

    return (
        <Box sx={{width: "100%", minHeight: 200, my: 4, px: 2}}>
            <Typography variant="h4" 
                color="black" 
                sx={{textAlign: 'left', fontFamily: "MTSWide", fontWeight: "bold", mb: 2}}
            >
                ГОРОД ПРОВЕДЕНИЯ
            </Typography>
            <Box
                sx={{display: "flexbox"}}
            >
                {cinemas.map((cinema, i) =>
                    <CinemaItem key={i}
                        cinema={cinema}
                    />
                )}
            </Box>
        </Box>
    )

}
import { useEffect, useState } from "react"
import { FormControl, InputLabel, Select, MenuItem, TextField, FormControlLabel,
    Checkbox, Typography, Stack, Link } from "@mui/material"
import RegulationDocumant from "../../../documents/regulations_form.pdf"

const VARIANT = "outlined"

const FULL_REGEX = /^[а-яА-Яa-zA-Z0-9.\-_!?&:;,.«»"'=\s][(\[][)\]]+$/i
const URL_REGEX = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=/]*)$/g

const ReferralField = (props) => {
    const {
        referral, setReferral, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
        const newValue = event.target.value
        newValue === "" && required 
            ? setError("referral", true) 
            : setError("referral", false)
        setReferral(newValue.replace(/\s+/g, ' ', "").trim())
    }

    return (
        <FormControl
            id="referral"
            variant={VARIANT}
            color="secondary"
            error={error}
            required={required}
            disabled={disabled}
            fullWidth
        >
            <InputLabel>Откуда вы узнали о фестивале</InputLabel>
            <Select value={referral} onChange={handleChange}>
                <MenuItem value="">не выбрано</MenuItem>
                <MenuItem value="social_network">Из социальных сетей</MenuItem>
                <MenuItem value="friends">От друзей</MenuItem>
                <MenuItem value="school">От учителей / преподавателей</MenuItem>
                <MenuItem value="family">От членов семьи</MenuItem>
                <MenuItem value="work">От коллег на работе</MenuItem>
                <MenuItem value="other">другое</MenuItem>
            </Select>
        </FormControl>
    )
}

export {ReferralField}

const NominationField = (props) => {
    const {
        nominations, nominationId, setNominationId, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
        const newValue = event.target.value
        newValue === "" && required 
            ? setError("nomination", true) 
            : setError("nomination", false)
        setNominationId(newValue)
    }

    return (
        <FormControl
            id="nomination"
            variant={VARIANT}
            color="secondary"
            error={error}
            required={required}
            disabled={disabled}
            fullWidth
        >
            <InputLabel>Номинация</InputLabel>
            <Select value={nominationId} 
                label="Nomination" 
                onChange={handleChange}
            >
                <MenuItem value="">не выбрано</MenuItem>
                {nominations.map(nomination => 
                    <MenuItem key={nomination.id} value={nomination.id} onChange={handleChange}>
                        {nomination.title}
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    )

}

export {NominationField}

const ProposalTitleField = (props) => {
    const {
        title, setTitle, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
        event.target.value === "" && required 
            ? setError("title", true) 
            : setError("title", false)
            setTitle(event.target.value.replace(/\s+/g, ' ', "").trim())
    }

    return (
        <TextField 
            id="title"
            value={title}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            variant={VARIANT}
            error={error}
            multiline
            label="Название проекта"
            color="secondary"
            helperText="до 100 символов"
            inputProps={{ maxLength: 98 }}
        />
    )
}

export {ProposalTitleField}

const ProposalDescriptionField = (props) => {
    const {
        description, setDescription, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
        event.target.value === "" && required 
            ? setError("description", true) 
            : setError("description", false)
            setDescription(event.target.value.replace(/\s+/g, ' ', "").trim())
    }

    return (
        <TextField 
            id="description"
            value={description}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            variant={VARIANT}
            error={error}
            multiline
            label="Краткое описание проекта"
            helperText="до 1500 символов"
            color="secondary"
            inputProps={{ maxLength: 1400 }}
        />
    )
}

export {ProposalDescriptionField}

const ProposalPurposeField = (props) => {
    const {
        socialPurpose, setSocialPurpose, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
        event.target.value === "" && required 
            ? setError("purpose", true) 
            : setError("purpose", false)
            setSocialPurpose(event.target.value.replace(/\s+/g, ' ', "").trim())
    }

    return (
        <TextField
            id="socialPurpose"
            value={socialPurpose}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            error={error}
            multiline
            variant={VARIANT}
            color="secondary"
            label="Актуальность и социальная значимость проекта"
            helperText="до 1500 символов" 
            inputProps={{ maxLength: 1400 }}
        />
    )
}

export {ProposalPurposeField}

const ProposalAboutField = (props) => {
    const {
        about, setAbout, required=false, 
        disabled=false, error, setError
    } = props

    const handleChange = (event) => {
        event.target.value === "" && required 
            ? setError("about", true) 
            : setError("about", false)
            setAbout(event.target.value.replace(/\s+/g, ' ', "").trim())
    }

    return (
        <TextField 
            id='about'
            value={about}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            error={error}
            multiline
            variant={VARIANT}
            color="secondary"
            label="Механика и реализация проекта"
            helperText="до 1500 символов"
            inputProps={{ maxLength: 1400 }}
        />
    )
}

export {ProposalAboutField}

const ProposalLinkField = (props) => {
    const {
        link, setLink, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
 
        setLink(event.target.value.replace(/\s+/g, ' ', "").trim())
    }

    return (
        <TextField 
            id="link"
            value={link}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            variant={VARIANT}
            error={error}
            label="Ссылка на ресурс с проектом"
            // type="url"
            color="secondary"
            inputProps={{ maxLength: 120 }}
            helperText="
                Вы можете прикрепить любые дополнительные материалы о проекте, 
                в том числе фото и видео (загрузите ссылку на файлообменник)
            "
        />
    )
}

export {ProposalLinkField}

const ProposalTypeField = (props) => {
    const {
        type, setType, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
        const newValue = event.target.value
        newValue === "" && required 
            ? setError("type", true) 
            : setError("type", false)
        setType(newValue.replace(/\s+/g, ' ', "").trim())
    }

    return (
        <FormControl
            id="type"
            variant={VARIANT}
            color="secondary"
            error={error}
            required={required}
            disabled={disabled}
            fullWidth
        >
            <InputLabel>Кем был реализован проект?</InputLabel>
            <Select value={type} onChange={handleChange}>
                <MenuItem value="">не выбрано</MenuItem>
                <MenuItem value="single">Только руководителем</MenuItem>
                <MenuItem value="team">Командой</MenuItem>
            </Select>
        </FormControl>
    )
}

export {ProposalTypeField}

const ProposalConfirmField = (props) => {
    const {
        confirm, setConfirm, required=false, disabled=false
    } = props

    const handleChange = (event) => {
        setConfirm(!confirm)
    } 

    const downloadDocument = () => {
        fetch(RegulationDocumant).then((response) => {
            response.blob().then((blob) => {
                let alink = document.createElement("a")
                alink.href = window.URL.createObjectURL(blob)
                // alink.download = documents.regulation.href 
                alink.click()
            })
        })
    }

    return (
        <Stack direction='column'>
            <FormControlLabel disabled={disabled} required={required} control={
                <Checkbox
                    checked={confirm}
                    onChange={handleChange}
                />
            } label={
                <Typography variant="caption">
                    Выбирая данное поле, Вы соглашаетесь  
                    с <Link onClick={downloadDocument}>
                        положением
                    </Link> фестиваля
                </Typography>
            } />
        </Stack>
    )
}

export {ProposalConfirmField}

const MemberLinksField = (props) => {
    const {
        links, setLinks, required=false, disabled=false, error, setError
    } = props

    const [errorOne, setErrorOne] = useState(false)
    const [errorTwo, setErrorTwo] = useState(false)
    const [errorThree, setErrorThree] = useState(false)

    const handleChange = (field, newValue, setErr) => { 
        if (newValue === "" || URL_REGEX.test(newValue)) {
            setErr(false)
            setError('socialLinks', false)
        } else {
            setErr(true)
            setError('socialLinks', true)
        }
        let updatedValue = {}
        updatedValue[field] = newValue
        setLinks(links => ({
            ...links,
            ...updatedValue
        }))
    }

    const handleOne = (event) => {
        handleChange("linkOne", event.target.value, setErrorOne)
    }

    const handleTwo = (event) => {
        handleChange("linkTwo", event.target.value, setErrorTwo)
    }

    const handleThree = (event) => {
        handleChange("linkThree", event.target.value, setErrorThree)
    }

    useEffect(() => {
        if (!error) {
            setErrorOne(false)
            setErrorTwo(false)
            setErrorThree(false)
        }
    }, [error]);
    
    return (
        <Stack direction="column" 
            spacing={1}
            sx={{textAlign: 'left'}}
        >
            <Typography variant="subtitle1" color="secondary.main">Социальные сети</Typography>
            <TextField 
                id="linkOne"
                value={links['linkOne']}
                onChange={handleOne}
                fullWidth
                required={required}
                disabled={disabled}
                variant={VARIANT}
                label="Первая ссылка"
                error={errorOne}
                color="secondary"
                inputProps={{ maxLength: 150 }}
            />
            {links['linkOne'] !== "" && !errorOne && !disabled &&
                <TextField 
                    id="linkTwo"
                    value={links['linkTwo']}
                    onChange={handleTwo}
                    fullWidth
                    required={required}
                    disabled={disabled}
                    variant={VARIANT}
                    label="Вторая ссылка"
                    error={errorTwo}
                    color="secondary"
                    inputProps={{ maxLength: 150 }}
                />
            }
            {links['linkTwo'] !== "" && !errorTwo &&
                <TextField 
                    id="linkThree"
                    value={links['linkThree']}
                    onChange={handleThree}
                    fullWidth
                    required={required}
                    disabled={disabled}
                    variant={VARIANT}
                    label="Третья ссылка"
                    error={errorThree}
                    color="secondary"
                    inputProps={{ maxLength: 150 }}
                />
            }
        </Stack>
    )
}

export {MemberLinksField}

const IdeaTitleField = (props) => {
    const {
        title, setTitle, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
        event.target.value === "" && required 
            ? setError("title", true) 
            : setError("title", false)
            setTitle(event.target.value.replace(/\s+/g, ' ', "").trim())
    }

    return (
        <TextField 
            id="idea-title"
            value={title}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            variant={VARIANT}
            error={error}
            multiline
            label="Укажите название идеи"
            helperText="до 100 символов"
            color="secondary"
            inputProps={{ maxLength: 99 }}
        />
    )
}

export {IdeaTitleField}

const IdeaReferralField = (props) => {
    const {
        referral, setReferral, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
        const newValue = event.target.value
        newValue === "" && required 
            ? setError("referral", true) 
            : setError("referral", false)
        setReferral(newValue.replace(/\s+/g, ' ', "").trim())
    }

    return (
        <FormControl
            id="referral"
            variant={VARIANT}
            label="Выберите направление"
            color="secondary"
            error={error}
            required={required}
            disabled={disabled}
            fullWidth
        >
            <InputLabel>Выберите направление</InputLabel>
            <Select label="Выберите направление"
                value={referral} 
                onChange={handleChange}
            >
                <MenuItem value="">Не выбранно</MenuItem>
                <MenuItem value="employment">Трудоустройство</MenuItem>
                <MenuItem value="sales">Поддержка продаж</MenuItem>
                <MenuItem value="service">Поддержка обслуживания</MenuItem>
                <MenuItem value="product">Продукты и услуги</MenuItem>
                <MenuItem value="site">Сайт (коммуникации)</MenuItem>
                <MenuItem value="other">Другое</MenuItem>
            </Select>
        </FormControl>
    )
}

export {IdeaReferralField}

const IdeaDescriptionField = (props) => {
    const {
        description, setDescription, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
        
        event.target.value === "" && required 
            ? setError("description", true) 
            : setError("description", false)
            setDescription(event.target.value.replace(/\s+/g, ' ', "").trim())
        
    }

    return (
        <TextField 
            id="idea-description"
            value={description}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            variant={VARIANT}
            error={error}
            multiline
            label="Опишите проблему или текущую ситуацию"
            helperText="до 1500 символов"
            color="secondary"
            inputProps={{ maxLength: 1400 }}
        />
    )
}

export {IdeaDescriptionField}

const IdeaAboutField = (props) => {
    const {
        about, setAbout, required=false, 
        disabled=false, error, setError
    } = props

    const handleChange = (event) => {
      
        event.target.value === "" && required 
            ? setError("about", true) 
            : setError("about", false)
            setAbout(event.target.value.replace(/\s+/g, ' ', "").trim())
      
    }

    return (
        <TextField 
            id='idea-about'
            value={about}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            error={error}
            multiline
            variant={VARIANT}
            color="secondary"
            label="Краткое описание идеи/решение проблемы"
            helperText="до 1500 символов"
            inputProps={{ maxLength: 1400 }}
        />
    )
}

export {IdeaAboutField}

const IdeaPurposeField = (props) => {
    const {
        socialPurpose, setSocialPurpose, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
        
        event.target.value === "" && required 
            ? setError("purpose", true) 
            : setError("purpose", false)
            setSocialPurpose(event.target.value.replace(/\s+/g, ' ', "").trim())
    
    }

    return (
        <TextField
            id="idea-socialPurpose"
            value={socialPurpose}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            error={error}
            multiline
            variant={VARIANT}
            color="secondary"
            label="Ожидаемый результат с указанием целевой аудитории от реализации идеи"
            helperText="до 1500 символов" 
            inputProps={{ maxLength: 1400 }}
        />
    )
}

export {IdeaPurposeField}

const RemarkProposalField = (props) => {
    const {
        remark, setRemark, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
        event.target.value === "" && required 
            ? setError("remark", true) 
            : setError("remark", false)
            setRemark(event.target.value.replace(/\s+/g, ' ', "").trim())
    }

    return (
        <TextField
            id="proposal-remark"
            value={remark}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            error={error}
            multiline
            variant={VARIANT}
            color="secondary"
            label="Комментарий модератора"
            helperText="до 300 символов" 
            inputProps={{ maxLength: 299 }}
        />
    )
}

export {RemarkProposalField}

const ProposalStatusField = (props) => {
    const {
        status, setStatus, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
        const newValue = event.target.value
        newValue === "" && required ? setError("status", true) : setError("status", false)
        setStatus(newValue)
    }

    const statusList = [
        {
            id: 1,
            value: "not_approved",
            title: "на проверке"
        },
        {
            id: 2,
            value: "approved",
            title: "одобрена"
        },
        {
            id: 3,
            value: "rejected",
            title: "отклонена"
        },
        {
            id: 4,
            value: "returned",
            title: "возвращена"
        },
    ]

    return (
        <FormControl
            id="status"
            variant={VARIANT}
            color="secondary"
            error={error}
            required={required}
            disabled={disabled}
            fullWidth
        >
            <InputLabel>Статус</InputLabel>
            <Select value={status} 
                label="Status" 
                onChange={handleChange}
            >   
                 <MenuItem
                    onClick={() => setStatus("")}
                    // value=""
                >
                    не выбрано
                </MenuItem>       
                {statusList.map((status, i) =>
                    <MenuItem
                        onClick={() => setStatus(status.value)}
                        key={i}
                        value={status.value}
                    >
                        {status.title} 
                    </MenuItem>        
                )}
            </Select> 
        </FormControl>
    )
}

export {ProposalStatusField}

const MarkStatusField = (props) => {
    const {
        status, setStatus, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
        const newValue = event.target.value
        newValue === "" && required ? setError("status", true) : setError("status", false)
        setStatus(newValue)
    }

    const statusList = [
        {
            id: 1,
            value: "counting",
            title: "подсчитывается"
        },
        {
            id: 2,
            value: "counted",
            title: "подсчитана"
        }
    ]

    return (
        <FormControl
            id="mark-status"
            variant={VARIANT}
            color="secondary"
            error={error}
            required={required}
            disabled={disabled}
            fullWidth
        >
            <InputLabel>Статус оценки</InputLabel>
            <Select value={status} 
                label="Status of mark" 
                onChange={handleChange}
            >   
                <MenuItem
                    onClick={() => setStatus("")}
                    // value={""}
                >
                    не выбрано
                </MenuItem>       
                {statusList.map((status, i) =>
                    <MenuItem
                        onClick={() => setStatus(status.value)}
                        key={i}
                        value={status.value}
                    >
                        {status.title} 
                    </MenuItem>        
                )}
            </Select> 
        </FormControl>
    )
}

export {MarkStatusField}
import { useSelector } from "react-redux";
import {useNavigate} from "react-router-dom"
import { Box, Stack, Typography, Button } from "@mui/material";
import ListIcon from '@mui/icons-material/List';
import { leftGradientStyle, rightGradientStyle } from "../HomePage";
import EduHands from "../../../assets/educationbanner.png"
import { AuthDialog } from "../../auth";
import { useState } from "react";

export default function EducationBanner() {

    const navigate = useNavigate()

    const isAuthed = useSelector(state => state.auth.isAuthed)

    const [authError, setAuthError] = useState(false)
    const [value, setValue] = useState(1);

    const handleNavigate = () => {
        isAuthed ? navigate('/user-area/3') : setAuthError(true)
    }

    return (
        <Box style={leftGradientStyle}
            sx={{width: "100%", borderRadius: 10}}
        >
             <Box sx={{display: 'flex', position: "static",
                justifyContent: 'center',
                alignItems: 'center', mx: 'auto'}}
            >
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }}}>
                    <Stack
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        spacing={6}
                    >
                        <Stack direction="column" justifyContent="center"
                            sx={{width: "100%", textAlign: "left", minHeight: 150, p: 5}}
                        >
                            <Typography variant="h4" color="white.main" sx={{fontFamily: "MTSWide", fontWeight: "bold"}}>
                                ОНЛАЙН-ОБУЧЕНИЕ
                            </Typography>
                            <Typography variant="h5" color="white.main" sx={{my: 2, fontFamily: "MTSText"}}>
                                Образовательная программа реализуется в онлайн-формате и будет доступна на платформе фестиваля 
                                всем зарегистрированным пользователям   
                            </Typography>
                            <Button variant="contained" size="large"
                                onClick={handleNavigate}
                                sx={{borderRadius: 5, height: 50, fontFamily: "MTSWide", fontWeight: "bold"}}
                            >
                                ПОДРОБНЕЕ
                            </Button>
                        </Stack>
                        <Box sx={{width: "100%", pt: 4}}>
                            <img 
                                alt="hello-hand"
                                src={EduHands}
                                style={{height: "100%"}}
                            />
                        </Box>
                    </Stack>
                </Box>
                <Box sx={{ ml: 2, flexGrow: 1, display: { xs: 'none', md: 'flex'}, width: "100%"}}>
                    <Stack
                        direction="row"
                        alignItems="stretch"
                        justifyContent="center"
                        spacing={16} 
                    >
                        <Box sx={{width: "30%", pt: 4}}>
                            <img 
                                alt="hello-hand"
                                src={EduHands}
                                style={{height: "100%"}}
                            />
                        </Box>
                        <Stack direction="column" justifyContent="center"
                            sx={{width: "60%", textAlign: "left", minHeight: 150}}
                        >
                            <Typography variant="h4" color="white.main" sx={{fontFamily: "MTSWide", fontWeight: "bold"}}>
                                ОНЛАЙН-ОБУЧЕНИЕ
                            </Typography>
                            <Typography variant="h5" color="white.main" sx={{my: 2, fontFamily: "MTSText", maxWidth: "90%"}}>
                                Образовательная программа реализуется в онлайн-формате и будет доступна на платформе фестиваля 
                                всем зарегистрированным пользователям                            
                            </Typography>
                            <Button variant="contained" size="large"
                                onClick={handleNavigate}
                                sx={{borderRadius: 5, maxWidth: 300, height: 50, fontFamily: "MTSWide", fontWeight: "bold"}}
                            >
                                ПОДРОБНЕЕ
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Box>
            <AuthDialog 
                open={authError}
                onClose={() => setAuthError(false)}
                value={value}
                setValue={setValue}
            /> 
        </Box>
    )
}
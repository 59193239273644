import { forwardRef, useEffect, useState } from "react"
import { TextField, FormControlLabel, Checkbox, InputLabel, 
    FormControl, Select, MenuItem, Button, Dialog, DialogTitle, Box, Snackbar, Alert,
    Slider, Grid } from "@mui/material"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateField } from '@mui/x-date-pickers/DateField';
import { ruRU } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { IMaskInput } from "react-imask";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useDispatch } from "react-redux";
import { deleteUser, getRegions } from "../../../middleware/api";
import { logOut } from "../../../strore/authSlice";

const VARIANT = 'outlined'
const SIZE = 'small'

const USERNAME_REGEX = /^[a-zA-Z0-9_]+$/i
const NAME_REGEX = /^[а-яА-я-]+$/i
const PHONE_REGEX = /^([0-9+()-\s]{18,18})+$/i
const FULL_REGEX = /^[a-zA-Zа-яА-я0-9.,-_\s]+$/i
const REGION_REGEX = /^[а-яА-я-.\s]+$/i
const EMAIL_REGEX = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})+$/i
const PASSWORD_REGEX = /^[a-zA-Z0-9]+$/i

export {EMAIL_REGEX}

const EmailField = (props) => {

    const {
        email, setEmail, required=false, disabled=false, error, setError,
    } = props

    const handleChange = (event) => {
        if (EMAIL_REGEX.test(event.target.value)) {
            setError("email", false)
        } else {
            setError("email", true)
        }
        event.target.value === "" && !required && setError("email", false)
        setEmail(event.target.value)
    }

    return (
        <TextField 
            id="email"
            type="email"
            value={email}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            variant={VARIANT}
            label="Электронная почта"
            color="secondary"
            error={!!error}
            inputProps={{ maxLength:99 }}
            size={SIZE}
        />
    )
}

export {EmailField}

const SexField = (props) => {

    const {
        sex, setSex, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
        event.target.value === "" ? setError("sex", true) : setError("sex", false)
        setSex(event.target.value) 
    }

    return (
        <FormControl 
            id="sex"
            variant={VARIANT} 
            color="secondary" 
            error={error} 
            required={required}
            disabled={disabled}
        >
            <InputLabel>Пол</InputLabel>
            <Select value={sex} onChange={handleChange} >
                <MenuItem value="">не выбрано</MenuItem>
                <MenuItem value="male">Мужской</MenuItem>
                <MenuItem value="female">Женский</MenuItem>
            </Select>

        </FormControl>
    )
}

export {SexField}

const NameField = (props) => {

    const {
        name, setName, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
        if (event.target.value === "" || NAME_REGEX.test(event.target.value)) {
            event.target.value === "" && required 
                ? setError("name", true) 
                : setError("name", false)
            setName(event.target.value)
        }
    }

    return (
        <TextField 
            id="name"
            value={name}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            variant={VARIANT}
            error={error}
            label="Имя"
            color="secondary"
            inputProps={{ maxLength: 49 }}
            size={SIZE}
        />
    )
}

export {NameField}

const SurnameField = (props) => {

    const {
        surname, setSurname, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
        if (event.target.value === "" || NAME_REGEX.test(event.target.value)) {
            event.target.value === "" && required ? setError("surname", true) : setError("surname", false)
            setSurname(event.target.value)
        }
    }
    
    return (
        <TextField 
            id="surname"
            value={surname}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            variant={VARIANT}
            label="Фамилия"
            error={!!error}
            color="secondary"
            inputProps={{ maxLength:49 }}
            size={SIZE}
        />
    )
}

export {SurnameField}

const PatronymicField = ({patronymic, setPatronymic, required=false, disabled=false}) => {

    const handleChange = (event) => {
        if (event.target.value === "" || NAME_REGEX.test(event.target.value)) {
            setPatronymic(event.target.value)
        }
    }

    return (
        <TextField 
            id="patronymic"
            value={patronymic}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            variant={VARIANT}
            label="Отчество"
            color="secondary"
            inputProps={{ maxLength:49 }}
            size={SIZE}
        />
    )
}

export {PatronymicField}

const UsernameField = (props) => {

    const {
        username, setUsername, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
        if (event.target.value === "" || USERNAME_REGEX.test(event.target.value)) {
            event.target.value === "" && required ? setError("username", true) : setError("username", false)
            setUsername(event.target.value)
        }
    }
    return (
        <TextField 
            id="username"
            value={username}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            variant={VARIANT}
            label="Пользовательское имя"
            error={error}
            color="secondary"
            inputProps={{ maxLength:49 }}
        />
    )
}

export {UsernameField}

const BirthDateField = (props) => {

    const {
        birthDate, setBirthDate, required=false, disabled=false, error, setError
    } = props

    let date = dayjs()

    const findDif = (year) => {
        return date.year() - year
    }

    const handleChange = (newValue) => {
        if (7 <= findDif(newValue?.year()) && findDif(newValue?.year()) <= 120) {
            setError("birthDate", false)
        } else {
            setError("birthDate", true)
        }
        !newValue && setError(true)
        setBirthDate(newValue)
    }

    return (
        <LocalizationProvider 
            dateAdapter={AdapterDayjs}
            localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
        >
            <DateField 
                id="birthDate"
                format="DD.MM.YYYY"
                value={birthDate}
                onChange={handleChange}
                fullWidth
                required={required}
                disabled={disabled}
                variant={VARIANT}
                error={error}
                label="Дата рождения"
                color="secondary"
                size={SIZE}
            />
        </LocalizationProvider>
    )
}

export {BirthDateField}

const AgeField = (props) => {

    const {
        age, setAge, required=false, disabled=false, error, setError
    } = props

    const handleSliderChange = (event, newValue) => {
        setAge(newValue);
      };
    
      const handleInputChange = (event) => {
        const regex = /^[0-9]+$/
        if (event.target.value === "" || regex.test(event.target.value)) {
            setAge(event.target.value === '' ? 1 : Number(event.target.value))
        }
      };
    
      const handleBlur = () => {
        if (age < 7) {
          setAge(7);
        } else if (age > 100) {
          setAge(100);
        }
      };

    return (
        <Grid container  
            direction="row" 
            columnSpacing={0} 
            alignItems="center" 
            justifyContent="space-between"
        >
            <Grid item sx={{width: 285.5, pl: 3}}>
                <Slider 
                    value={typeof age === 'number' && age > 7 ? age : 7}
                    disabled={disabled}
                    onChange={handleSliderChange}
                    step={1}
                    min={7}
                    max={100}
                    defaultValue={7}
                    color="secondary"
                />
            </Grid>
            <Grid item sx={{ width: 90}}>
                <TextField 
                    id='age'
                    variant={VARIANT}
                    label='Возраст'
                    value={age}
                    disabled={disabled}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    error={error}
                    color="secondary"
                />
            </Grid>
        </Grid>
    )
}

export {AgeField}

const ActivityField = (props) => {

    const {
        activity, setActivity, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
        event.target.value === "" ? setError("activity", true) : setError("activity", false)
        setActivity(event.target.value) 
    }

    return (
        <FormControl 
            id="activity"
            variant={VARIANT} 
            color="secondary" 
            error={error} 
            required={required}
            disabled={disabled}
        >
            <InputLabel>Деятельность</InputLabel>
            <Select value={activity} onChange={handleChange}>
                <MenuItem value="">не выбрано</MenuItem>
                <MenuItem value="studying">Учусь</MenuItem>
                <MenuItem value="working">Работаю</MenuItem>
            </Select>

        </FormControl>
    )
}

export {ActivityField}

const ActivityPlaceField = (props) => {

    const {
        activityPlace, setActivitiyPlace, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
        if (event.target.value === "" || FULL_REGEX.test(event.target.value)) {
            event.target.value === "" ? setError("activityPlace", true) : setError("activityPlace", false)
            setActivitiyPlace(event.target.value)
        }
    }
    return (
        <TextField 
            id="activityPlace"
            value={activityPlace}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            variant={VARIANT}
            label="Место учебы / работы"
            error={error}
            color="secondary"
            inputProps={{ maxLength:49 }}
        />
    )
}

export {ActivityPlaceField}

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="+7 (#00) 000-00-00"
        definitions={{
          "#": /[1-9]/
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
});

TextMaskCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};


const ContactField = (props) => {

    const {
        contact, setContact, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
        if (PHONE_REGEX.test(event.target.value)) {
            setError("contact", false)
        } else {
            setError("contact", true)
        }
        event.target.value === "" && !required && setError("contact", false)
        setContact(event.target.value)
    }

    return (
        <TextField 
            id="contact"
            value={contact}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            variant={VARIANT}
            label="Моб. телефон"
            name="contact"
            InputProps={{
                inputComponent: TextMaskCustom
            }}
            error={error}
            color="secondary"
            inputProps={{ maxLength:49 }}
            size={SIZE}
        />
    )
}

export {ContactField}

const RegionField = ({regionId, setRegionId, required, disabled=false}) => {

    const [regions, setRegions] = useState([])

    const [error, setError] = useState(false)

    const handleChange = (event) => {
        if (event.target.value === "" || REGION_REGEX.test(event.target.value)) {
            event.target.value === "" && required ? setError(true) : setError(false)
            setRegionId(event.target.value)
        }
    }

    useEffect(() => {
        async function fetchData() {
            try {
                const {data, code} = await getRegions()
                if (code === 200) {
                    setRegions(data.regions)
                }
            } catch(error) {
                alert(error)
            }
        }
        fetchData()
    }, [])
    
    return (
        <FormControl fullWidth  size={SIZE} required={required}>
            <InputLabel id="regionId">Регион проживания</InputLabel>
            <Select
                required={required}
                disabled={disabled}
                variant={VARIANT}
                error={error}
                color="secondary"
                value={regionId ? regionId : ""}
                label="Регион проживания"
                onChange={handleChange}
               

            >
                {regions.map(region => 
                    <MenuItem
                        onClick={() => setRegionId(region.id)}
                        key={region.id}
                        value={region.id}
                    >
                        {region.name}
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    )
}

export {RegionField}

const CityField = (props) => {

    const {
        city, setCity, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
        if (event.target.value === "" || REGION_REGEX.test(event.target.value)) {
            event.target.value === "" ? setError("city", true) : setError("city", false)
            setCity(event.target.value)
        }
    }

    return (
        <TextField 
            id="city"
            value={city}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            variant={VARIANT}
            label="Город проживания"
            error={error}
            color="secondary"
            inputProps={{ maxLength:79 }}
        />
    )
}

export {CityField}

const PasswordField = (props) => {

    const {
        password, setPassword, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
        if (event.target.value === "" || PASSWORD_REGEX.test(event.target.value)) {
            event.target.value.length < 7 ? setError("password", true) : setError("password", false)
            event.target.value === "" && !required && setError("password", false) 
            setPassword(event.target.value)
        }
    }

    return (
        <TextField 
            id="password"
            value={password}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            variant={VARIANT}
            label="Пароль"
            error={error}
            type="password"
            color="secondary"
            inputProps={{ maxLength:49}}
            helperText="От 7 до 50 символов"
            size={SIZE}
        />
    )
}

export {PasswordField}

const RepeatPasswordField = (props) => {

    const {
        repeatPassword, setRepeatPassword, original, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
        if (event.target.value === "" || PASSWORD_REGEX.test(event.target.value)) {
            event.target.value.length < 7 ? setError("repeatPassword", true) : setError("repeatPassword", false)
            setRepeatPassword(event.target.value)
        }
    }

    useEffect(() => {
        repeatPassword === original ? setError("repeatPassword", false) : setError("repeatPassword", true)
    }, [original, repeatPassword, setError]);

    return (
        <TextField 
            id="repeatPassword"
            value={repeatPassword}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            variant={VARIANT}
            label="Повторный пароль"
            error={error}
            type="password"
            color="secondary"
            inputProps={{ maxLength:49}}
            helperText="От 7 до 50 символов"
            size={SIZE}
        />
    )
}

export {RepeatPasswordField}

const ConfirmField = ({confirm, setConfirm, required=false, disabled=false}) => {

    const handleChange = () => {
        setConfirm(!confirm)
    }

    return (
        <FormControlLabel disabled={disabled} required={required} control={
            <Checkbox 
                value={confirm}
                onChange={handleChange}
            />
        } label="Согласен на обработку данных" />
    )
}

export {ConfirmField}

const DeleteUserButton = ({userId, loading, setLoading}) => {

    const dispatch = useDispatch()

    const [dialogOpen, setDialogOpen] = useState(false)

    const [success, setSuccess] = useState(false)
    const [warning, setWarning] = useState(false)
    const [error, setError] = useState(false)

    const onSuccess = () => {
        setSuccess(true)
        setLoading(false)
        setTimeout(() => {dispatch(logOut())}, 5000);
    }

    const handleDelete = async () => {
        setLoading(true)
        setDialogOpen(false)
        try {
            const {code} = await deleteUser(userId)
            if (code === 204) {
                onSuccess()
            }
        } catch(error) {
            switch(error.response.status) {
                case 403:
                    setWarning(true)
                    break;
                case 404:
                    setError(true)
                    break;
                default:
                    setDialogOpen(false)
            }
            setLoading(false)
        }
    }

    return (<>
        <LoadingButton
            onClick={() => setDialogOpen(true)}
            size='small'
            sx={{ml: 0.2}}
            loading={loading}
            endIcon={<DeleteOutlineIcon />}
            loadingPosition="end"
        >
            удалить
        </LoadingButton>
        <Dialog 
            open={dialogOpen} 
            onClose={() => setDialogOpen(false)}
        >
            <DialogTitle>
                Вы точно уверены, что хотите удалить свой аккаунт?
            </DialogTitle>
            <Box  sx={{mx: 2, mb: 2}}>
                <Button
                    onClick={handleDelete}
                    variant="contained"
                    color="primary"
                    sx={{float: "left", width: "49%"}}
                >
                    Да!
                </Button>
                <Button
                    onClick={() => setDialogOpen(false)}
                    variant="outlined"
                    color="primary"
                    sx={{float: "right", width: "49%"}}
                >
                    Нет...
                </Button>  
            </Box>
        </Dialog>
        <Snackbar 
            open={success} 
            autoHideDuration={6000} 
            onClose={() => setSuccess(false)}
        >
            <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
                Вы успешно удалили свой аккаунт. Возвращайтесь еще
            </Alert>
        </Snackbar>
        <Snackbar 
            open={warning} 
            autoHideDuration={6000} 
            onClose={() => setWarning(false)}
        >
            <Alert onClose={() => setWarning(false)} severity="warning" sx={{ width: '100%' }}>
                Вы не имеете права доступа удалять этого пользователя
            </Alert>
        </Snackbar>
        <Snackbar 
            open={error} 
            autoHideDuration={6000} 
            onClose={() => setError(false)}
        >
            <Alert onClose={() => setError(false)} severity="warning" sx={{ width: '100%' }}>
                Указанный вами пользователь не существует 
            </Alert>
        </Snackbar>
    </>)
}

export {DeleteUserButton}
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Box, IconButton, Stack, Tooltip, Typography} from '@mui/material'
import FavoriteIcon from '@mui/icons-material/Favorite';
import VotingImage from '../../assets/votingshell.png'
import { GeneralBanner } from '../main/HomePage';
import { deleteLike, getAllLikes, postLike } from '../../middleware/api';
import { getLikes, updateLikes } from '../../strore/likeSlice';

const MAX_WIDTH = 1280

const VoteItem = ({proposal, likedProposals}) => {

    const dispatch = useDispatch()

    const userId = useSelector(state => state.auth.userId)

    const [proposalLikes, setProposalLikes] = useState(proposal.like_count)

    const checkLike = useCallback(() => {
        let likes = []
        likedProposals.map(likedProposal => likes.push(likedProposal.id))
        return likes.includes(proposal.id)
    }, [likedProposals])

    const handleDelLike = useCallback(async () => {
        try {
            const {data, code} = await deleteLike(proposal.id)
            if (code === 201) {
                setProposalLikes(proposalLikes => proposalLikes - 1)
                dispatch(getLikes(userId))
            }
        } catch(error) {
            console.log()
        }
    }, [proposal])

    const handleAddLike = useCallback(async () => {
        try {
            const {data, code} = await postLike(proposal.id)
            if (code === 201) {
                setProposalLikes(data.like_count)
                dispatch(getLikes(userId))
            }
        } catch(error) {
            console.log(error)
        }
    }, [proposal])

    return (
        <Box sx={{height: 200, bgcolor: "white.main", borderRadius: 5, overflow: "hidden"}}>
            <Stack direction="row" justifyContent="space-between" alignItems="center"
                sx={{height: "100%", py: 2}}
            >
                <Stack direction="column" 
                    sx={{width: "100%", px: 6, textAlign: "left"}}
                >
                    <Typography variant='h5'
                        sx={{fontFamily: "MTSText", fontWeight: "bold", overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical"
                        }}
                    >
                        {proposal.title}
                    </Typography>
                    <Typography variant='h6'
                        sx={{ overflow: "hidden", fontFamily: "MTSText",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical"}}
                    >
                        {proposal.description}
                    </Typography>
                </Stack>
                <Stack direction="row"
                    alignItems="center"
                    sx={{width: 200}}
                    spacing={1}
                >
                    {checkLike() 
                        ? <IconButton onClick={handleDelLike}>
                            <FavoriteIcon sx={{fontSize: 40, color: "primary.main"}} />
                        </IconButton>
                        : <IconButton onClick={handleAddLike}>
                            <FavoriteIcon sx={{fontSize: 40, color: "secondary.dark"}} />
                        </IconButton>
                    }
                    <Typography variant='h5'
                        sx={{fontFamily: "MTSText", fontWeight: "bold"}}
                    >
                        {proposalLikes}
                    </Typography>
                </Stack>
            </Stack>
        </Box>
    )
}

const VotesPanel = () => {

    const dispatch = useDispatch()

    const likedProposals = useSelector(state => state.like.proposals)
    const userId = useSelector(state => state.auth.userId)

    const [proposals, setProposals] = useState([])
    const [loading, setLoading] = useState(false)
    
    const fetchData = useCallback(async () => {
        dispatch(getLikes(userId))
        setLoading(true)
        try {
            const {data, code} = await getAllLikes()
            if (code === 200) {
                setProposals(data.proposals)
            }
        } catch(error) {
            console.log(error)
        }
        setLoading(false)
    }, [dispatch, userId])

    useEffect(() => {
        fetchData()
    }, [fetchData])
    
    return (
        <Stack spacing={2}
            sx={{width: "100%"}}
        >
            {proposals.map(proposal =>
                <VoteItem key={proposal.id}
                    proposal={proposal}
                    likedProposals={likedProposals}
                />
            )}
        </Stack>
    )
}

export default function VotingPage() {

    useEffect(() => {
        document.title = "Мы услышим • Голосование"
    }, []);

    return (
        <Box sx={{bgcolor: 'secondary.light'}}>
            <GeneralBanner 
                title="ГОЛОСУЙТЕ ЗА ИДЕЮ"
                imgUrl={VotingImage}
            />
            <Box maxWidth={MAX_WIDTH} sx={{mx: "auto"}}>
                <Typography variant='h6'
                    sx={{my: 4, textAlign: "left"}}
                >
                    Открытое голосование за идеи участников конкурса «Мы услышим» в номинации «Социальная идея».
                    Чтобы проголосовать за идею, нажмите на сердечко напротив описания проекта.  
                </Typography>
                <VotesPanel />
            </Box>
            <br />
        </Box>
    )
}
import { Box, CircularProgress, Dialog, DialogTitle, Divider, Link, Stack, Typography } from "@mui/material";
import { useEffect, useState, useCallback } from "react";

import { MarkStatusField } from "./fields";
import { getMarks } from "../../../middleware/api";
import { NomiantionsPanel } from "./ModerProposalsPanel";

const MarkStatusPanel = ({markStatus, setMarkStatus}) => {
    
    const [errors, setErrors] = useState({
        "status": false
    })

    const handleError = (field, value) => {
        let updatedValue = {}
        updatedValue[field] = value
        setErrors(errors => ({
            ...errors,
            ...updatedValue
        }))
    }

    return (
        <Box sx={{width: "48%", height: "100%", textAlign: "left"}}>
            <MarkStatusField 
                status={markStatus}
                setStatus={setMarkStatus}
                error={errors["status"]}
                setError={handleError}
            />
        </Box>
    )

}

const MarksHeader = () => {
    return (
        <Box sx={{width: "100%", mt: 2}}>
            <Stack direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                sx={{width: "100%", textAlign: "left"}}
            >
                <Box sx={{width: 85, px: 1}}>
                    <Typography>
                        Номер заявки
                    </Typography>
                </Box>
                <Box sx={{ width: "100%"}}>
                    <Typography>
                        Название проекта
                    </Typography>
                </Box>
                <Box sx={{width: 85}}>
                    <Typography>
                        Статус оценки
                    </Typography>
                </Box>
                <Box sx={{width: 85}}>
                    <Typography>
                        Сумма баллов
                    </Typography>
                </Box>
            </Stack>
            <Divider sx={{my: 1}} />
        </Box>
    )
}

const EstimateItem = ({estimate}) => {
    return (
        <Box sx={{width: "100%", bgcolor: "secondary.light", px: 2, mb: 1}}>
            <Stack direction="row" justifyContent="space-between" space={2}>
                <Typography variant="h6">
                    {estimate.expert.fullname}
                </Typography>
                <Typography variant="h5" sx={{color: "primary.main", mx: 1}}>
                    {estimate.grade}
                </Typography>
            </Stack>
            <Typography>
                {estimate.comment}
            </Typography>
        </Box>
    )
}

const MarkItem = ({mark}) => {

    const markStatus = {
        "counting": "идет подсчет",
        "counted": "готово"
    }

    const [open, setOpen] = useState(false)

    return (<>
        <Stack direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={1}
            sx={{width: "100%", textAlign: "left"}}
        >
            <Box sx={{width: 85, bgcolor: "white.main", px: 1}}>
                <Typography>
                    №{mark.id}
                </Typography>
            </Box>
            <Box sx={{ width: "100%", bgcolor: "white.main", px: 1}}>
                <Typography sx={{ overflow: "hidden", 
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",}}
                >
                    <Link color="#000000" onClick={() => setOpen(true)}>
                        {mark.proposal_title}
                    </Link>
                </Typography>
            </Box>
            <Box sx={{width: 85, bgcolor: "white.main", px: 1}}>
                <Typography>
                    {markStatus[mark.status]}
                </Typography>
            </Box>
            <Box sx={{width: 85, bgcolor: "white.main", px: 1}}>
                <Typography>
                    {mark.total.toFixed(1)}
                </Typography>
            </Box>
        </Stack>
        <Dialog open={open} onClose={() => setOpen(false)} sx={{widht: "100%"}}>
            <DialogTitle>
                Оценка №{mark.id}
            </DialogTitle>
            <Stack direction="column" sx={{widht: "100%"}}>
                {mark.estimates.map(estimate =>
                    <EstimateItem key={estimate.id}
                        estimate={estimate}
                    />
                )}
            </Stack>
        </Dialog>
    </>)
}

export default function RatedProposalsPanel() {

    const [nominationId, setNominationId] = useState("")
    const [markStatus, setMarkStatus] = useState("")
    const [marks, setMarks] = useState([])

    const [loading, setLoading] = useState(false)

    useEffect(() => {
      document.title = "Мы услышим • Оценки"
    }, [])

    const fetchData = useCallback(async (nId, mStatus) => {
        setLoading(true)

        const queryData = {contest_id: 1, per_page: 1000}

        if (nId !== "") {
            queryData.nomination_id = nId
        } 
        
        if (mStatus !== "") {
            queryData.status = mStatus
        }

        try {
            const {data, code} = await getMarks(queryData)
            if (code === 200) {
                setMarks(data.marks)
            }
        } catch(error) {
            console.log(error)
        }
        setLoading(false)
    }, [])
    
    useEffect(() => {
        fetchData(nominationId, markStatus)
        window.scrollTo({ top: 0, behavior: "smooth" })
    }, [nominationId, markStatus, fetchData])

    return (<>
        <Stack direction="row"
            justifyContent="space-between"
            sx={{width: "100%"}}
        >
            <NomiantionsPanel 
                nominationId={nominationId}
                setNominationId={setNominationId}
            />
            <MarkStatusPanel 
                markStatus={markStatus}
                setMarkStatus={setMarkStatus}
            />
        </Stack>
        <MarksHeader />
        {loading 
            ? <Box>
                <CircularProgress />
            </Box>
            : <Stack direction="column"
                spacing={1}
                sx={{width: "100%"}}
            >
                {marks.map(mark =>
                    <MarkItem key={mark.id}
                        mark={mark}
                    />
                )}
            </Stack>
        }
    </>)
}
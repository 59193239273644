import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import CenterBox from "../../../layouts/CenterBox"
import { deleteProposal, getMember, getUserProposals, putProposal } from "../../../middleware/api"
import { Button, CircularProgress, Stack, Typography, Box, IconButton, Dialog, DialogTitle, Divider } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

import Image from "../../../assets/blank.png"
import Blank from "../../../assets/blank.svg"
import { IdeaAboutField, IdeaDescriptionField, IdeaPurposeField, IdeaReferralField, IdeaTitleField, ProposalAboutField, ProposalDescriptionField, ProposalLinkField, ProposalPurposeField, ProposalTitleField } from "./fields"
import CreateMemberPanel from "../../main/components/CreateMemberPanel"
import { UploadFileBox } from "../../main"

const ProposalStatus = ({status}) => {

    const templates = {
        "not_upload": ["черновик", "lightgrey"],
        "not_approved": ["на проверке", "#b4e4ff"],
        "rejected": ["отклонена", "#ffcacd"],
        "returned": ["возвращена", "#ffdd81"],
        "approved": ["одобрена", "#abf7a6"]
    }
    
    const [template, setTemplate] = useState("not_approved")

    useEffect(() => {
        setTemplate(status)
    }, [status]);

    return (
        <Box 
            sx={{
                minHeight: 30,  bgcolor: templates[template][1],
                display: 'flex', alignItems: "center", justifyContent: 'center', 
                borderRadius: 3, p: 1
            }}
        >
            <Typography color="secondary" >
                {templates[template][0]}
            </Typography>
        </Box>
    )
}

export {ProposalStatus}

const IndividualFields = () => {

}

const EditProposalDialog = ({open, setOpen, proposal, setProposalUpdated}) => {

    const [member, setMember] = useState()

    const [title, setTitle] = useState(proposal.title)
    const [referral, setReferral] = useState(proposal.referral)
    const [description, setDescription] = useState(proposal.description)
    const [socialPurpose, setSocialPurpose] = useState(proposal.social_purpose)
    const [about, setAbout] = useState(proposal.about)
    const [link, setLink] = useState(proposal.link)

    const [loading, setLoading] = useState(false)

    const [errors, setErrors] = useState({
        "title": false, "description": false, "referral": false,
        "purpose": false, "about": false, "link": false
    })

    let isEmpty = title === "" && description === "" && 
        socialPurpose === "" && about === "" ? true : false

    let isFilled = title !== "" && description !== "" && 
        socialPurpose !== "" && about !== "" ? true : false
        
    const handleError = (field, value) => {
        let updatedValue = {}
        updatedValue[field] = value
        setErrors(errors => ({
            ...errors,
            ...updatedValue
        }))
    }

    const isError = () => {
        for (const key of Object.keys(errors)) {
            if (errors[key]) {
                return true
            }
        }
        return false
    }

    const fetchData = useCallback(async (memberId) => {
        setLoading(false)
        try {
            const {data, code} = await getMember(memberId)
            if (code === 200) {
                setMember(data)
            }
        } catch(error) {
            console.log(error)
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        proposal.member_id && fetchData(proposal.member_id)
    }, [proposal.member_id, fetchData])

    const handleChange = async () => {
        setLoading(true)

        const formData = new FormData()
        title !== "" && title !== proposal.title && formData.append('title', title)
        description !== "" && description !== proposal.description && formData.append('description', description)
        about !== "" && about !== proposal.about && formData.append('about', about)
        socialPurpose !== "" && socialPurpose !== proposal.social_purpose && formData.append('social_purpose', socialPurpose)
        link !== "" && link !== proposal.link && formData.append('link', title)
        proposal.nomination_id === 2 && referral !== "" && referral !== proposal.referral && formData.append('referral', referral)

        try {
            const {data, code} = await putProposal(proposal.id, formData)
            if (code === 201) {
                setProposalUpdated(true)
            }

        } catch(error) {
            console.log(error)
        }
        setLoading(false)
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle variant="h5"
                sx={{fontFamily: "MTSText", fontWeight: "bold"}}
            >
                Внесите изменения в заявку №{proposal.id} и нажмите сохранить.
            </DialogTitle>
            {proposal.nomination_id === 1 ?
                <Box sx={{p: 3}}>
                    <Box sx={{mb: 4}}>
                        <Typography variant="h6" color="secondary.main">
                            Номинация:
                        </Typography>
                        <Typography variant="h6" sx={{fontFamily: "MTSText", fontWeight: "bold"}}>
                            Технологическая инновация
                        </Typography>
                    </Box>
                    <Stack direction="column"
                        spacing={2}
                    >
                        <ProposalTitleField 
                            title={title}
                            setTitle={setTitle}
                            required
                            disabled={loading}
                            error={errors["title"]}
                            setError={handleError}
                        />
                        <ProposalDescriptionField 
                            description={description}
                            setDescription={setDescription}
                            required
                            disabled={loading}
                            error={errors["description"]}
                            setError={handleError}
                        />
                        <ProposalAboutField 
                            about={about}
                            setAbout={setAbout}
                            required
                            disabled={loading}
                            error={errors['about']}
                            setError={handleError}
                        />
                        <ProposalPurposeField 
                            socialPurpose={socialPurpose}
                            setSocialPurpose={setSocialPurpose}
                            required
                            disabled={loading}
                            error={errors['purpose']}
                            setError={handleError}
                        /> 
                    </Stack>
                </Box>
            :
                <Box sx={{p: 3}}>
                    <Box sx={{mb: 4}}>
                        <Typography variant="h6" color="secondary.main">
                            Номинация:
                        </Typography>
                        <Typography variant="h6" sx={{fontFamily: "MTSText", fontWeight: "bold"}}>
                            Социальная идея
                        </Typography>
                    </Box>
                    <Stack direction="column"
                        spacing={2}
                    >
                        <IdeaTitleField 
                            title={title}
                            setTitle={setTitle}
                            required
                            disabled={loading}
                            error={errors["title"]}
                            setError={handleError}
                        />
                        <IdeaReferralField 
                            referral={referral}
                            setReferral={setReferral}
                            required
                            disabled={loading}
                            error={errors['referral']}
                            setError={handleError}
                        />
                        <IdeaDescriptionField 
                            description={description}
                            setDescription={setDescription}
                            required
                            disabled={loading}
                            error={errors["description"]}
                            setError={handleError}
                        />
                        <IdeaAboutField 
                            about={about}
                            setAbout={setAbout}
                            required
                            disabled={loading}
                            error={errors['about']}
                            setError={handleError}
                        />
                        <IdeaPurposeField 
                            socialPurpose={socialPurpose}
                            setSocialPurpose={setSocialPurpose}
                            required
                            disabled={loading}
                            error={errors['purpose']}
                            setError={handleError}
                        /> 
                    </Stack>
                </Box>
            }
            <Box sx={{px: 3}}>
                <CreateMemberPanel 
                    currentPage={0}
                    setMember={setMember}
                />
                <ProposalLinkField 
                    link={link}
                    setLink={setLink}
                    disabled={loading}
                    error={errors["link"]}
                    setError={handleError}
                />
                <Box sx={{textAlign: 'left', my: 1}}>
                    <Typography variant="h6" color="black">
                        Презентация вашей творческой работы    
                    </Typography>
                    <Divider variant="middle" sx={{my: 1}} />
                    <Typography variant="subtitle1" color="secondary">
                        Приложите не более 5 слайдов
                    </Typography>
                </Box>
                <UploadFileBox 
                    destination={"document"}
                    ownerId={proposal.id}
                />
                <Typography variant="subtitle1" sx={{my: 1}}>
                    Максимально допустимый размер файла 10 мб
                </Typography>
                <Button variant="contained" sx={{mb: 3}}
                    onClick={handleChange}
                    disabled={loading || !isFilled || isError() || proposal.status !== 'not_approved' && proposal.status !== "returned"} 
                >
                    Сохранить изменения
                </Button>
            </Box>
        </Dialog>
    )
}

const ProposalRemarkButton = ({remark}) => {

    const [moreOpen, setMoreOpen] = useState(false)

    return (<>
        <IconButton
            onClick={() => setMoreOpen(true)}
        >
            <PriorityHighIcon />
        </IconButton>
        <Dialog open={moreOpen} onClose={() => setMoreOpen(false)}>
            <DialogTitle variant="h5" sx={{fontWeight: "bold"}}>
                Комментарий модератора:
            </DialogTitle>
            <Typography variant="h6" sx={{mx: 3, mb: 2}}>
                {remark}
            </Typography>
        </Dialog>
    </>) 
}

export {ProposalRemarkButton}

const ProposalItem = ({proposal, deleteFunc, setProposalUpdated}) => {

    const [confirmOpen, setConfirmOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)

    const handleDelete = () => {
        deleteFunc(proposal.id)
        setConfirmOpen(false)
    }

    return (
        <Box bgcolor="white.main" sx={{ p: 2, borderRadius: 5}}>
            <Stack direction="row" 
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                sx={{minHeight: 150, width: "100%"
                   }}
            >
                <Box sx={{width: "60%"}}>
                    <Typography sx={{textAlign: 'left', px: 2}}>
                        Заявка на участие в конкурсе №{proposal.id}
                    </Typography>
                    <Typography 
                        variant="h6" 
                        color="black"
                        sx={{
                            pl: 2,
                            textAlign: "left",
                            fontFamily: "MTSText",
                            fontWeight: "bold"
                        }}
                    >
                        {proposal.title}
                    </Typography>
                </Box>
                <Stack direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    { proposal.remark && proposal.remark !== "" &&
                        <ProposalRemarkButton 
                            remark={proposal.remark}
                        />
                    }
                    {deleteFunc ?
                        <IconButton
                            onClick={() => setEditOpen(true)}
                        >
                            <EditIcon />
                        </IconButton>
                    :
                        <IconButton
                            disabled
                        >
                            <EditIcon />
                        </IconButton>
                    }
                    <ProposalStatus status={proposal.status}/>
                    {deleteFunc ?
                        <IconButton
                            onClick={() => setConfirmOpen(true)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    :
                        <IconButton
                            disabled
                        >
                            <DeleteIcon />
                        </IconButton>
                    }
                </Stack>
            </Stack>
            <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
                <Box sx={{p: 4}}>
                    <Typography variant="h6">
                        Вы собираетесь удалить завку!
                    </Typography>
                    <Typography variant="h6">
                        Удалить без возможности восстановления? 
                    </Typography>
                    <Stack 
                        direction="row" 
                        alignItems="center" 
                        justifyContent="space-between"
                        sx={{mt: 4}}
                    >
                        <Button 
                            variant="contained"
                            onClick={() => setConfirmOpen(false)}
                        >
                            Нет
                        </Button>
                        <Button 
                            onClick={handleDelete}
                        >
                            Да, удалить
                        </Button>
                    </Stack>
                </Box>
            </Dialog>
            <EditProposalDialog 
                open={editOpen}
                setOpen={setEditOpen}
                proposal={proposal}
                setProposalUpdated={setProposalUpdated}
            />

        </Box>
    )

}

const ProposalsList = ({proposals, deleteFunc, setProposalUpdated}) => {

    const isSaved = useSelector(state => state.competition.isSaved)
    const savedNominationId = useSelector(state => state.competition.proposalNominationId)
    const savedTitle = useSelector(state => state.competition.proposalTitle)
    const savedDescription = useSelector(state => state.competition.proposalDescription)
    const savedAbout = useSelector(state => state.competition.proposalAbout)
    const savedPurpose = useSelector(state => state.competition.proposalPurpose)
    const savedLink = useSelector(state => state.competition.proposalLink)
    
    const savedProposal = {
        id: "0",
        nomination_id: savedNominationId,
        title: savedTitle,
        description: savedDescription,
        about: savedAbout,
        social_purpose: savedPurpose,
        link: savedLink,
        status: "not_upload"
    }

    return(
        <Stack direction="column"
            spacing={2} 
            sx={{ width: "100%"}}
        >
            {(proposals.length === 0 && !isSaved) && <>
                <img 
                    src={Image} 
                    alt="empty-proposals" 
                    height={140}
                    style={{
                        marginLeft: "auto", 
                        marginRight: "auto", 
                        display: "block", 
                        marginTop: 25,
                        objectFit: "fill"
                    }}
                />
                <Typography variant="h6" color="secondary.main">Вы пока не создали ни одной заявки</Typography>
            </>}
            {isSaved &&
                <ProposalItem proposal={savedProposal} />
            }
            {proposals.map(proposal => 
                <ProposalItem key={proposal.id} 
                    proposal={proposal} 
                    deleteFunc={deleteFunc}
                    setProposalUpdated={setProposalUpdated} 
                />    
            )}
        </Stack>
    )
}


function MyProposalsPanel({setCurrentPage}) {

    const userId = useSelector(state => state.auth.userId)
    const navigate = useNavigate()

    const [proposals, setProposals] = useState([])

    const [loading, setLoading] = useState(true)
    const [memberError, setMemberError] = useState(false)
    const [proposalUpdated, setProposalUpdated] = useState(true)

    const fetchData = useCallback(async () => {
        setLoading(true)
        try {
            const {data, code} = await getUserProposals(userId)
            if (code === 200) {
                setProposals(data.proposals)
            }
        } catch(error) {
            switch(error) {
                case 403:
                    setMemberError(true)
                    break;
            }
            // setProposalUpdated(false)
        }
        setProposalUpdated(false)
        setLoading(false)
    }, [userId])


    const handleDelete = async (proposalId) => {
        try {
            const {data, code} = await deleteProposal(proposalId)
            if (code === 201) {
                fetchData()
            }
        } catch(error) {

        }
    }

    useEffect(() => {
        proposalUpdated && userId && fetchData()
    }, [userId, fetchData, proposalUpdated]);
    

    useEffect(() => {
        document.title = "Мы услышим • Мои заявки"
    }, []);

    return (
        <Stack direction="column" 
            justifyContent="center"
            alignItems="flex-start"
            spacing={4}
            sx={{minHeight: 300,}}
        >
            {loading 
                ? <CircularProgress />
                : <ProposalsList 
                    proposals={proposals} 
                    deleteFunc={handleDelete}
                    setProposalUpdated={setProposalUpdated} 
                />
            }
            <Box sx={{width: "100%"}}>
                <Button 
                    onClick={() => setCurrentPage(0)}
                    variant="outlined"
                    size="large"
                    sx={{borderRadius: 10, mx: "auto", mb: 2}}
                >
                    Создать заявку
                </Button>
            </Box>
        </Stack>
    )
}

export default MyProposalsPanel

import { useState } from "react";
import { useNavigate } from "react-router-dom"
import { AppBar, Toolbar, Typography, Menu, Container, Button,
    MenuItem, Box, IconButton } from "@mui/material"
import MenuIcon from '@mui/icons-material/Menu';
import { AuthButton } from "../pages/auth";
import Image from "../logo.png"
import { useSelector } from "react-redux";

const pages = {
    "ФЕСТИВАЛЬ": "/",
    "КОНКУРС": "/contest",
    "НОВОСТИ": "/news",
    // "ТРАНСЛЯЦИЯ": "/stream/2"
}

function Header() {

    const navigate = useNavigate()

    const isAuthed = useSelector(state => state.auth.isAuthed)

    const [anchorElNav, setAnchorElNav] = useState(null);

    const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };

    const handleNavigate = (path) => {
        navigate(path)
        handleCloseNavMenu()
    }


    return (
        <AppBar position="static" color="transparent"
            sx={{boxShadow: 0, height: 104, 
                display: 'flex', 
                justifyContent: 'center',
                alignItems: 'center', bgcolor: "white.main"
            }}
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <img src={Image} alt="SocialIdea logo" style={{width: 60, height: 60}}/>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="navigations buttons"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="secondary"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                        {Object.keys(pages).map((page) => (
                            <MenuItem key={page} onClick={() => handleNavigate(pages[page])}>
                                <Typography 
                                    textAlign="center"
                                    sx={{fontFamily: "MTSText", fontWeight: "bold"}}
                                >
                                    {page}
                                </Typography>
                            </MenuItem>
                        ))}
                        </Menu>
                    </Box>
                    <Box sx={{ ml: 2, flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {Object.keys(pages).map((page) => (
                            <Button
                                key={page}
                                onClick={() => handleNavigate(pages[page])}
                                sx={{ my: 2, color: 'black', display: 'block', fontFamily: "MTSText", fontWeight: "bold"}}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <AuthButton />
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export {Header}
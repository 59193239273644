import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserLikes } from "../middleware/api";

const getLikes = createAsyncThunk(
    'like/getLikes',
    async function(userId, {rejectWithValue, dispatch}) {
        try {
            // setLoading(true)
            const {data, code} = await getUserLikes(userId)
            if (code === 200) {
                dispatch(updateLikes(data))
            }
        } catch(error) {
            console.log(error)
            // switch(error.response.status) {
            //     case 403:
            //         setErrorRejected(true)
            //         break;
            //     case 404:
            //         setErrorExisting(true)
            //         break;
            //     default:
            //         console.log(error)
            // }
            return rejectWithValue(error.message)
        }
        // setLoading(false)
    }
)

export {getLikes}

const setError = (state, action) => {
    state.status = 'rejected'
    state.error = action.payload
}

const likeSlice = createSlice({
    name: "like",
    initialState: {
       count: 0, proposals: []
    },
    reducers: {
        updateLikes(state, action) {
            const payload = action.payload
            if (payload?.proposals.length > 0) {
                state.count = payload.proposals.length
                state.proposals = payload.proposals
            } else {
                state.count = 0
                state.proposals = []
            }
       
        }
    },
    extraReducers: {
        [getLikes.rejected]: setError
    }
})

export const {
    updateLikes
} = likeSlice.actions

export default likeSlice.reducer

import axios from 'axios'

const HEADERS = {
    'accept': 'applicatiom/json',
    'X-API-Key': process.env.REACT_APP_API_KEY,
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin' : '*',
}

const api = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_URL,
    headers: HEADERS
})

async function getRegions() {

    const response = await api.get(`/regions`)

    const data = await response.data
    const code = response.status

    return {data, code}
}

export {getRegions}

async function postUser(formData) {

    const response = await api.post('/user', formData)

    const data = await response.data
    const code = response.status

    return {data, code}
}

export {postUser}

async function getUser(userId) {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')

    const response = await api.get(`/user/${userId}`)

    const data = await response.data
    const code = response.status

    return {data, code}
}

export {getUser}

async function patchUser(confirmToken) {

    const response = await api.patch(`/user/${confirmToken}`)

    const code = response.status

    return {code}
}

export {patchUser}

async function putUser(userId, formData) {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')

    const response = await api.put(`/user/${userId}`, formData)

    const data = await response.data
    const code = response.status

    return {data, code}
}

export {putUser}

async function deleteUser(userId) {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')

    const response = await api.delete(`/user/${userId}`)

    const code = response.status

    return {code}
}

export {deleteUser}

async function freezeUser(formData) {
    const response = await api.patch('/freeze-user', formData)
    const code = response.status
    return {code}
}

export {freezeUser}

async function recoverUser(formData) {
    const response = await api.patch('/recover-user', formData)
    const code = response.status
    return {code}
}

export {recoverUser}

async function getUsers() {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')
    
    const response = await api.get('/users')

    const data = await response.data
    const code = response.status

    return {data, code}

}

export {getUsers}

async function postToken(formData) {

    const response = await api.post('/token', formData)

    const data = await response.data
    const code = response.status
    
    return {data, code}
}

export {postToken}

async function patchToken() {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')
    
    const response = await api.patch('/token')

    const data = await response.data
    const code = response.status

    return {data, code}
}

export {patchToken}

async function getActiveContest() {

    const response = await api.get('/contest')

    const data = await response.data
    const code = response.status
    
    return {data, code}
}

export {getActiveContest}

async function getContestExperts(contestId) {
    
    const response = await api.get(`/experts/${contestId}`)

    const data = await response.data
    const code = response.status

    return {data, code}
}

export {getContestExperts}

async function getContestPatners(contestId) {
    
    const response = await api.get(`/partners/${contestId}`)

    const data = await response.data
    const code = response.status

    return {data, code}
}

export {getContestPatners}

async function getGuestCriteria(queryData) {

    const response = await api.get('/guest-criteria', {params: queryData})

    const data = await response.data
    const code = response.status

    return {data, code}
}

export {getGuestCriteria}

async function getExperts() {

    const response = await api.get('/experts')

    const data = await response.data
    const code = response.status

    return {data, code}
}

export {getExperts}

async function postMember(formData) {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')

    const response = await api.post('/member', formData)

    const data = await response.data
    const code = response.status

    return {data, code}
}

export {postMember}

async function getMember(memberId) {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')

    const response = await api.get(`/member/${memberId}`)

    const data = await response.data
    const code = response.status

    return {data, code}
}

export {getMember}

async function deleteMember(memberId) {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')

    const response = await api.delete(`/member/${memberId}`)

    const data = await response.data
    const code = response.status

    return {data, code}
}

export {deleteMember}

async function postProposal(formData) {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')

    const response = await api.post('/proposal', formData)

    const data = await response.data
    const code = response.status

    return {data, code}
}

export {postProposal}

async function putProposal(proposalId, formData) {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')

    const response = await api.put(`/proposal/${proposalId}`, formData)

    const data = await response.data
    const code = response.status

    return {data, code}
}

export {putProposal}

async function deleteProposal(proposalId) {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')
    
    const response = await api.delete(`/proposal/${proposalId}`)
    
    const data = await response.data
    const code = response.status

    return {data, code}
}

export {deleteProposal}

async function patchProposal(proposalId, formData) {
    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')
    
    const response = await api.patch(`/proposal/${proposalId}`, formData)
    
    const data = await response.data
    const code = response.status

    return {data, code}
}

export {patchProposal}

async function getUserProposals(userId) {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')
    
    const response = await api.get(`/proposals/${userId}`)
    
    const data = await response.data
    const code = response.status

    return {data, code}
}

export {getUserProposals}

async function postObject(formData) {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')

    const response = await api.post(`/object`, formData)

    const data = await response.data
    const code = response.status
    
    return {data, code}
}

export {postObject}

async function putObject(objectId, formData) {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')

    const response = await api.put(`/object/${objectId}`, formData)

    const data = await response.data
    const code = response.status
    
    return {data, code}
}

export {putObject}

async function deleteObject(objectId) {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')

    const response = await api.delete(`/object/${objectId}`)

    const data = await response.data
    const code = response.status

    return {data, code}
}

export {deleteObject}

async function getObject(objectId) {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')

    const response = await api.get(`/object/${objectId}`)

    const data = await response.data
    const code = response.status

    return {data, code}
}

export {getObject}

async function getObjects(queryData) {

    const response = await api.get(`/objects`, {params: queryData})

    const data = await response.data
    const code = response.status

    return {data, code}
}

export {getObjects}

async function getNovelties(queryData) {

    const response = await api.get('/novelties', {params: queryData})

    const data = await response.data
    const code = response.status
    
    return {data, code}
}

export {getNovelties}

async function getNovelty(noveltyId) {

    const response = await api.get(`/novelty/${noveltyId}`)

    const data = await response.data
    const code = response.status

    return {data, code}
}

export {getNovelty}

async function getVideo(videoId) {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')

    const response = await api.get(`/video/${videoId}`)

    const data = await response.data
    const code = response.status

    return {data, code}
}

export {getVideo}

async function getVideos() {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')

    const response = await api.get(`/videos`)

    const data = await response.data
    const code = response.status

    return {data, code}

}

export {getVideos}

async function getLectures() {

    const response = await api.get(`/lectures`)

    const data = await response.data
    const code = response.status

    return {data, code}
}

export {getLectures}

async function getStreams() {

    const response = await api.get('/streams')

    const data = await response.data
    const code = response.status

    return {data, code}

}

export {getStreams}

async function postEmployee(formData) {

    const response = await api.post('/employee', formData)

    const data = await response.data
    const code = response.status

    return {data, code}
}

export {postEmployee}

async function getCinemas() {

    const response = await api.get('/cinemas')

    const data = await response.data
    const code = response.status

    return {data, code}

}

export {getCinemas}

async function getNominations(contestId) {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')

    const response = await api.get(`/nominations/${contestId}`)

    const data = await response.data
    const code = response.status

    return {data, code}

}

export {getNominations}

async function getProposals(queryData) {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')

    const response = await api.get('/proposals', {params: queryData})

    const data = await response.data
    const code = response.status

    return {data, code}
}

export {getProposals}

async function postMark(proposalId) {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')
    
    const response = await api.post(`/mark/${proposalId}`)
    
    const data = await response.data
    const code = response.status

    return {data, code}
}

export {postMark}

async function deleteMark(proposalId) {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')
    
    const response = await api.delete(`/mark/${proposalId}`)
    
    const data = await response.data
    const code = response.status

    return {data, code}
}

export {deleteMark}

async function getMark(proposalId) {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')
    
    const response = await api.get(`/mark/${proposalId}`)
    
    const data = await response.data
    const code = response.status

    return {data, code}
}

export {getMark}

async function getMarks(queryData) {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')
    
    const response = await api.get(`/marks`, {params: queryData})
    
    const data = await response.data
    const code = response.status

    return {data, code}
}

export {getMarks}

async function postEstimate(formData) {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')
    
    const response = await api.post(`/estimate`, formData)
    
    const data = await response.data
    const code = response.status

    return {data, code}
}

export {postEstimate}

async function deleteEstimate(estimateId) {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')
    
    const response = await api.delete(`/estimate/${estimateId}`)
    
    const data = await response.data
    const code = response.status

    return {data, code}
}

export {deleteEstimate}

async function getExpertEstimates(expertId, queryData) {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')
    
    const response = await api.get(`/estimates/${expertId}`, {params: queryData})
    
    const data = await response.data
    const code = response.status

    return {data, code}
}

export {getExpertEstimates}

async function getExpertCriteria(queryData) {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')
    
    const response = await api.get(`/criteria`, {params: queryData})
    
    const data = await response.data
    const code = response.status

    return {data, code}
}

export {getExpertCriteria}

async function putEstimate(formData) {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')
    
    const response = await api.put(`/estimate`, formData)
    
    const data = await response.data
    const code = response.status

    return {data, code}
}

export {putEstimate}

async function postLike(proposalId) {
    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')
    
    const response = await api.post(`/like/${proposalId}`)
    
    const data = await response.data
    const code = response.status

    return {data, code}
}

export {postLike}

async function deleteLike(proposalId) {
    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')
    
    const response = await api.delete(`/like/${proposalId}`)
    
    const data = await response.data
    const code = response.status

    return {data, code}
}

export {deleteLike}

async function getUserLikes(userId) {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')
    
    const response = await api.get(`/likes/${userId}`)
    
    const data = await response.data
    const code = response.status

    return {data, code}
}

export {getUserLikes}

async function getAllLikes() {

    api.defaults.headers.common['X-AUTH-Key'] = localStorage.getItem('accessToken')
    
    const response = await api.get(`/likes`)
    
    const data = await response.data
    const code = response.status

    return {data, code}
}

export {getAllLikes}

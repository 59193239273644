import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CircularProgress, Grid, Typography } from "@mui/material"
import { patchUser } from '../../middleware/api'

const TOKEN_REGEX = /^[a-zA-Z0-9_.-]+$/

function ConfirmUserPage() {

    const params = useParams()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [warning, setWarning] = useState(false)

    const confirm = async (confirmToken) => {
        try {   
            const {code} = await patchUser(confirmToken)
            if (code === 204) {
                setLoading(false)
                navigate('/')
            }
        } catch(error) {
            setLoading(false)
            error.response.status === 404 && setError(true)
            error.response.status === 409 && setWarning(true)
        }
    }

    useEffect(() => {
        if (!TOKEN_REGEX.test(params.token)) {
            navigate('/not-found')
        }
        confirm(params.token)
    }, [params.token, navigate]);

    useEffect(() => {
        document.title = "Подтверждение пользователя"
    }, []);

    return (
        <Grid container
            position='relative'
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{height: '80vh'}}
            spacing={2}
        >
            <Grid item>
                {loading && <CircularProgress />}
            </Grid>
            <Grid item>
                { !error && !warning &&
                    <Typography>
                        Всего несколько секунд! Нам надо подтвердить ваш e-mail...
                    </Typography>
                }
                { error &&
                    <Typography>
                        Кажется у вас неверный токен подтверждения...
                    </Typography>
                }
                { warning &&
                    <Typography>
                        Пользователь уже подтвержден...
                    </Typography>
                }
            </Grid>
        </Grid>
    )
}

export default ConfirmUserPage

import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { Box, Dialog, DialogTitle, Tab, Tabs } from "@mui/material"
import SignupBox from "./SignupBox";
import LoginBox from "./LoginBox";
import FreezeBox from "./FreezeBox";

function AuthDialog(props) {

    const {open, onClose, value, setValue} = props

    const isAuthed = useSelector(state => state.auth.isAuthed)

    const CustomTabPanel = (props) => {

        const { children, value, index, ...other } = props;
      
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
            {value === index && (
                <Box sx={{ px: 3, my: 1 }}>
                    {children}
                </Box>
            )}
            </div>
        );
    }
      
    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };
      
    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    return (
        <Dialog open={open && !isAuthed} onClose={onClose}>
            <DialogTitle>
                {/* <Tabs 
                    value={value} 
                    onChange={handleChange} 
                    centered
                    variant="fullWidth"
                >
                    <Tab label="Сброс пароля" {...a11yProps(0)} />
                    <Tab label="Вход" {...a11yProps(1)} />
                    <Tab label="Регистрация" {...a11yProps(2)} />
                </Tabs> */}
            </DialogTitle>
            <Box sx={{ width: '100%', py: 3}}>
                <CustomTabPanel value={value} index={0}>
                    <FreezeBox callback={setValue} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <LoginBox callback={setValue} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <SignupBox callback={setValue} />
                </CustomTabPanel>
               
            </Box>
        </Dialog>
    )
}

export default AuthDialog

import { useState, useEffect, useCallback, forwardRef } from "react";
import { Box, Typography, Tooltip, Divider, Stack, Button } from "@mui/material";

import { getContestPatners, getObjects } from "../../../middleware/api"

const prepareObjects = (objects) => {
    
    let preparedObjects = {}
    objects.map(object => preparedObjects[object.position] = object)

    return preparedObjects
}

const PartnerItem = ({partner}) => {

    const [objects, setObjects] = useState({})

    const [objectsError, setObjectsError] = useState(false)

    const fetchObjects = useCallback(async (partnerId) => {
        const queryData = {
            'owner_id': partnerId,
            'destination': "external"
        }
        try {
            const {data, code} = await getObjects(queryData)
            if (code === 200) {
                setObjects(prepareObjects(data.objects))
            }
        } catch(error) {
            switch(error.response.status) {
                case 404:
                    setObjectsError(true)
                    break;
                default:
                    console.log(error.response)
            }
        }
    }, [])

    useEffect(() => {
        partner.id && fetchObjects(partner.id)
    }, [partner.id, fetchObjects]);

    return (
        <Tooltip arrow title={
            <Box>
                <Typography variant="subtitle1" sx={{fontFamily: "MTSWide", fontWeight: "bold"}}>
                    {partner.title}
                </Typography>
                <Divider color="white" sx={{my: 0.5}} />
                <Typography variant="body1" sx={{fontFamily: "MTSText"}}>
                    {partner.about}
                </Typography>
            </Box>
        }>
            <Box sx={{width: 250, height: 110,
                display: "inline-block", mx: 1, mb: 1}}
            >
                <img 
                    src={objects[0]?.url}
                    alt="partner-image"
                    style={{objectFit: "none", width: "100%", height: "100%"}}
                />
            </Box>
        </Tooltip>
    )
}

const PartnersPanel = forwardRef(({contestId}, ref) => {

    const [partners, setPartners] = useState([])

    const [loading, setLoading] = useState(false)
    const [errExist, setErrExist] = useState(false)

    const [allPartners, setAllPartners] = useState(false)

    const fetchData = useCallback(async (cId) => {
        try {
            const {data, code} = await getContestPatners(cId)
            if (code === 200) {
                setLoading(false)
                setPartners(data.partners)
            }
        } catch(error) {
            switch(error.response.status) {
                case 404:
                    setErrExist(true)
                    break;
                default:
                    console.log(error.response)
            }
        }
    }, [])

    useEffect(() => {
        contestId && fetchData(contestId)
    }, [contestId, fetchData]);

    const changePartnerCount = () => {
        setAllPartners(!allPartners)
    }
 
    return (
        <Box ref={ref} 
            sx={{width: "100%", my: 6, display: "block"}}
        >
            <Stack direction="row" 
                justifyContent="space-between"
                alignItems="center"
                sx={{mb: 2, mx: 2}}
            >
                <Typography variant="h4" 
                    color="black" 
                    sx={{textAlign: 'left', fontFamily: "MTSWide", fontWeight: "bold"}}
                >
                    ПАРТНЕРЫ
                </Typography>
                <Button size="large" color="primary" variant={allPartners ? "conatined": "outlined"}
                    sx={{borderRadius: 10, fontFamily: "MTSWide", fontWeight: "bold"}} 
                    onClick={changePartnerCount}
                >
                    { allPartners ?
                        "Скрыть партнеров"
                    :
                        "Все партнеры"
                    }
                   
                </Button>
            </Stack>
            { allPartners ?
                partners.map((partner, i) => 
                    <PartnerItem key={i} partner={partner} />    
            ):
                partners.slice(0, 4).map((partner, i) => 
                <PartnerItem key={i} partner={partner} />
            )}
           
        </Box>
    )
})

export default PartnersPanel

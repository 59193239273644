import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { getExpertCriteria, putEstimate } from "../../../middleware/api";
import { useNavigate } from "react-router-dom";

const CriteriaItem = ({i, item, allGrades, setAllGrades}) => {

    const [itemValue, setItemValue] = useState("0")

    const handleChange = (event) => {
        if (event.target.value >= 0 && event.target.value <= item.score ) {
            setItemValue(Number(event.target.value))
            let nee = allGrades
            nee[i] = Number(event.target.value)
            setAllGrades(allGrades => [...nee])
        }
    }

    return (
        <Box sx={{width: "100%"}}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{width: "100%"}}
                spacing={2}
            >
                <Typography
                    sx={{width: "100%", fontWeight: "bold"}}
                >
                    {item.details}
                </Typography>
                <TextField 
                    value={itemValue}
                    onChange={handleChange}
                    type="numeric"
                    sx={{height: "100%", textAlign: "center", width: 90, px: 1}}
                />
            </Stack>
        </Box>
    )
}

export default function FillEstimatePanel({proposal, estimateId, callback}) {

    const navigate = useNavigate()

    const [criteria, setCriteria] = useState([])

    const [allGrades, setAllGrades] = useState([])
    const [comment, setComment] = useState("")

    const [loading, setLoding] = useState(false)
    const [isChanged, setIsChanged] = useState(true)

    const [total, setTotal] = useState(0)

    const fetchData = useCallback(async () => {
        setLoding(true)
        const queryData = {
            contest_id: proposal?.contest_id,
            nomination_id: proposal?.nomination_id
        }
        try {
            const {data, code} = await getExpertCriteria(queryData)
            if (code === 200) {
                setCriteria(data.criteria)
            }
        } catch(error) {
            console.log(error)
        }
        setLoding(false)
    }, [proposal])

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        let tt = 0
        allGrades.map(grade => grade >= 0 ? tt += Number(grade) : null)
        setTotal(tt)
    }, [allGrades])

    const handleFill = async () => {
        setLoding(true)
        const formData = new FormData()
        formData.append("id", estimateId)
        formData.append("grade", total)
        comment !== "" && formData.append("comment", comment)
        try {
            const {data, code} = await putEstimate(formData)
            if (code === 201) {
                callback()
                navigate("/moder-area/2")
            }
        } catch(error) {
            console.log(error)
        }
        setLoding(false)
    }
    
    return (
        <Box sx={{width: "100%", px: 3, mb: 3}}>
            <Typography variant="h5" sx={{fontWeight: "bold"}}>
                Оценить проект
            </Typography>
            <Typography variant="h6" 
                sx={{my: 1, color: "text.secondary"}}
            >
                Оцените проект согласно списку критериям от 0 до 10 баллов 
            </Typography>
            <Stack direction="column"
                spacing={2}
                sx={{width: "100%"}}
            >
                {criteria.map((item, i)=> 
                    <CriteriaItem key={item.id}
                        i={i}
                        item={item}
                        allGrades={allGrades}
                        setAllGrades={setAllGrades}
                    />
                )}
            </Stack>
            <br />
            <Stack direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{width: "100%"}}
            >
                <Typography variant="h6" sx={{fontWeight: "bold"}}>
                    Итоговый балл
                </Typography>
                <TextField 
                    id="totalGrade"
                    value={total}
                    disabled
                    sx={{width: 78, px: 1}}
                />
            </Stack>
            <Stack>
                <Typography color="text.secondary" sx={{my: 1}}>
                    Если необходимо вы можете написать свой комментарий к оценке проекта
                </Typography>
                <TextField 
                    id="commentField"
                    value={comment}
                    onChange={(event) => setComment(event.target.value)}
                    label="Комментарий"
                    multiline
                />
            </Stack>
            <br />
            <Box sx={{width: "100%", textAlign: "center"}}>
                <Button variant="contained"
                    onClick={handleFill}
                    sx={{fontWeight: "bold"}}
                    disabled={total <= 1 && comment === ""}
                >
                    оценить проект
                </Button>
            </Box>
        </Box>
    )
}
import { useCallback, useEffect, useState } from "react";
import { Box, Button, Dialog, DialogTitle, IconButton, Link, Stack, Typography } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { NominationField, ProposalStatusField, RemarkProposalField } from "./fields";
import { getMember, getNominations, getProposals, patchProposal, postMark } from "../../../middleware/api";
import { ProposalRemarkButton, ProposalStatus } from "./MyProposalsPanel";
import ContestExpertsSelector from "./ContestExpersSelector";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import FillEstimatePanel from "./FillEstimatePanel";

const NomiantionsPanel = ({nominationId, setNominationId}) => {

    const [nominations, setNominations] = useState([])

    const [loading, setLoading] = useState(false)

    const [errors, setErrors] = useState({
        "nomination": false
    })

    const handleError = (field, value) => {
        let updatedValue = {}
        updatedValue[field] = value
        setErrors(errors => ({
            ...errors,
            ...updatedValue
        }))
    }

    const fetchData = useCallback(async () => {
        setLoading(true)
        try {
            const {data, code} = await getNominations(1)
            if (code === 200) {
                setNominations(data.nominations)
            }
        } catch(error) {
            console.log(error)
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])
    
    return (
        <Box sx={{width: "48%", height: "100%", textAlign: "left"}}>
            <NominationField 
                nominations={nominations}
                nominationId={nominationId}
                setNominationId={setNominationId}
                error={errors["nomination"]}
                setError={handleError}
            />
        </Box>
    )
}

export {NomiantionsPanel}

const ProposalStatusPanel = ({nominationStatus, setNominationStatus}) => {

    const [errors, setErrors] = useState({
        "status": false
    })

    const handleError = (field, value) => {
        let updatedValue = {}
        updatedValue[field] = value
        setErrors(errors => ({
            ...errors,
            ...updatedValue
        }))
    }

    return (
        <Box sx={{width: "48%", height: "100%", textAlign: "left"}}>
            <ProposalStatusField 
                status={nominationStatus}
                setStatus={setNominationStatus}
                error={errors["status"]}
                setError={handleError}
            />
        </Box>
    )
}

const IndividualMember = ({member}) => {

    return (
        <Stack direction="column"
            spacing={1}
        >
            <Box>
                <Typography variant="h6" 
                    sx={{color: "secondary.main", fontFamily: 'MTSText'}}
                >
                    Физическое лицо:
                </Typography>
                <Typography
                    variant="h6"
                    sx={{color: "black", fontFamily: 'MTSText'}}
                >
                    {member?.fullname}
                </Typography>
            </Box>
            <Box>
                <Typography variant="h6" 
                    sx={{color: "secondary.main", fontFamily: 'MTSText'}}
                >
                    Место работы/учебы:
                </Typography>
                <Typography
                    variant="h6"
                    sx={{color: "black", fontFamily: 'MTSText'}}
                >
                    {member?.title}
                </Typography>
            </Box>
            <Box>
                <Typography variant="h6" 
                    sx={{color: "secondary.main", fontFamily: 'MTSText'}}
                >
                    Какие проекты в этой области были реализованы:
                </Typography>
                <Typography
                    variant="h6"
                    sx={{color: "black", fontFamily: 'MTSText'}}
                >
                    {member?.experience}
                </Typography>
            </Box>
            <Box>
                <Typography variant="h6" 
                    sx={{color: "secondary.main", fontFamily: 'MTSText'}}
                >
                    Мои достижения:
                </Typography>
                <Typography
                    variant="h6"
                    sx={{color: "black", fontFamily: 'MTSText'}}
                >
                    {member?.achievements}
                </Typography>
            </Box>
            <Box>
                <Typography variant="h6" 
                    sx={{color: "secondary.main", fontFamily: 'MTSText'}}
                >
                    Команда проекта:
                </Typography>
                <Typography
                    variant="h6"
                    sx={{color: "black", fontFamily: 'MTSText'}}
                >
                    {member?.team}
                </Typography>
            </Box>
        </Stack>
    )
}

const LegalMember = ({member}) => {

    return (
        <Stack direction="column"
            spacing={1}
        >
            <Box>
                <Typography variant="h6" 
                    sx={{color: "secondary.main", fontFamily: 'MTSText'}}
                >
                    ФИО заявителя:
                </Typography>
                <Typography
                    variant="h6"
                    sx={{color: "black", fontFamily: 'MTSText'}}
                >
                    {member?.fullname}
                </Typography>
            </Box>
            <Box>
                <Typography variant="h6" 
                    sx={{color: "secondary.main", fontFamily: 'MTSText'}}
                >
                    Юридическое лицо:
                </Typography>
                <Typography
                    variant="h6"
                    sx={{color: "black", fontFamily: 'MTSText'}}
                >
                    {member?.title}
                </Typography>
            </Box>
            <Box>
                <Typography variant="h6" 
                    sx={{color: "secondary.main", fontFamily: 'MTSText'}}
                >
                    ИНН юридического лица:
                </Typography>
                <Typography
                    variant="h6"
                    sx={{color: "black", fontFamily: 'MTSText'}}
                >
                    {member?.taxpayer_number}
                </Typography>
            </Box>
            <Box>
                <Typography variant="h6" 
                    sx={{color: "secondary.main", fontFamily: 'MTSText'}}
                >
                    Юридический адрес:
                </Typography>
                <Typography
                    variant="h6"
                    sx={{color: "black", fontFamily: 'MTSText'}}
                >
                    {member?.legal_address}
                </Typography>
            </Box>
            <Box>
                <Typography variant="h6" 
                    sx={{color: "secondary.main", fontFamily: 'MTSText'}}
                >
                    Адрес места нахождения:
                </Typography>
                <Typography
                    variant="h6"
                    sx={{color: "black", fontFamily: 'MTSText'}}
                >
                    {member?.fact_address}
                </Typography>
            </Box>
            <Box>
                <Typography variant="h6" 
                    sx={{color: "secondary.main", fontFamily: 'MTSText'}}
                >
                    Телефон:
                </Typography>
                <Typography
                    variant="h6"
                    sx={{color: "black", fontFamily: 'MTSText'}}
                >
                    {member?.phone}
                </Typography>
            </Box>
            <Box>
                <Typography variant="h6" 
                    sx={{color: "secondary.main", fontFamily: 'MTSText'}}
                >
                    Электронная почта:
                </Typography>
                <Typography
                    variant="h6"
                    sx={{color: "black", fontFamily: 'MTSText'}}
                >
                    {member?.email}
                </Typography>
            </Box>
            <Box>
                <Typography variant="h6" 
                    sx={{color: "secondary.main", fontFamily: 'MTSText'}}
                >
                    Краткое описание опыта деятельности юридического лицо:
                </Typography>
                <Typography
                    variant="h6"
                    sx={{color: "black", fontFamily: 'MTSText'}}
                >
                    {member?.experience}
                </Typography>
            </Box>
            <Box>
                <Typography variant="h6" 
                    sx={{color: "secondary.main", fontFamily: 'MTSText'}}
                >
                    Достижения коллектива:
                </Typography>
                <Typography
                    variant="h6"
                    sx={{color: "black", fontFamily: 'MTSText'}}
                >
                    {member?.achievements}
                </Typography>
            </Box>
            <Box>
                <Typography variant="h6" 
                    sx={{color: "secondary.main", fontFamily: 'MTSText'}}
                >
                    Команда проекта:
                </Typography>
                <Typography
                    variant="h6"
                    sx={{color: "black", fontFamily: 'MTSText'}}
                >
                    {member?.team}
                </Typography>
            </Box>
        </Stack>
    )
}

const MemberItem = ({member}) => {

    return (
        <Box>
            <Typography variant="h6"
                sx={{fontFamily: 'MTSText', fontWeight: "bold", mb: 1}}
            >
                Информация о заявителе проекта:
            </Typography>
            {member?.type === 'individual' ?
                <IndividualMember member={member} />
            :
                <LegalMember member={member} />
            }
        </Box>
    )
}

const ConfirmProposalBox = ({proposal, callback}) => {

    const navigate = useNavigate()

    const [step, setStep] = useState(0)
    const [mark, setMark] = useState()

    const [remark, setRemark] = useState("")

    const [loading, setLoading] = useState(false)

    const handleRemark = async () => {
        setLoading(true)
        const formData = new FormData()
        formData.append("status", "returned")
        formData.append("remark", remark)
        try {
            const {data, code} = await patchProposal(proposal.id, formData)
            if (code === 201) {
                callback()
                navigate("/moder-area/2")
            }
        } catch(error) {
            console.log(error)
        }
        setLoading(false)
    }

    const handleApprove = useCallback(async () => {
        setLoading(true)
        const formData = new FormData()
        formData.append("status", "approved")
        try {
            const {data, code} = await patchProposal(proposal.id, formData)
            if (code === 201) {
                setStep(1)
            }
        } catch(error) {
            console.log(error)
        }
        setLoading(false)
    }, [proposal])

    const handleCreate = useCallback(async () => {
        setLoading(true)
        try {
            const {data, code} = await postMark(proposal.id)
            if (code === 201) {
                setMark(data)
            }
        } catch(error) {
            console.log(error)
        }
        setLoading(false)
    }, [proposal])

    const [errors, setErrors] = useState({
       "remark": false
    })

    const handleError = (field, value) => {
        let updatedValue = {}
        updatedValue[field] = value
        setErrors(errors => ({
            ...errors,
            ...updatedValue
        }))
    }

    return (<>
         <Stack direction="row"
            alignItems="center"
            justifyContent="space-around"
            sx={{ width: "100%", px: 3}}
        >
            <Button variant="contained" 
                disabled={(proposal?.status !== 'not_approved' || mark )&& true}
                onClick={handleApprove}
                sx={{width: "40%", height: 50, borderRadius: 5, fontWeight: "bold"}}
            >
                ПРИНЯТЬ ЗАЯВКУ
            </Button>
            <Button variant="contained" onClick={() => setStep(2)}
                color="blackButton"
                disabled={proposal?.status !== 'not_approved'}
                sx={{width: "40%", height: 50, borderRadius: 5, color: "white.main", fontWeight: "bold"}}
            >
                на доработку
            </Button>
        </Stack>
        <br />
        <Box sx={{px: 3}}>
            {step === 1 && <>
                <Typography variant="h6">
                    Вы принимаете данную заявку на конкурс. Создайте оценочный лист и 
                    назначьте экспертов (до 3х человек), которые будут оценивать проект. 
                </Typography>
                <Box sx={{width: "100%", px: 10, my: 3}}>
                    <Button variant="outlined"
                        disabled={mark}
                        onClick={handleCreate}
                        sx={{width: "100%", fontWeight: "bold", minHeight: 50, borderRadius: 5}}
                    >
                        Создать оценочный лист
                    </Button>
                </Box>
            </>
            }
            {step === 2 &&<>
                <Typography variant="h6" sx={{fontFamily: "MTSText", mb: 2}}>
                    Вы отправляете данную заявку на доработку участнику. 
                    Напишите комментарий, в котором обозначьте, что именно нужно исправить.
                </Typography>
                <RemarkProposalField 
                    remark={remark}
                    setRemark={setRemark}
                    disabled={loading}
                    required
                    error={errors['remrk']}
                    setError={handleError}
                />
                <Box
                    sx={{width: "100%", alignItems: "center", justifyContent: "center", px: 10, my: 2}}
                >
                    <Button variant="outlined"
                        onClick={handleRemark}
                        disabled={remark === "" || loading}
                        sx={{width: "100%", fontWeight: "bold", minHeight: 50, borderRadius: 5}}
                    >
                        Отправить на доработку
                    </Button>
                </Box>
            </>}
            <ContestExpertsSelector 
                contestId={proposal.contest_id}
                proposal={proposal}
                mark={mark}
                setMark={setMark}
                callback={handleApprove}
            />
        </Box>
    </>)
}

const ProposalDialog = ({open, setOpen, proposal, estimateId=0}) => {

    const visitorRole = useSelector(state => state.auth.role)

    const [member, setMember] = useState()

    const [loading, setLoading] = useState(false)

    const referrals = {
        "service": "Поддержка обслуживания",
        "employment": "Трудоустройство",
        "sales": "Поддержка продаж",
        "product": "Продукты и услуги",
        "site": "Сайт(коммуникации)",
        "other": "Другое"
    }

    const handleClose = () => {
        setOpen(false)
    }

    const fetchData = useCallback(async (memberId) => {
        setLoading(false)
        try {
            const {data, code} = await getMember(memberId)
            if (code === 200) {
                setMember(data)
            }
        } catch(error) {
            console.log(error)
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        proposal.member_id && fetchData(proposal.member_id)
        window.scrollTo({ top: 0, behavior: "smooth" })
    }, [proposal.member_id, fetchData])
    
    const downloadDocument = (doc) => {
        const link = document.createElement("a")
        link.href = doc.url
        link.download = `${proposal.title}.zip`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    return (
        <Dialog open={open} onClose={handleClose}> 
            <DialogTitle variant="h5"
                sx={{fontFamily: "MTSText", fontWeight: "bold"}}
            >
                Заявка на участие в конкурсе №{proposal.id}
            </DialogTitle>
            <Stack sx={{px: 3}}
                spacing={1}
            >
                {proposal.type === 'innovation' ? <>
                    <Box>
                        <Typography variant="h6" color="secondary.main"
                            sx={{fontFamily: "MTSText"}}
                        >
                            Название проекта:
                        </Typography>
                        <Typography variant="h6" color="black"
                            sx={{fontFamily: "MTSText"}}
                        >
                            {proposal.title}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h6" color="secondary.main"
                            sx={{fontFamily: "MTSText"}}
                        >
                            Краткое описание проекта:
                        </Typography>
                        <Typography variant="h6" color="black"
                            sx={{fontFamily: "MTSText"}}
                        >
                            {proposal.description}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h6" color="secondary.main"
                            sx={{fontFamily: "MTSText"}}
                        >
                            Актуальность и социальная значимость проекта:
                        </Typography>
                        <Typography variant="h6" color="black"
                            sx={{fontFamily: "MTSText"}}
                        >
                            {proposal.social_purpose}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h6" color="secondary.main"
                            sx={{fontFamily: "MTSText"}}
                        >
                            Механика и реализация проекта:
                        </Typography>
                        <Typography variant="h6" color="black"
                            sx={{fontFamily: "MTSText"}}
                        >
                            {proposal.about}
                        </Typography>
                    </Box>
                </>:<>
                    <Box>
                        <Typography variant="h6" color="secondary.main"
                            sx={{fontFamily: "MTSText"}}
                        >
                            Название идеи:
                        </Typography>
                        <Typography variant="h6" color="black"
                            sx={{fontFamily: "MTSText"}}
                        >
                            {proposal.title}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h6" color="secondary.main"
                            sx={{fontFamily: "MTSText"}}
                        >
                            Направление:
                        </Typography>
                        <Typography variant="h6" color="black"
                            sx={{fontFamily: "MTSText"}}
                        >
                            {referrals[proposal.referral]}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h6" color="secondary.main"
                            sx={{fontFamily: "MTSText"}}
                        >
                            Определение проблемы и текущей ситуации:
                        </Typography>
                        <Typography variant="h6" color="black"
                            sx={{fontFamily: "MTSText"}}
                        >
                            {proposal.description}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h6" color="secondary.main"
                            sx={{fontFamily: "MTSText"}}
                        >
                            Ожидаемый результат с указанием целевой аудитории от реализации идеи:
                        </Typography>
                        <Typography variant="h6" color="black"
                            sx={{fontFamily: "MTSText"}}
                        >
                            {proposal.social_purpose}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h6" color="secondary.main"
                            sx={{fontFamily: "MTSText"}}
                        >
                            Описание идеи или решения проблемы:
                        </Typography>
                        <Typography variant="h6" color="black"
                            sx={{fontFamily: "MTSText"}}
                        >
                            {proposal.about}
                        </Typography>
                    </Box>
                </>}
                <br />
                <MemberItem member={member} />
                <Box>
                    <Typography variant="h6" color="secondary.main"
                        sx={{fontFamily: "MTSText"}}
                    >
                        Презентация проекта:
                    </Typography>
                    <Stack direction="column">
                        {proposal?.objects.length === 0 &&
                            <Typography variant="h6" color="black"
                                sx={{fontFamily: "MTSText"}}
                            >
                                -
                            </Typography>
                        }
                        {proposal?.objects.map((object, i) =>
                            <Typography key={i}>
                                <Link 
                                    variant="h6" 
                                    sx={{fontFamily: "MTSText"}}
                                    onClick={() => downloadDocument(object)}
                                >
                                    {object.id}.zip
                                </Link>
                            </Typography>
                        )}
                    </Stack>
                </Box>
                <Box>
                    <Typography variant="h6" color="secondary.main"
                        sx={{fontFamily: "MTSText"}}
                    >
                        Дополнительные материалы:
                    </Typography>
                    {proposal.link ?
                        <Link variant="h6" 
                            sx={{fontFamily: "MTSText"}}
                            href={proposal.link}
                        >
                            {proposal.link}
                        </Link>
                    :  
                        <Typography variant="h6" color="black"
                            sx={{fontFamily: "MTSText"}}
                        >
                            -
                        </Typography>
                    }
                </Box>
            </Stack>
            <br />
            {(visitorRole === "moder" || visitorRole === "admin") &&
                <ConfirmProposalBox 
                    proposal={proposal}
                    callback={() => setOpen(false)}
                />
            }
            {(visitorRole === "expert") &&
                <FillEstimatePanel 
                    proposal={proposal}
                    estimateId={estimateId}
                    callback={() => setOpen(false)}
                />
            }
        </Dialog>
    )
}

export {ProposalDialog}

const ProposalItem = ({proposal}) => {

    const [open, setOpen] = useState(false)

    return (
        <Box bgcolor="white.main" sx={{ p: 2, borderRadius: 5, width: "100%"}}>
            <Stack direction="row" 
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                sx={{minHeight: 150, width: "100%"}}
            >
                <Box sx={{width: "60%"}}>
                    <Typography sx={{textAlign: 'left', px: 2}}>
                        Заявка на участие в конкурсе №{proposal.id}
                    </Typography>
                    <Typography 
                        variant="h6" 
                        color="black"
                        sx={{
                            pl: 2,
                            textAlign: "left",
                            fontFamily: "MTSText",
                            fontWeight: "bold"
                        }}
                    >
                        {proposal.title}
                    </Typography>
                </Box>
                <Stack direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    {proposal.remark !== "" &&
                        <ProposalRemarkButton 
                            remark={proposal.remark}
                        />
                    }
                    <ProposalStatus status={proposal.status}/>
                    <IconButton
                        onClick={() => setOpen(true)}
                    >
                        <VisibilityIcon />
                    </IconButton>
                </Stack>
            </Stack>
            <ProposalDialog 
                open={open}
                setOpen={setOpen}
                proposal={proposal}
            />
        </Box>
    )
}

export default function ModerProposalsPanel() {

    const [nominationId, setNominationId] = useState("")
    const [nominationStatus, setNominationStatus] = useState("")
    const [proposals, setProposals] = useState([])

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        document.title = "Мы услышим • Заявки участников"
    }, [])

    const fetchData = useCallback(async (nId, nStatus) => {
        setLoading(true)

        const queryData = {contest_id: 1}
        
        if (nId !== "") {
            queryData.nomination_id = nId
        } 
        
        if (nStatus !== "") {
            queryData.status = nStatus
        }

        try {
            const {data, code} = await getProposals(queryData)
            if (code === 200) {
                setProposals(data.proposals)
            }
        } catch(error) {
            console.log(error)
        }
        setLoading(false)
    }, [])

    useEffect(() => {
      fetchData(nominationId, nominationStatus)
    }, [nominationId, nominationStatus, fetchData])
    
    return (
        <Stack direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
        
        >
            <Stack direction="row" 
                justifyContent="space-between"
                sx={{width: "100%"}}
            >
                <NomiantionsPanel 
                    nominationId={nominationId}
                    setNominationId={setNominationId}
                />
                <ProposalStatusPanel 
                    status={nominationStatus}
                    setNominationStatus={setNominationStatus}
                />
            </Stack>
         
            {proposals.map((proposal, i) =>
                <ProposalItem key={i}
                    proposal={proposal}
                />
            )}
        </Stack>
    )
}
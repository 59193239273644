import { Box, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { getExperts } from "../../../middleware/api";

const ExpertItem = ({expert}) => {
    return (
        <Box sx={{bgcolor: "white.main", width: "100%", textAlign: "left", p: 3, borderRadius: 5}}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="column">
                    <Typography variant="h6" sx={{fontWeight: "bold"}}>
                        {expert.fullname}
                    </Typography>
                    <Typography variant="body">
                        {expert.about}
                    </Typography>
                </Stack>
                <Box sx={{textAlign: "center"}}>
                    <Typography
                        sx={{fontWeight: 'bold'}}
                    >
                        Оценки
                    </Typography>
                    <Typography>{expert.close_estimate_count} / {expert.estimate_count}</Typography>
                </Box>
            </Stack>
        </Box>
    )
}

export default function ExpertsPanel() {

    const [experts, setExperts] = useState([])

    const [loading, setLoading] = useState(false)

    const fetchData = useCallback(async () => {
        setLoading(true)
        try {
            const {data, code} = await getExperts()
            if (code === 200) {
                setExperts(data.experts)
            }
        } catch(error) {
            console.log(error)
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <Stack direction="column" spacing={2}
            sx={{width: "100%"}}
        >
            {experts.map(expert => 
                <ExpertItem key={expert.id}
                    expert={expert}
                />
            )}
        </Stack>
    )
}
import { useEffect, useState, useCallback } from "react";
import { Avatar, Box, Button, Divider, Stack, Typography } from "@mui/material";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { ContactPanel } from "../../layouts/Footer";
import InclusiveShell from "../../assets/inclusivelectureshell.png"
import { getLectures } from "../../middleware/api";
import { VideoPlayer } from "./components/EducationVideosPanel";
import { GeneralBanner } from "./HomePage";

const MAX_WIDTH = 1280

const LectureItem = ({video}) => {

    const [playerOpen, setPlayerOpen] = useState(false)

    return (<>
        <Box sx={{ 
            width: 380, bgcolor: "white.main", borderRadius: 10,
            display: "inline-block", mx: 1, mb: 1
        }}>
            <Stack direction="column" sx={{p: 4, height: 490}} >
                <Avatar variant="square" 
                    sx={{width: 160, height: 160, borderRadius: 4, my: 2}}
                    src={video.objects[1].url}
                >
                    <PlayCircleIcon />
                </Avatar>
                <Typography variant="h5" 
                    sx={{textAlign: "left", my: 1,
                    fontFamily: "MTSText", fontWeight: "bold",
                    WebkitBoxOrient: "vertical"}}
                >
                    {video.title}
                </Typography>
                <Typography variant="h6" 
                    sx={{textAlign: "left", my: 2, fontFamily: "MTSText",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 5,
                        WebkitBoxOrient: "vertical",
                    }}
                >
                    {video.description}
                </Typography>
            </Stack>
            <Button
                variant="contained" color="blackButton"
                sx={{mb: 4, width: "85%", color: "white.main", p: 1, borderRadius: 5}} 
                onClick={() => setPlayerOpen(true)}
            >
                СМОТРЕТЬ
            </Button>
        </Box>
        <VideoPlayer 
            open={playerOpen}
            setOpen={setPlayerOpen}
            videoUrl={video.objects[0].url}
            videoType={video.objects[0].type}
            videoTitle={video.title}
        />
    </>)
}

export {LectureItem}

export default function InclusiveLecturePage() {

    const [lectures, setLectures] = useState([])


    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    const fetchData = useCallback(async () => {
        try {
            const {data, code} = await getLectures()
            if (code === 200) {
                setLectures(data.videos)
            }
        } catch(error) {
            console.log(error)
            setError(true)
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        document.title = "Мы услышим • Инклюзивный лекторий"
        fetchData()
    }, []);

    return (
        <Box sx={{bgcolor: 'secondary.light', width: "100%", height: "100%"}}>
            <GeneralBanner 
                title=""
                detials="ИНКЛЮЗИВНЫЙ ЛЕКТОРИЙ"
                imgUrl={InclusiveShell}
            />
            <Box sx={{maxWidth: MAX_WIDTH, mx: "auto", mt: 4}}>
                <Typography variant="h6" color="black"
                    sx={{textAlign: "left", mx: 2, fontFamily: "MTSText"}}
                >
                    В рамках лектория доступны видеолекции, подкасты и другие 
                    материалы о развитии инклюзивных практик.                
                </Typography>
                <Box sx={{display: "flexbox", width: "100%", my: 4}}>
                    {lectures.map(video =>
                        <LectureItem key={video.id}
                            video={video}
                        />
                    )}
                </Box>
            </Box>
            <ContactPanel maxWidth={MAX_WIDTH} />
            <br />
        </Box>
    )
}
import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material"
import { forwardRef } from "react"
import { IMaskInput } from "react-imask"
import PropTypes from "prop-types";
import { EMAIL_REGEX } from "../../auth/components/fields";


const VARIANT = "outlined"

const MemberTypeRadio = (props) => {
    const {
        type, setType, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
        const newValue = event.target.value
        setType(newValue.replace(/\s+/g, ' ', "").trim())
    }

    return (
        <FormControl disabled={disabled}>
            <RadioGroup row
                required={required}
                value={type}
                onChange={handleChange}
            >
                <FormControlLabel 
                    value="individual" 
                    control={<Radio />} 
                    label="Физическое лицо" 
                />
                <FormControlLabel 
                    value="legal"
                    control={<Radio />} 
                    label="Юридическое лицо" 
                />
            </RadioGroup>
        </FormControl>
    )
    
}

export {MemberTypeRadio}

const TITLE_REGEX = /^[0-9a-zA-ZBа-яА-я-.,©®\s]+$/i

const MemberTitleField = (props) => {
    const {
        title, setTitle, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
        event.target.value === "" && required 
            ? setError("title", true) 
            : setError("title", false)
            setTitle(event.target.value.replace(/\s+/g, ' ', "").trim())
  
    }

    return (
        <TextField 
            id="memberTitle"
            value={title}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            variant={VARIANT}
            error={error}
            label="Место работы/учебы"
            color="secondary"
            helperText="до 64 символов"
            inputProps={{ maxLength: 63 }}
        />
    )
}

export {MemberTitleField}

const LegalTitleField = (props) => {
    const {
        title, setTitle, required=false, disabled=false, error, setError,
    } = props

    const handleChange = (event) => {
        event.target.value === "" && required 
            ? setError("title", true) 
            : setError("title", false)
            setTitle(event.target.value.replace(/\s+/g, ' ', "").trim())
    }

    return (
        <TextField 
            id="legalTitle"
            value={title}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            variant={VARIANT}
            error={error}
            label="Полное наименование юридического лица"
            color="secondary"
            helperText="до 64 символов"
            inputProps={{ maxLength: 63 }}
        />
    )
}

export {LegalTitleField}

const TEXT_REGEX = /^[0-9a-zA-ZBа-яА-я-_.,!?`%$©®\-\s\n]+$/i

const MemberExperienceField = (props) => {
    const {
        experience, setExperience, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {

        event.target.value === "" && required 
            ? setError("experience", true) 
            : setError("experience", false)
            setExperience(event.target.value.replace(/\s+/g, ' ', "").trim())
    
    }

    return (
        <TextField 
            id="experience"
            value={experience}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            variant={VARIANT}
            error={error}
            multiline
            label="Какие проекты в этой области были реализованы"
            color="secondary"
            helperText="до 1000 символов"
            inputProps={{ maxLength: 999 }}
        />
    )
}

export {MemberExperienceField}

const MemberAchievementsField = (props) => {
    const {
        achievements, setAchievements, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
    
        event.target.value === "" && required 
            ? setError("achievements", true) 
            : setError("achievements", false)
            setAchievements(event.target.value.replace(/\s+/g, ' ', "").trim())
        
    }

    return (
        <TextField 
            id="achievements"
            value={achievements}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            variant={VARIANT}
            error={error}
            multiline
            label="Мои достижения"
            color="secondary"
            helperText="до 1000 символов"
            inputProps={{ maxLength: 999 }}
        />
    )
}

export {MemberAchievementsField}

const MemberTeamField = (props) => {
    const {
        team, setTeam, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
      
        event.target.value === "" && required 
            ? setError("team", true) 
            : setError("team", false)
            setTeam(event.target.value.replace(/\s+/g, ' ', "").trim())
        
    }

    return (
        <TextField 
            id="team"
            value={team}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            variant={VARIANT}
            error={error}
            multiline
            label="Команда проекта. Укажите краткую информацию о 
                членах команды проектной активности"
            color="secondary"
            helperText="до 1000 символов"
            inputProps={{ maxLength: 999 }}
        />
    )
}

export {MemberTeamField}

const TAXPAYER_REGEX = /^[0-9\-]+$/i

const TaxpayerMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="000000000000"
        // definitions={{
        //   "#": /[1-55]/
        // }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
});

TaxpayerMaskCustom.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

const TaxpayerField = (props) => {
    const {
        taxpayer, setTaxpayer, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
       
        event.target.value === "" && required 
            ? setError("taxpayer", true) 
            : setError("taxpayer", false)
            setTaxpayer(event.target.value.replace(/\s+/g, ' ', "").trim())
        
    }

    return (
        <TextField 
            id="taxpayerNumber"
            value={taxpayer}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            variant={VARIANT}
            error={error}
            multiline
            label="ИНН юридического лица"
            color="secondary"
            helperText=" от 10 до 12 символов"
            inputProps={{ minLength: 10, maxLength: 12, inputсomponent: TextMaskCustom }}
        />
    )
}

export {TaxpayerField}

const ADDRESS_REGEX = /^[0-9а-яА-я-.,\s]+$/i

const LegalAddressField = (props) => {
    const {
        legalAddress, setLegalAddress, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
    
        event.target.value === "" && required 
            ? setError("legalAddress", true) 
            : setError("legalAddress", false)
            setLegalAddress(event.target.value.replace(/\s+/g, ' ', "").trim())
        
    }

    return (
        <TextField 
            id="legalAddress"
            value={legalAddress}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            variant={VARIANT}
            error={error}
            multiline
            label="Юридический адрес"
            color="secondary"
            helperText="до 256 символов"
            inputProps={{maxLength: 255 }}
        />
    )
}

export {LegalAddressField}

const FactAddressField = (props) => {
    const {
        factAddress, setFactAddress, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
     
        event.target.value === "" && required 
            ? setError("factAddress", true) 
            : setError("factAddress", false)
            setFactAddress(event.target.value.replace(/\s+/g, ' ', "").trim())
        
    }

    return (
        <TextField 
            id="factAddress"
            value={factAddress}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            variant={VARIANT}
            error={error}
            multiline
            label="Адрес места нахождения"
            color="secondary"
            helperText="до 256 символов"
            inputProps={{maxLength: 255 }}
        />
    )
}

export {FactAddressField}

const CONTACT_REGEX = /^((\(\d{1,4}\)\s\d{1,3}\-\d{0,3}\-\d{0,3}))+$/i

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="(0000) 000-000-000"
        // definitions={{
        //   "#": /[1-55]/
        // }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
});

TextMaskCustom.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired
};


const LegalContactField = (props) => {
    const {
        contact, setContact, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
        if (event.target.value === "" || CONTACT_REGEX.test(event.target.value)) {
            event.target.value === "" && required 
                ? setError("contact", true) 
                : setError("contact", false)
                setContact(event.target.value.replace(/\s+/g, ' ', "").trim())
        }
    }

    return (
        <TextField 
            id="legalContact"
            value={contact}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            variant={VARIANT}
            error={error}
            multiline
            label="Телефон"
            InputProps={{
                inputComponent: TextMaskCustom
            }}
            color="secondary"
            // helperText="до 55 символов"
            // inputProps={{maxLength: 55 }}
        />
    )
}

export {LegalContactField}

const LegalEmailField = (props) => {

    const {
        email, setEmail, required=false, disabled=false, error, setError,
    } = props

    const handleChange = (event) => {
        if (EMAIL_REGEX.test(event.target.value)) {
            setError("email", false)
        } else {
            setError("email", true)
        }
        event.target.value === "" && !required && setError("email", false)
        setEmail(event.target.value.replace(/\s+/g, ' ', "").trim())
    }

    return (
        <TextField 
            id="email"
            type="email"
            value={email}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            variant={VARIANT}
            label="Электронная почта"
            color="secondary"
            error={!!error}
            inputProps={{ maxLength:99 }}
        />
    )
}

export {LegalEmailField}

const LegalExperienceField = (props) => {
    const {
        experience, setExperience, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
      
        event.target.value === "" && required 
            ? setError("experience", true) 
            : setError("experience", false)
            setExperience(event.target.value.replace(/\s+/g, ' ', "").trim())
    
    }

    return (
        <TextField 
            id="legalExperience"
            value={experience}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            variant={VARIANT}
            error={error}
            multiline
            label="Краткое описание опыта деятельности юридического лицо"
            color="secondary"
            helperText="до 1000 символов"
            inputProps={{ maxLength: 999 }}
        />
    )
}

export {LegalExperienceField}

const LegalAchievementsField = (props) => {
   const {
        achievements, setAchievements, required=false, disabled=false, error, setError
    } = props

    const handleChange = (event) => {
       
        event.target.value === "" && required 
            ? setError("achievements", true) 
            : setError("achievements", false)
            setAchievements(event.target.value.replace(/\s+/g, ' ', "").trim())
        
    }

    return (
        <TextField 
            id="legalAchievements"
            value={achievements}
            onChange={handleChange}
            fullWidth
            required={required}
            disabled={disabled}
            variant={VARIANT}
            error={error}
            multiline
            label="Достижения коллектива"
            color="secondary"
            helperText="до 1000 символов"
            inputProps={{ maxLength: 999 }}
        />
    )
}

export {LegalAchievementsField}
import { Box, Stack, Button } from "@mui/material";
import ModerPanel from "../main/components/ModerPanel";
import { ContactPanel } from "../../layouts/Footer";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ModerProposalsPanel from "./components/ModerProposalsPanel";
import RatedProposalsPanel from "./components/RatedProposalsPanel";
import ExpertsPanel from "./components/ExpertsPanel";
import UsersPanel from "./components/UsersPanel";
import { useSelector } from "react-redux";

const MAX_WIDTH = 1280

const NavigationButtonGroup = ({currentPage, setCurrentPage}) => {

    const navigate = useNavigate()

    const buttonVariant = "contained"
    const buttonColor = "navigationBatton"
    const buttonActiveColor = "primary"
    const buttonPref = {
        borderRadius: 4,
        height: 60,
        fontFamily: "MTSText", 
        fontWeight: "bold"
    }

    return (
        <Stack spacing={2}>
            <Button 
                variant={buttonVariant} 
                sx={buttonPref}
                color={currentPage === 0 ? buttonActiveColor : buttonColor}
                onClick={() => setCurrentPage(0)}
            >
                Заявки на рассмотрение
            </Button>
            <Button 
                variant={buttonVariant} 
                sx={buttonPref}
                color={currentPage === 1 ? buttonActiveColor : buttonColor}
                onClick={() => setCurrentPage(1)}
            >
                Оцененные заявки
            </Button>
            <Button 
                variant={buttonVariant} 
                sx={buttonPref}
                color={currentPage === 2 ? buttonActiveColor : buttonColor}
                onClick={() => setCurrentPage(2)}
            >
                Эксперты
            </Button>
            <Button 
                variant={buttonVariant} 
                sx={buttonPref}
                color={currentPage === 3 ? buttonActiveColor : buttonColor}
                onClick={() => setCurrentPage(3)}
            >
                Участники
            </Button>
            <Button 
                variant={buttonVariant} 
                sx={buttonPref}
                color="blackButton"
                style={{color: "white"}}
                onClick={() => navigate('/user-area')}
            >
                Личный кабинет участника
            </Button>
            <Button 
                variant={buttonVariant} 
                sx={buttonPref}
                color="blackButton"
                style={{color: "white"}}
                onClick={() => navigate('/expert-area')}
            >
                Личный кабинет эксперта
            </Button>
        </Stack>
    )
}

const ModerAreaTabs = ({currentPage, setCurrentPage}) => {
    return (
        <> 
            {currentPage === 0 && <ModerProposalsPanel 
                currentPage={currentPage}
                setCurrentPage={setCurrentPage} 
            />}
            {currentPage === 1 && <RatedProposalsPanel 
                setCurrentPage={setCurrentPage}
            />}
            {currentPage === 2 && <ExpertsPanel />}
            {currentPage === 3 && <UsersPanel
                currentPage={currentPage}
            />}
        </>
    )
}

export default function ModerPrivatePage() {

    const {page} = useParams()
    const navigate = useNavigate()

    const role = useSelector(state => state.auth.role)

    const [currentPage, setCurrentPage] = useState(0)

    useEffect(() => {
        if (role === "user" || role === "member") {
            navigate('/user-area')
        }
        if (role === "expert") {
            navigate('/expert-area')
        }
    }, [role])
    
    useEffect(() => {
        page === "1" && setCurrentPage(0)
        page === "2" && setCurrentPage(1)
        page === "3" && setCurrentPage(2)
        page === "4" && setCurrentPage(3)
    }, [page])

    return (
        <Box sx={{bgcolor: "secondary.light", minHeight: "90vh"}}>
            <Box sx={{maxWidth: MAX_WIDTH, mx: 'auto'}}>
                <br/>
                <ModerPanel />
                <br/>
                <Box sx={{display: 'flex', position: "static",
                    justifyContent: 'center',
                    alignItems: 'center', maxWidth: MAX_WIDTH, mx: 'auto'}}
                >
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none', width: "100%"}}}>
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={4}
                            sx={{width: "100%"}}
                        >
                            <Box sx={{width: "100%", px: 2}}>
                                <NavigationButtonGroup 
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </Box>
                            <Box sx={{width: "100%", px: 2}}>
                                <ModerAreaTabs 
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </Box>
                        </Stack>
                    </Box>
                    <Box sx={{flexGrow: 1, display: { xs: 'none', md: 'flex'}, width: "100%"}}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="space-between"
                            spacing={4}
                            sx={{width: "100%"}}
                        >
                            <Box sx={{width: "28%"}}>
                                <NavigationButtonGroup 
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </Box>
                            <Box sx={{width: '72%'}}> 
                                <ModerAreaTabs 
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </Box>
                        </Stack>
                    </Box>
                </Box> 
                <br/>
            </Box>
            <ContactPanel maxWidth={MAX_WIDTH}/>
            <br />
        </Box>
    )

}
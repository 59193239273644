import { useEffect } from "react";
import { Box, Divider, Typography } from "@mui/material";
import CinemaShell from "../../assets/showcinemashell.png"
import { ContactPanel } from "../../layouts/Footer";
import { GeneralBanner } from "./HomePage";
import CinemasPanel from "./components/CinemasPanel";

const MAX_WIDTH = 1280

export default function ShowCinemaPage() {

    useEffect(() => {
        document.title = "Мы услышим • Показ Кино"
    }, []);

    return (
        <Box sx={{bgcolor: 'secondary.light'}}>
            <GeneralBanner 
                title="ПУБЛИЧНЫЙ ПОКАЗ КИНО"
                imgUrl={CinemaShell}
            />
            <Box sx={{maxWidth: MAX_WIDTH, mx: "auto", mt: 4}}>
                <Typography variant="h6" color="black"
                    sx={{textAlign: "left", mx: 2, fontFamily: "MTSText"}}
                >
                    В рамках Всероссийского фестиваля «Мы услышим» реализуется публичный бесплатный 
                    показ фильмов от КИОН originals  с переводом на русский жестовый язык и субтитрами.         
                </Typography>
                <CinemasPanel />
                <br />
            </Box>
            <ContactPanel maxWidth={MAX_WIDTH} />
            <br />
        </Box>
    )
}
import { useCallback, useState } from "react"
import { Box, Link, useScrollTrigger, Zoom, Fab, Typography, Stack, Button,
  Snackbar, Alert } from "@mui/material"
import LoadingButton from '@mui/lab/LoadingButton'
import { KeyboardArrowUp } from "@mui/icons-material";
import EmailIcon from '@mui/icons-material/Email';
import appInfo from "../app.json"
import { EmailField } from "../pages/auth/components/fields";
import { postEmployee } from "../middleware/api";
import { leftGradientStyle, rightGradientStyle } from "../pages/main/HomePage";
import BallsImage from "../assets/balls.png"

const ScrollTop = () => {
    const trigger = useScrollTrigger({
        target: document.window,
        disableHysteresis: true,
        threshold: 100,
      });

    const scrollToTop = useCallback(() => {
        window.scrollTo({ top: 0, behavior: "smooth"})
    }, [])

    return (
        <Zoom in={trigger}>
          <Box
            role="presentation"
            sx={{
              position: "fixed",
              bottom: 32,
              right: 32,
              zIndex: 10,
            }}
          >
            <Fab
              onClick={scrollToTop}
              color="primary"
              size="small"
              aria-label="scroll back to top"
            >
              <KeyboardArrowUp />
            </Fab>
          </Box>
        </Zoom>
    )
}

export {ScrollTop}

const WorkInCompanyPanel = () => {

  const [email, setEmail] = useState("")

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const [errors, setErrors] = useState({
    'email': false
  })

  const handleError = (field, value) => {
    let updatedValue = {}
    updatedValue[field] = value
    setErrors(errors => ({
        ...errors,
        ...updatedValue
    }))
  }

  const isError = () => {
    for (const key of Object.keys(errors)) {
        if (errors[key]) {
            return true
        }
    }
    return false
  }

  const handelSubmit = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append('email', email)
    try {
      const {data, code} = await postEmployee(formData)
      if (code === 201) {
        setSuccess(true)
      }
    } catch(error) {
      switch (error.response.status) {
        case 409:
          setError(true)
      }
    }
    setLoading(false)
  }

  return (
    <Box 
      sx={{
        width: "100%", 
        bgcolor: "white.main", 
        mt: 6,
        borderRadius: 10,
        py: 8
      }}
    >
      <Box sx={{display: 'flex', position: "static",
          justifyContent: 'center',
          alignItems: 'center', maxWidth: 1280, mx: 'auto'}}
      >
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }}}>
            <Box sx={{width: "80%", textAlign: "left", mx: "auto"}}>
              <Typography variant="h4" sx={{fontFamily: "MTSWide", fontWeight: "bold"}}>
                РАБОТА В МТС
              </Typography>
              <Typography variant="h6" sx={{my: 2, fontFamily: "MTSText"}}>
                Если вы хотите работать в МТС и получать актуальные вакансии, 
                подпишитесь на рассылку
              </Typography>
              <Stack direction="column"
                alignItems="center"
                spacing={2}

              >
                <EmailField
                  email={email}
                  setEmail={setEmail}
                  required
                  error={errors['email']}
                  setError={handleError}
                />
                <LoadingButton variant="contained"
                  onClick={handelSubmit}
                  loading={loading} 
                  size="large" 
                  disabled={email === "" || isError()}
                  sx={{p: 2, borderRadius: 5, fontFamily: "MTSWide", fontWeight: "bold", width: "100%"}}
                >
                  Подписаться
                </LoadingButton>
              </Stack>
            </Box>
          </Box>
          <Box sx={{ ml: 2, flexGrow: 1, display: { xs: 'none', md: 'flex'}, width: "100%"}}>
            <Box sx={{width: "90%", textAlign: "left", mx: "auto"}}>
              <Typography variant="h4" sx={{fontFamily: "MTSWide", fontWeight: "bold"}}>
                РАБОТА В МТС
              </Typography>
              <Typography variant="h6" sx={{my: 2, fontFamily: "MTSText"}}>
                Если вы хотите работать в МТС и получать актуальные вакансии, 
                подпишитесь на рассылку
              </Typography>
              <Stack direction="row"
                alignItems="center"
                spacing={2}

              >
                <EmailField
                  email={email}
                  setEmail={setEmail}
                  required
                  error={errors['email']}
                  setError={handleError}
                />
                <LoadingButton variant="contained" 
                  onClick={handelSubmit}
                  loading={loading} 
                  size="large" 
                  disabled={email === "" || isError()}
                  sx={{borderRadius: 5, fontFamily: "MTSWide", fontWeight: "bold", width: "40%"}}
                >
                  Подписаться
                </LoadingButton>
              </Stack>
            </Box>
          </Box>
      </Box>
      <Snackbar 
          open={error} 
          autoHideDuration={6000} 
          onClose={() => setError(false)}
          >
              <Alert onClose={() => setError(false)} severity="error" sx={{ width: '100%' }}>
                  Пользователь с такой электронной почтой уже подписался на рассылку вакинсий
          </Alert>
      </Snackbar>
      <Snackbar 
          open={success} 
          autoHideDuration={6000} 
          onClose={() => setSuccess(false)}
          >
              <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
                  Вы успешно подписались на рассылку ({email})
          </Alert>
      </Snackbar>
    </Box>
  )
}

export {WorkInCompanyPanel}

const ContactPanel = ({maxWidth}) => {

  const LeftBox = () => {
    return (
      <Box sx={{textAlign: 'left'}}>
        <Typography variant="body1">
          По вопросам партнерства и участия:
        </Typography>
        <Typography variant="h6" sx={{textDecoration: "underline"}}
          onClick={() => window.location = "mailto:csr@mts.ru"}
        >
          <EmailIcon sx={{height: 15}}/> csr@mts.ru
        </Typography>
    </Box>
    )
  }

  const RightBox = () => {
    return (
      <Box sx={{textAlign: 'left', zIndex: 10}}>
        <Typography variant="body1">
          По вопросам работы сайта и подаче заявок:
        </Typography>
        <Typography variant="h6" sx={{textDecoration: "underline"}}
          onClick={() => window.location = "mailto:info@rusinnovations.com"}
        >
          <EmailIcon sx={{height: 15}}/> info@rusinnovations.com
        </Typography>
      </Box>
    )
  }

  return (
    <Box style={rightGradientStyle}
      sx={{
        overflow: "hidden",
        position: "relative",
        bgcolor: "lightblue", 
        mx: "auto", 
        borderRadius: 10, p: 4, maxWidth: 1280
      }}
    >
      <Typography variant="h4" color="white.main"
        sx={{textAlign: "left", p: 2, fontFamily: "MTSWide", fontWeight: "bold"}}
      >
        Контакты
      </Typography>
      <Box sx={{display: 'flex', position: "static",
        justifyContent: 'center', fontFamily: "MTSText", color: "white.main",
        alignItems: 'center', mx: 'auto', mt: 4}}
      >
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }}}>
            <Stack direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={4}
                sx={{width: "100%", mb: 2, px: 2}}
            >
              <LeftBox />
              <RightBox />
            </Stack>
        </Box>
        <Box sx={{ ml: 2, flexGrow: 1, display: { xs: 'none', md: 'flex'}, width: "100%", mb: 2}}>
            <Stack direction="row"
              justifyContent="spaca-between"
              alignItems="spaca-between"
              sx={{ width: "100%"}}
              spacing={10}
            >
              <LeftBox />
              <RightBox />
              <img 
                alt="balls"
                src={BallsImage}
                style={{height: 400, position: 'absolute', right: 50, top: -100, zIndex: 0}}
              />
            </Stack>
        </Box>
      </Box>
    </Box>
  )
}

export {ContactPanel}

function Footer() {

    return (
        <Box className="footer">
            <ScrollTop />
        </Box> 
    )
}

export {Footer}
import { Box, Dialog, DialogTitle, Stack, Typography } from "@mui/material"
import CircleIcon from '@mui/icons-material/Circle';

export default function GuestCriteriaBanner(props) {

    const {open, setOpen, criteria, nominationTitle} = props

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
        >
            <Box sx={{p: 2}}>
                <Stack direction="column" sx={{p: 3}}>
                    <Typography variant="h5" sx={{fontFamily: "MTSText", fontWeight: "bold"}}>
                        Критерии оценки заявок по номинации
                    </Typography>
                    <Typography variant="h5" sx={{fontFamily: "MTSText", fontWeight: "bold"}}>
                        «{nominationTitle}»
                    </Typography>
                </Stack>
                <Stack direction="column" sx={{p: 2}}>
                    {criteria.map(cr => 
                        <Typography key={cr.id} variant="h6" sx={{mb: 1, fontFamily: "MTSText"}}>
                            <CircleIcon color="primary" sx={{fontSize: 10}} /> {cr.details}
                        </Typography>
                    )} 
                </Stack>
            </Box>
        </Dialog>
    )
}
import { Grid, Typography, Box } from "@mui/material"

const TaskPanel = ({task, id}) => {
    return (
        <Grid item 
            sx={{ width: "49%"}}
        >
            <Box 
                sx={{height: 45, width: 45, ml: 2, borderRadius: 45, display: "flex",
                    border: 2, borderColor: "white.main", position: "absolute",
                    bgcolor: "secondary.light", justifyContent: "center", alignItems: "center"}}
            >
                <Typography variant="h6" color="secondary.main">
                    {id}
                </Typography>
            </Box>
            <Box sx={{bgcolor: "secondary.light", mt: 3.1, borderRadius: 4, pb: 0.5, boxShadow: 2}}>
                <br />
                <Typography sx={{m: 2}}>
                    {task}
                </Typography>
            </Box>
        </Grid>
    )
}

export {TaskPanel}

function TasksBox({tasks}) {
    return (
        <Grid container 
            direction="row"
            columns={2}
            justifyContent="space-between"
            alignItems="flex-start"
            columnGap={1}
            rowGap={2}
            sx={{mt: 2}}
        >
            {tasks.map((task, i) => 
                <TaskPanel 
                    key={task} 
                    task={task}
                    id={i+1}
                />)}
        </Grid>
    )
}

export default TasksBox
import { useEffect, useState } from "react";
import { Box, Button, Link, Stack, Typography } from "@mui/material";
import HomeShell from "../../assets/homeshell.png"
import Cinema from "../../assets/cinema.png"
import Heart from "../../assets/heart.png"
import Case from "../../assets/case.png"
import { ContactPanel, WorkInCompanyPanel } from "../../layouts/Footer";
import PartnersPanel from "../competition/components/PartnersPanel";
import { useNavigate } from "react-router-dom";
import NoveltiesPanel from "./components/NoveltiesPanel";
import TwoHandsImage from "../../assets/hands.png"
import { VideoPlayer } from "./components/EducationVideosPanel";
import StreamsPanel from "./components/StreamsPanel";

const MAX_WIDTH = 1280

const leftGradientStyle = {
    background: 'radial-gradient(at bottom left, #c8cdf9, #7a8efb'
}

const rightGradientStyle = {
    background: 'radial-gradient(at bottom right, #e9d2e9, #7a8efb'
}

export {leftGradientStyle}
export {rightGradientStyle}

const GeneralBanner = ({title, description="", tagline="", detials="", imgUrl}) => {

    const HeaderBox = () => {
        return (
            <Box sx={{py: 4, width: "76%"}}>
                <Box 
                    sx={{minWidth: 200, textAlign: "left", mx: "auto", py: 4, width: "100%"}}
                    
                >
                    { detials !== "" &&
                          <Typography variant="h4" color='white.main' 
                            sx={{textShadow: 2, mt: 2, mb: 2, width: "100%", px: 1, maxWidth: 640}}
                        >
                            {detials}
                        </Typography>
                    }
                    <Typography variant="h3" color='white.main' 
                        sx={{fontFamily: "MTSWide", fontWeight: "bold", width: "100%", mb: 1, px: 1}}
                    >
                        {title}
                    </Typography>
                    {tagline !== "" &&
                        <Typography variant="h4" color='white.main' 
                            sx={{textShadow: 2, width: "100%", 
                                px: 1, borderRadius: 10, mt: 2
                            }}
                        >
                            {tagline}
                        </Typography>
                    }
                    { description !== "" &&
                        <Typography variant="h4" color='white.main' 
                            sx={{textShadow: 2,
                                p: 1, borderRadius: 10
                            }}
                        >
                            {description}
                        </Typography>
                    }         
                </Box>
            </Box>       
        )
    }

    return (
        <Box style={rightGradientStyle}
            sx={{ width: "100%"}}
        >
            <Box sx={{display: 'flex', position: "static",
                justifyContent: 'center',
                alignItems: 'center', maxWidth: MAX_WIDTH, mx: 'auto', }}
            >
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }}}>
                    <Stack direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={4}
                        sx={{width: "100%"}}
                    >
                        <HeaderBox />
                        <Box sx={{position: 'relative'}}>
                            {imgUrl &&
                                <img 
                                    src={imgUrl}
                                    alt="general-preview"
                                    style={{
                                        objectFit: "cover",
                                        width: "100%", 
                                        height: "100%",    
                                }}
                            />
                            }
                        </Box>
                    </Stack>
                </Box>
                <Box sx={{ ml: 2, flexGrow: 1, display: { xs: 'none', md: 'flex'}, width: "100%", position: "relative"}}>
                    <Stack direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{width: "100%", mt: 4, minHeight: 500}}
                    >
                        <HeaderBox />
                        <Box sx={{width: "70%"}}>
                            {imgUrl &&
                                <img 
                                    src={imgUrl}
                                    alt="general-preview"
                                    style={{
                                        objectFit: "cover",
                                        maxWidth: 640, 
                                        maxHeight: 500,
                                        position: "absolute",
                                        bottom: 0,
                                        right: 0
                                    }}
                                />
                            }
                        </Box>
                    </Stack>
                </Box>
            </Box> 
        </Box>
    )
}

export {GeneralBanner}

const ContestBanner = () => {

    const navigate = useNavigate()

    return (
        <Box style={leftGradientStyle}
            sx={{borderRadius: 10}}
        >
            <Box sx={{display: 'flex', position: "static",
                justifyContent: 'center',
                alignItems: 'center', maxWidth: MAX_WIDTH, mx: 'auto'}}
            >
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }}}>
                    <Stack
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        spacing={6}
                    >
                        <Stack direction="column" justifyContent="center"
                            sx={{width: "100%", textAlign: "left", minHeight: 150, p: 5}}
                        >
                            <Typography variant="h4" color="white.main" sx={{fontFamily: "MTSWide", fontWeight: "bold"}}>
                                КОНКУРС «МЫ УСЛЫШИМ»
                            </Typography>
                            <Typography variant="h5" color="white.main" sx={{my: 2, fontFamily: "MTSText"}}>
                                Поиск лучших практик и сервисов для людей с нарушением слуха
                            </Typography>
                            <Button variant="contained" size="large"
                                onClick={() => navigate('/contest')}
                                sx={{borderRadius: 5, height: 50, fontFamily: "MTSWide", fontWeight: "bold"}}
                            >
                                ПОДРОБНЕЕ
                            </Button>
                            <br />
                            <Button variant="contained" size="large"
                                color="navigationBatton"
                                onClick={() => navigate('/voting')}
                                sx={{borderRadius: 5, height: 50, fontFamily: "MTSWide", fontWeight: "bold"}}
                            >
                                ГОЛОСУЙ ЗА ИДЕЮ
                            </Button>
                        </Stack>
                        <Box sx={{width: "100%", pt: 4}}>
                            <img 
                                alt="hello-hand"
                                src={TwoHandsImage}
                                style={{height: "100%"}}
                            />
                        </Box>
                    </Stack>
                </Box>
                <Box sx={{ ml: 2, flexGrow: 1, display: { xs: 'none', md: 'flex'}, width: "100%"}}>
                    <Stack
                        direction="row"
                        alignItems="stretch"
                        justifyContent="center"
                        spacing={10} 
                    >
                        <Box sx={{width: "30%", pt: 4}}>
                            <img 
                                alt="hello-hand"
                                src={TwoHandsImage}
                                style={{height: "100%"}}
                            />
                        </Box>
                        <Stack direction="column" justifyContent="center"
                            sx={{width: "60%", textAlign: "left", minHeight: 150}}
                        >
                            <Typography variant="h4" color="white.main" sx={{fontFamily: "MTSWide", fontWeight: "bold"}}>
                                КОНКУРС «МЫ УСЛЫШИМ»
                            </Typography>
                            <Typography variant="h5" color="white.main" sx={{my: 2, fontFamily: "MTSText", maxWidth: "90%"}}>
                                Поиск лучших практик и сервисов для людей с нарушением слуха
                            </Typography>
                            <Stack direction="row"
                                spacing={2}
                            >
                                <Button variant="contained" size="large"
                                    onClick={() => navigate('/contest')}
                                    sx={{borderRadius: 5, maxWidth: 300, height: 50, fontFamily: "MTSWide", fontWeight: "bold"}}
                                >
                                    ПОДРОБНЕЕ
                                </Button>
                                <Button variant="contained" size="large"
                                    color="navigationBatton"
                                    onClick={() => navigate('/voting')}
                                    sx={{borderRadius: 5, maxWidth: 300, height: 50, fontFamily: "MTSWide", fontWeight: "bold"}}
                                >
                                    ГОЛОСУЙ ЗА ИДЕЮ
                                </Button>
                            </Stack>
                           
                        </Stack>
                    </Stack>
                </Box>
            </Box> 
        </Box>
    )
}

const AboutItem = ({table}) => {

    const navigate = useNavigate()

    const handleClick = () => {
        window._tmr.push({ type: 'reachGoal', id: 3504126, goal: 'click_program'})
        navigate(table.link)
    }

    return (
        <Box sx={{ display: "inline-block", width: 380, bgcolor: "white.main", borderRadius: 10, position: "relative", 
            mb: 2, mx: 1, height: 500}}
        >
            <Stack 
                direction="column"
                alignItems="flex-start"
                spacing={3}
                sx={{p: 4, height: 420}}
            >
                <img 
                    src={table.image}
                    alt={table.title}
                    style={{objectFit: "contain"}}
                />
                <Typography variant="h5" 
                   sx={{fontFamily: "MTSText", fontWeight: "bold"}}
                >
                    {table.title}
                </Typography>
                <Typography 
                    variant="h6" 
                    sx={{
                        fontFamily: "MTSText",
                        textAlign: 'left',
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 4,
                        WebkitBoxOrient: "vertical",
                        height: 300
                    }}
                >
                    {table.details}
                </Typography>
            </Stack>
            <Box sx={{ml: 4, bottom: 0, textAlign: "left"}}>
                <Button variant="contained" color="blackButton"
                    onClick={handleClick}
                    sx={{p: 1.5, borderRadius: 5, fontFamily: "MTSWide", fontWeight: "bold", color: 'white.main'}}
                >
                    ПОДРОБНЕЕ
                </Button>
            </Box>
        </Box>
    )
}

const AboutPanel = () => {

    const tables = [
        {
            image: Heart,
            title: "Конкурс «Мы услышим»",
            details: "Конкурс направлен на поиск идей и решений, которые повысят доступность среды для людей с нарушением слуха",
            link: "/contest"
        },
        {
            image: Cinema,
            title: "Публичный показ кино",
            details: "Бесплатный кинопоказ фильма с сопровождением русского жестового языка",
            link: "/show-cinema"
        },
        {
            image: Case,
            title: "Инклюзивный лекторий",
            details: "Гайд по доступности, образовательные курсы, истории успеха. Всё, что нужно знать бизнесу об инклюзивности",
            link: "/inclusive-lecture"
        }
    ]

    return (
        <Box sx={{maxWidth: MAX_WIDTH, mx: "auto", mt: 6, display: "block"}}>
            <Typography variant="h4" 
                color="black" sx={{textAlign: "left", pl: 2, mb: 2, fontFamily: "MTSWide", fontWeight: "bold"}}
            >
                ПРОГРАММА
            </Typography>
            <Box sx={{
                mt: 4, display: 'flexbox',
            }}>
                {tables.map(table => 
                    <AboutItem 
                        key={table.title}
                        table={table}
                    />    
                )}
            </Box>
           
        </Box>
    )

}

export default function HomePage() {

    const navigate = useNavigate()

    const [open, setOpen] = useState(true)

    useEffect(() => {
        document.title = "Мы услышим • Фестиваль"
    }, []);

    return (
        <Box sx={{bgcolor: 'secondary.light'}}>
            <GeneralBanner
                title="МЫ УСЛЫШИМ"
                detials="Всероссийский инклюзивный фестиваль"
                imgUrl={HomeShell}
            />
            <Box 
                sx={{width: "100%", maxWidth: MAX_WIDTH, mx: "auto", mt: 6}}
            >
               <StreamsPanel />
            </Box>
            <AboutPanel />
            <Box maxWidth={MAX_WIDTH} sx={{mx: "auto", mt: 2}}>
                <NoveltiesPanel />
                <br />
                <ContestBanner />
                <WorkInCompanyPanel />
                <PartnersPanel contestId={1} />
            </Box>
            <ContactPanel maxWidth={MAX_WIDTH}/>
            <br />
        </Box>
    )
}
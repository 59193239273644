import { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Box, Divider, Stack, Typography, Button, IconButton, Snackbar, Alert, CircularProgress } from "@mui/material"
import ClearIcon from '@mui/icons-material/Clear'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton"
import { getActiveContest, postProposal } from "../../../middleware/api"
import CenterBox from "../../../layouts/CenterBox"
import { NominationField, ProposalConfirmField, ProposalDescriptionField, ProposalPurposeField, ProposalLinkField, 
    ProposalTitleField, ProposalTypeField, ReferralField, ProposalSignificanceField, ProposalAboutField, IdeaTitleField, IdeaReferralField, IdeaDescriptionField, IdeaAboutField, IdeaPurposeField } from "./fields"
import { clearProposal, saveProposal } from "../../../strore/competitionSlice"
import { UploadFileBox } from "../../main";
import { useNavigate } from "react-router-dom";
import HelloPanel from "../../main/components/HelloPanel";
import CreateMemberPanel from "../../main/components/CreateMemberPanel";

const Header = ({contestTitle, contestDescription}) => {

    useEffect(() => {
        document.title = "Мы услышим • Подать заявку"
    }, []);

    return (
        <Box sx={{textAlign: "left"}}>
            <Typography variant="h4" color="black" sx={{fontFamily: "MTSText", fontWeight: "bold"}}>
                Конкурс «{contestTitle}»
            </Typography>
            <Typography variant="h6" sx={{mt: 1, fontFamily: "MTSText"}}>
                {contestDescription}
            </Typography>
            <Typography variant="h6" sx={{mt: 1, fontFamily: "MTSText"}}>
                Для участия в конкурсе выберите номинацию и запоните заявку.
            </Typography>
            <Typography variant="h6" sx={{mt: 1, fontFamily: "MTSText"}} color="primary">
                Важно! Участник может подать несколько заявок на Программу признания.
            </Typography>
        </Box>
    )
}

const CreateProposalForm = (props) => {

    const {contestId, nominations, proposalId, setProposalId, setProposalType, currentPage} = props

    const dispatch = useDispatch()

    const isSaved = useSelector(state => state.competition.isSaved)
    const savedNominationId = useSelector(state => state.competition.proposalNominationId)
    const savedTitle = useSelector(state => state.competition.proposalTitle)
    const savedReferral = useSelector(state => state.competition.proposalReferral)
    const savedDescription = useSelector(state => state.competition.proposalDescription)
    const savedAbout = useSelector(state => state.competition.proposalAbout)
    const savedPurpose = useSelector(state => state.competition.proposalPurpose)
    const savedLink = useSelector(state => state.competition.proposalLink)

    const [saved, setSaved] = useState(false)
    const [restored, setRestored] = useState(false)

    const [nominationId, setNominationId] = useState(savedNominationId)
    const [title, setTitle] = useState(savedTitle)
    const [referral, setReferral] = useState(savedReferral)
    const [description, setDescription] = useState(savedDescription)
    const [socialPurpose, setSocialPurpose] = useState(savedPurpose)
    const [about, setAbout] = useState(savedAbout)
    const [link, setLink] = useState(savedLink)

    const [isMember, setMember] = useState(false)

    const [loading, setLoading] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [success, setSuccess] = useState(false)
    const [rejected, setRejected] = useState(false)
    const [warning, setWarning] = useState(false)
    const [errAccording, setErrAccording] = useState(false)
    const [errExisting, setErrExisting] = useState(false)
    const [errSearching, setErrSearching] = useState(false)

    const [errors, setErrors] = useState({
        "nomination": false, "title": false, "referral": false, "description": false,
        "purpose": false, "about": false, "link": false
    })

    let isEmpty = nominationId === "" && title === "" && description === "" && 
        socialPurpose === "" && about === "" ? true : false
    
    let isFilled = nominationId !== "" && title !== "" && description !== "" && 
        socialPurpose !== "" && about !== "" ? true : false

    const isIdea = () => {
        if (nominationId === 2 && referral === "") {
            return true
        }
        return false
    }

    const handleError = (field, value) => {
        let updatedValue = {}
        updatedValue[field] = value
        setErrors(errors => ({
            ...errors,
            ...updatedValue
        }))
    }

    const isError = () => {
        for (const key of Object.keys(errors)) {
            if (errors[key]) {
                return true
            }
        }
        return false
    }

    const handleSave = () => {
        dispatch(saveProposal({
            nominationId, title, referral,
            description, about, socialPurpose, link
        }))
        setSaved(true)
    }

    const handleClear = () => {
        dispatch(clearProposal())
        setNominationId("")
        setTitle("")
        setDescription("")
        setAbout("")
        setSocialPurpose("")
        setLink("")
        setErrors({
            "nomination": false, "title": false, "description": false, "referral": false,
            "purpose": false, "about": false, "link": false, "confirm": false
        })
        setRestored(true)
    }

    const handleSubmit = async () => {
        setLoading(true)

        const formData = new FormData()
        formData.append('contest_id', contestId)
        formData.append('nomination_id', nominationId)
        if (nominationId === 1) {
            formData.append('type', "innovation") 
        } else if (nominationId === 2) {
            formData.append('type', "idea")
            formData.append('referral', referral)
        }
        formData.append('title', title)
        formData.append('description', description)
        formData.append('about', about)
        formData.append('social_purpose', socialPurpose)
        link !== "" && formData.append('link', link)

        try {
            const {data, code} = await postProposal(formData)
            if (code === 201) {
                setProposalId(data.id)
                setProposalType(data.type)
                dispatch(clearProposal())
                setLoaded(true)
                // setSuccess(true)
            }
        } catch(error) {
            switch(error.response?.status) {
                case 404:
                    handleError('nomination', true)
                    setErrSearching(true)
                    break;
                case 405:
                    handleError('title', true)
                    handleError('link', true)
                    setRejected(true)
                    break;
                case 406:
                    handleError('nomination', true)
                    setErrExisting(true)
                    break;
                case 409:
                    handleError('nomination', true)
                    setErrAccording(true)
                    break;
                case 423:
                    setWarning(true)
                    break;
                default:
                    console.log(error.response)
            }
            setLoading(false)
        }
    }

    return (
        <Stack direction="column" 
            spacing={2}
            sx={{textAlign: 'left'}}
        >
            <NominationField 
                nominations={nominations}
                nominationId={nominationId}
                setNominationId={setNominationId}
                required
                disabled={loading}
                error={errors["nomination"]}
                setError={handleError}
            />
            {nominationId === 1 ? <>
                    <ProposalTitleField 
                        title={title}
                        setTitle={setTitle}
                        required
                        disabled={loading}
                        error={errors["title"]}
                        setError={handleError}
                    />
                    <ProposalDescriptionField 
                        description={description}
                        setDescription={setDescription}
                        required
                        disabled={loading}
                        error={errors["description"]}
                        setError={handleError}
                    />
                    <ProposalAboutField 
                        about={about}
                        setAbout={setAbout}
                        required
                        disabled={loading}
                        error={errors['about']}
                        setError={handleError}
                    />
                    <ProposalPurposeField 
                        socialPurpose={socialPurpose}
                        setSocialPurpose={setSocialPurpose}
                        required
                        disabled={loading}
                        error={errors['purpose']}
                        setError={handleError}
                    /> 
                </> : nominationId === 2 &&<>
                    <IdeaTitleField 
                        title={title}
                        setTitle={setTitle}
                        required
                        disabled={loading}
                        error={errors['title']}
                        setError={handleError}
                    />
                    <IdeaReferralField 
                        referral={referral}
                        setReferral={setReferral}
                        required
                        disabled={loading}
                        error={errors['referral']}
                        setError={handleError}
                    />
                    <IdeaDescriptionField 
                        description={description}
                        setDescription={setDescription}
                        required
                        disabled={loading}
                        error={errors['description']}
                        setError={handleError}
                    />
                    <IdeaAboutField 
                        about={about}
                        setAbout={setAbout}
                        required
                        disabled={loading}
                        error={errors['about']}
                        setError={handleError}
                    />
                    <IdeaPurposeField 
                        socialPurpose={socialPurpose}
                        setSocialPurpose={setSocialPurpose}
                        required
                        disabled={loading}
                        error={errors['purpose']}
                        setError={handleError}
                    />
                </>
            }
            <CreateMemberPanel 
                currentPage={currentPage} 
                setMember={setMember}
            />
            <ProposalLinkField 
                link={link}
                setLink={setLink}
                disabled={loading}
                error={errors["link"]}
                setError={handleError}
            />
            {!loaded && <Box>
                <Button onClick={handleSave} size="large" sx={{float: "left", fontFamily: "MTSWide", fontWeight: "bold"}}
                    variant="text" color="primary" disabled={isEmpty}
                >
                    В черновик
                </Button>
                { (isSaved || !isEmpty) &&
                    <IconButton disabled={loading} onClick={handleClear} sx={{float: "left"}}>
                        <ClearIcon />
                    </IconButton>
                }
                <LoadingButton size="large" sx={{float: "right", borderRadius: 10, fontFamily: "MTSWide", fontWeight: "bold"}}
                    variant="outlined" endIcon={<ArrowCircleRightIcon />}
                    disabled={loading || !isFilled || isError() || !isMember || isIdea()} 
                    onClick={handleSubmit}
                >
                    { nominationId === 2 ?
                        "Отправить заявку"
                    :
                        "Загрузить файл"
                    }
                </LoadingButton>
            </Box>}
            <Snackbar
                open={saved} 
                autoHideDuration={6000} 
                onClose={() => setSaved(false)}
            >
                <Alert onClose={() => setSaved(false)} severity="info" sx={{ width: '100%' }}>
                    Вы сохранили заявку в локальное хранилище
                </Alert>
            </Snackbar>
            <Snackbar
                open={restored} 
                autoHideDuration={6000} 
                onClose={() => setRestored(false)}
            >
                <Alert onClose={() => setRestored(false)} severity="info" sx={{ width: '100%' }}>
                    Вы очистили локальное хранилище
                </Alert>
            </Snackbar>
            <Snackbar
                open={success} 
                autoHideDuration={6000} 
                onClose={() => setSuccess(false)}
            >
                <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    Вы успешно подали заявку на фестиваль, теперь можете добавить дополнительную информацию
                </Alert>
            </Snackbar>
            <Snackbar
                open={rejected} 
                autoHideDuration={6000} 
                onClose={() => setRejected(false)}
            >
                <Alert onClose={() => setRejected(false)} severity="error" sx={{ width: '100%' }}>
                    Название проекта или ссылка на дополнительные материалы уже существуют в системе
                </Alert>
            </Snackbar>
            <Snackbar
                open={warning} 
                autoHideDuration={6000} 
                onClose={() => setWarning(false)}
            >
                <Alert onClose={() => setWarning(false)} severity="warning" sx={{ width: '100%' }}>
                    В данный момент заявки на фестиваль не принимаются
                </Alert>
            </Snackbar>
            <Snackbar
                open={errAccording} 
                autoHideDuration={6000} 
                onClose={() => setErrAccording(false)}
            >
                <Alert onClose={() => setErrAccording(false)} severity="error" sx={{ width: '100%' }}>
                    Указанная номинация не соответствует номинациям фестиваля
                </Alert>
            </Snackbar>
            <Snackbar
                open={errExisting} 
                autoHideDuration={6000} 
                onClose={() => setErrExisting(false)}
            >
                <Alert onClose={() => setErrExisting(false)} severity="error" sx={{ width: '100%' }}>
                    У вас уже существует заявка в выбранной номинации
                </Alert>
            </Snackbar>
            <Snackbar
                open={errSearching} 
                autoHideDuration={6000} 
                onClose={() => setErrSearching(false)}
            >
                <Alert onClose={() => setErrSearching(false)} severity="error" sx={{ width: '100%' }}>
                    Фестиваль или номинация не найдены
                </Alert>
            </Snackbar>
        </Stack>
    )
}

function CreateProposalPanel({currentPage, setCurrentPage}) {

    const navigate = useNavigate()
    const uploadFileRef = useRef(null)

    const [confirm, setConfirm] = useState(false)

    const [contestId, setContestId] = useState(null)
    const [contestTitle, setContestTitle] = useState("...")
    const [contestDescription, setContestDescription] = useState("...")
    const [contestStatus, setContestStatus] = useState("published")
    const [nominations, setNominations] = useState([])

    const [proposalId, setProposalId] = useState(null)
    const [proposalType, setProposalType] = useState("single")

    const [loading, setLoading] = useState(false)

    const fetchData = async () => {
        setLoading(true)
        try {
            const {data, code} = await getActiveContest()
            if (code === 200) {
                setContestId(data.id)
                setContestTitle(data.title)
                setContestDescription(data.description)
                setContestStatus(data.status)
                setNominations(data.nominations)
                setLoading(false)
                uploadFileRef.current?.scrollIntoView({ behavior: 'smooth' })
            }
        } catch(error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchData()
        return () => {
            setContestId(null)
            setContestTitle("...")
            setContestStatus("published")
            setNominations([])
        };
    }, []);

    return (
        <Box sx={{mb: 4}}>
            {loading 
                ? <CircularProgress />
                : <Header 
                    contestTitle={contestTitle}
                    contestDescription={contestDescription} 
                />
            }
            <Divider variant="middle" sx={{mt: 1, mb: 2}} />
            {contestStatus === 'acceptance' 
                ? <CreateProposalForm 
                    contestId={contestId}
                    nominations={nominations}
                    proposalId={proposalId}
                    setProposalId={setProposalId}
                    setProposalType={setProposalType}
                    currentPage={currentPage}
                />
                : <Typography>
                    В данный момент заявки не принимаются.
                </Typography>
            }
            {proposalId && <>
                <Box ref={uploadFileRef} sx={{textAlign: 'left', my: 1}}>
                    <Typography variant="h6" color="black"
                        sx={{}}
                    >
                        Презентация вашей творческой работы    
                    </Typography>
                    <Divider variant="middle" sx={{my: 1}} />
                    <Typography variant="subtitle1" color="secondary">
                        Приложите не более 5 слайдов
                    </Typography>
                </Box>
                <UploadFileBox 
                    destination={"document"}
                    ownerId={proposalId}
                />
                <Typography variant="subtitle1" sx={{my: 1}}>
                    Максимально допустимый размер файла 10 мб
                </Typography>
                <ProposalConfirmField 
                    confirm={confirm}
                    setConfirm={setConfirm}
                    required
                    // disabled={loading}
                    // error={errors["confirm"]}
                    // setError={handleError}
                />
                <Button variant='contained'
                    onClick={() => setCurrentPage(1)}
                    size='large' 
                    disabled={!confirm}
                    sx={{borderRadius: 100}}
                >
                    Отправить заявку
                </Button>
            </>}
        </Box>
    )
}

export default CreateProposalPanel

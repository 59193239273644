import { Avatar, Box, Button, Dialog, DialogTitle, Stack, Typography } from "@mui/material";
import { useCallback, useState, useEffect } from "react";
import { getVideos } from "../../../middleware/api";
import VideoIcon from "../../../assets/video.png"
import { LectureItem } from "../InclusiveLecturePage";



const VideoPlayer = ({open, setOpen, videoUrl, videoType, videoTitle}) => {
    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle variant="h5" sx={{fontWeight: "bold"}}>
                {videoTitle}
            </DialogTitle>
            <Box sx={{bgcolor: 'white.main', p: 2}}>
                <video controls
                    width="100%"
                    height="100%"
                >
                    <source 
                        src={videoUrl}
                        type={`video/${videoType}`}
                    />
                </video>
            </Box>
        </Dialog>
    )
}

export {VideoPlayer}

const VideoTable = ({video}) => {

    const [playerOpen, setPlayerOpen] = useState(false)

    return (<>
        <Stack direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            sx={{minHeight: 180,boxShadow: 1, p: 2, 
                bgcolor: "white.main", mb: 2, borderRadius: 4}}
        >
            <img
                alt="video-icon" 
                src={VideoIcon}
            />
            
            <Box sx={{width: "50%"}}>
                <Typography variant="h6" sx={{textAlign: "left"}}>
                    {video.title}
                </Typography>
            </Box>
            <Button variant="contained"
                onClick={() => setPlayerOpen(true)} 
                sx={{bgcolor: "black"}}
            >
                СМОТРЕТЬ
            </Button>
        </Stack>
        <VideoPlayer 
            open={playerOpen}
            setOpen={setPlayerOpen}
            videoUrl={video.objects[0].url}
            videoType={video.objects[0].type}
            videoTitle={video.title}
        />
    </>)
}

export default function EducationVideosPanel() {

    const [videos, setVideos] = useState([])

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    const fetchData = useCallback(async () => {
        try {
            const {data, code} = await getVideos()
            if (code === 200) {
                setVideos(data.videos)
            }
        } catch(error) {
            console.log(error)
            setError(true)
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        document.title = "Мы услышим • Видео-уроки"
        fetchData()
    }, [fetchData]);


    return (
        <Box sx={{}}>
            {videos.map(video => 
                <LectureItem key={video.id}
                    video={video}
                />
            )}
        </Box>
    )
}
import { useEffect, useState } from "react"
import { useDispatch } from 'react-redux'
import { Typography, Stack, Button, Link, Snackbar, Alert, easing, DialogTitle, Box } from "@mui/material"
import LoadingButton from '@mui/lab/LoadingButton'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { sendLogin } from "../../../strore/authSlice";
import { EmailField, PasswordField, UsernameField } from "./fields";

export default function LoginBox({callback}) {

    const dispatch = useDispatch()

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const [loading, setLoading] = useState(false)

    const [errors, setErrors] = useState({
        'email': false, 'password': false
    })

    const [rejectUser, setRejectUser] = useState(false)
    const [rejectEmail, setRejectEmail] = useState(false)
    const [rejectPassword, setRejectPassword] = useState(false)

    const setAllErrors = (value) => {
        setErrors({
            'email': value, 'password': value
        })
    }
  
    const handleError = (field, value) => {
        let updatedValue = {}
        updatedValue[field] = value
        setErrors(errors => ({
            ...errors,
            ...updatedValue
        }))
    }

    useEffect(() => {
        rejectUser ? setAllErrors(true) : setAllErrors(false)
    }, [rejectUser]);

    useEffect(() => {
        rejectEmail ? handleError('email', true) : handleError('email', false)
    }, [rejectEmail]);

    useEffect(() => {
        rejectPassword ? handleError('password', true) : handleError('password', false)
    }, [rejectPassword]);

    const isError = () => {
        for (const key of Object.keys(errors)) {
            if (errors[key]) {
                return true
            }
        }
        return false
    }

    const handleSubmit = async () => {
        setLoading(true)
        const formData = new FormData()
        formData.append('email', email)
        formData.append('password', password)
        try {
            dispatch(sendLogin({
                formData, setRejectUser, setRejectEmail, setRejectPassword, setLoading
            }))
        } catch(error) {
            setLoading(false)
        }
    }

    return (
        <>
            <DialogTitle variant="h3" align="center" mt={-3} mb={2} color="primary"
                sx={{fontFamily: "MTSWide", fontWeight: "bold"}}
            >
                ВОЙТИ
            </DialogTitle>
            <Stack 
                direction='column'
                justifyContent="center"
                alignItems="center"
                spacing={2}
                my={2}
                mx="auto"
                width="85%"
            >
                <Typography variant="h6" sx={{fontFamily: "MTSText"}}>
                    Чтобы войти на сайт, используйте электронную почту и пароль, которые вы 
                    указывали при регистрации. 
                    Или просто <Link underline="hover" onClick={() => callback(2)}>зарегистрируйтесь</Link>.
                </Typography>
                <EmailField
                    email={email}
                    setEmail={setEmail}
                    required
                    disabled={loading}
                    error={errors['email']}
                    setError={handleError}
                />
                <PasswordField 
                    password={password}
                    setPassword={setPassword}
                    required
                    disabled={email === "" || loading}
                    error={errors['password']}
                    setError={handleError}
                />
                <Box sx={{width: "100%", position: "relative", textAlign: "right"}}>
                    <Button
                        disabled={loading}
                        size="small" 
                        sx={{right: 0}}
                        color="secondary"
                        onClick={() => callback(0)}
                    >
                        Забыли пароль?
                    </Button>
                </Box>
                <LoadingButton 
                    onClick={handleSubmit} 
                    disabled={password === "" || isError()}
                    variant="contained" 
                    size="large" 
                    sx={{borderRadius: 5, float: 'right', mb: 2, width: "100%", fontFamily: "MTSWide", fontWeight: "bold"}}
                    endIcon={<ArrowCircleRightIcon />}
                    loadingPosition="end"
                    loading={loading}
                >
                    Войти
                </LoadingButton>
            </Stack>
            <Snackbar 
                open={rejectUser} 
                autoHideDuration={6000} 
                onClose={() => setRejectUser(false)}
            >
                <Alert onClose={() => setRejectUser(false)} severity="warning" sx={{ width: '100%' }}>
                    Пользователь не подвержден. Проверте почту и подтвердите аккаунт
                </Alert>
            </Snackbar>
            <Snackbar 
                open={rejectEmail} 
                autoHideDuration={6000} 
                onClose={() => setRejectEmail(false)}
                >
                    <Alert onClose={() => setRejectEmail(false)} severity="error" sx={{ width: '100%' }}>
                        Пользователь с таким именем не существует
                </Alert>
            </Snackbar>
            <Snackbar 
                open={rejectPassword} 
                autoHideDuration={6000} 
                onClose={() => setRejectPassword(false)}
            >
                <Alert onClose={() => setRejectPassword(false)} severity="error" sx={{ width: '100%' }}>
                    Неверный пароль для такого пользовательского имени
                </Alert>
            </Snackbar>
        </>
    )
}

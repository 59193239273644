import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { getUsers } from "../../../middleware/api";

const UserItem = ({user}) => {
    return (
        <Box sx={{bgcolor: "white.main", width: "100%", textAlign: "left", p: 3, borderRadius: 5}}>
            <Stack direction="column">
                <Typography variant="h6" sx={{fontWeight: "bold"}}>
                    {user.fullname}
                </Typography>
                <Typography variant="body">
                    {user.region.name}
                </Typography>
            </Stack>
        </Box>
    )
}

export default function UsersPanel(currentPage) {

    const [users, setUsers] = useState([])

    const [loading, setLoading] = useState(false)

    const fetchData = useCallback(async () => {
        setLoading(true)
        try {
            const {data, code} = await getUsers()
            if (code === 200) {
                setUsers(data.users)
            }
        } catch(error) {
            console.log(error)
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])
    
    return (
        <Stack direction="column" spacing={2}
            sx={{width: "100%"}}
        >
            {loading ?
                <Box sx={{width: "100%"}}>
                    <CircularProgress />
                </Box>
               
            :
                users.map(user =>
                    <UserItem key={user.id}
                        user={user}
                    />
                )
            }
            {users.map(user =>
                <UserItem key={user.id}
                    user={user}
                />
            )}
        </Stack>
    )
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie'
import { PersistGate } from 'redux-persist/integration/react';
import {QueryClient, QueryClientProvider} from 'react-query'
import posthog from 'posthog-js';
import { PostHogProvider} from 'posthog-js/react'
import store, {persistor} from './strore/index';
import './index.css';
import App from './App';

const client = new QueryClient()

posthog.init(
  process.env.REACT_APP_POSTHOG_KEY, { 
    api_host: process.env.REACT_APP_POSTHOG_HOST
  }
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <CookiesProvider>
            <QueryClientProvider client={client}>
              <PostHogProvider client={posthog}>
                <App />
              </PostHogProvider>
            </QueryClientProvider>
          </CookiesProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
);
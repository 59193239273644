import { Box, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import OneHandImage from "../../../assets/hand.png"
import { leftGradientStyle } from "../HomePage";

export default function ExpertPanel() {
    const userName = useSelector(state => state.auth.fullname)

    return (
        <Box style={leftGradientStyle}
            sx={{borderRadius: 10, minHeight: 150}}
        >
             <Box sx={{display: 'flex', position: "static",
                justifyContent: 'center',
                alignItems: 'center', maxWidth: 1280, mx: 'auto'}}
            >
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }}}>
                    <Stack direction="column" justifyContent="center"
                        alignItems="center" spacing={2} 
                        sx={{}}
                    >
                        <Stack direction="column" justifyContent="center"
                            sx={{width: "90%", textAlign: "left", pt: 4}}
                        >
                            <Typography variant="h4" color='white.main' sx={{fontFamily: "MTSWide", fontWeight: "bold"}}>
                                Здравствуйте, {userName}!
                            </Typography>
                            <Typography variant="h6" color='white.main' sx={{fontFamily: "MTSText"}}>
                                Это личный кабинет эксперта. Здесь вы будете получать заявки на конкурс и оценивать 
                                их согласно критериям
                            </Typography>
                        </Stack>
                        <Box sx={{width: "100%", pt: 4}}>
                            <img 
                                alt="hello-hand"
                                src={OneHandImage}
                                style={{height: "100%"}}
                            />
                        </Box>  
                    </Stack>
                </Box>
                <Box sx={{ ml: 2, flexGrow: 1, display: { xs: 'none', md: 'flex'}, width: "100%"}}>
                    <Stack direction="row" justifyContent="center"
                        alignItems="stretch" spacing={2} 
                        sx={{}}
                    >
                        <Box sx={{width: "30%", pt: 4}}>
                            <img 
                                alt="hello-hand"
                                src={OneHandImage}
                                style={{height: "100%"}}
                            />
                        </Box>
                        <Stack direction="column" justifyContent="center"
                            sx={{width: "70%", textAlign: "left", pr: 5}}
                        >
                            <Typography variant="h4" color='white.main' sx={{fontFamily: "MTSWide", fontWeight: "bold"}}>
                                Здравствуйте, {userName}!
                            </Typography>
                            <Typography variant="h6" color='white.main' sx={{fontFamily: "MTSText"}}>
                                Это личный кабинет эксперта. Здесь вы будете получать заявки на конкурс и 
                                оценивать их согласно критериям
                            </Typography>
                        </Stack>  
                    </Stack>
                </Box>
            </Box> 
        </Box>
    )
}
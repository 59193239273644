import { createTheme } from '@mui/material';
import { ruRU } from '@mui/x-date-pickers/locales';

import MTSWideRegular from './fonts/Wide/MTSWide-Regular.ttf'
import MTSTextRegular from './fonts/Text/MTSText-Regular.ttf'
import MTSCompactRegular from './fonts/Compact/MTSCompact-Regular.ttf'
// import WideMedium from './fonts/Wide/MTSWide-Medium.ttf'


const mtsWideRegular = {
    fontFamily: "MTSWide",
    fontStyle: "regular",
    fontDisplay: 'swap',
    fontWeight: '800',
    src: `
        local('MTSWide'),
        local('MTSWide-regular'),
        url(${MTSWideRegular}) format('ttf')
    `,
    unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

const mtsTextRegular = {
    fontFamily: "MTSWide",
    fontStyle: "regular",
    fontDisplay: 'swap',
    fontWeight: '800',
    src: `
        local('MTSText'),
        local('MTSText-regular'),
        url(${MTSTextRegular}) format('ttf')
    `,
    unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

const mtsCompactRegular = {
    fontFamily: "MTSCompact",
    fontStyle: "regular",
    fontDisplay: 'swap',
    fontWeight: '800',
    src: `
        local('MTSCompact'),
        local('MTSCompact-regular'),
        url(${MTSCompactRegular}) format('ttf')
    `,
    unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}


const mainTheme = createTheme({
    typography: {
        fontFamily: ["MTSWide", "MTSText", "MTSCompact"].join(','),
    },
    step: {
        fontFamily: ["MTSWide", "MTSText", "MTSCompact"].join(','),
    },
    overrides: {
        MuiCssBaseline: {
          '@global': {
            '@font-face': [mtsWideRegular, mtsTextRegular, mtsCompactRegular],
          },
        }
    }, 
    palette: {
        primary: {
            main: "#FF0032",
            dark: "#c90000",
            white: "#gggggg"
        },
        secondary: {
            main: "#6C6C6C",
            dark: "#b5b5b5",
            light: "#f3f3f1"
        },
        white: {
            main: "#FFFFFF"
        },
        grey: {
            main: "#f3f3f1"
        },
        navigationBatton: {
            main: "white",
            dark: "lightgrey",
            light: "#f3f3f1",
            contrastText: '#242105'
        },
        lightblue: "#7a8efb",
        darkpink: "#e9d2e9",
        blackButton: {
            main: "#000000",
            dark: "#FF0032",
            white: "#ffffff"
        },
    },
    ruRU
});

export {mainTheme};

import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, Stack, Avatar} from "@mui/material";
import FilterIcon from '@mui/icons-material/Filter';
import { getNovelties, getObjects } from "../../../middleware/api";
import { leftGradientStyle } from "../HomePage";

const MAX_WIDTH = 860

const prepareObjects = (objects) => {
    
    let preparedObjects = {}
    objects.map(object => preparedObjects[object.position] = object)

    return preparedObjects
}

const NoveltyItem = ({novelty}) => {

    const navigate = useNavigate()

    const [objects, setObjects] = useState({})
    const [total, setTotal] = useState(0)
    const [currentObject, setCurrentObject] = useState(0)
    const [objectsError, setObjectsError] = useState(false)

    const fetchObjects = useCallback(async (noveltyId) => {
        const queryData = {
            'owner_id': noveltyId,
            'destination': "novelty"
        }
        try {
            const {data, code} = await getObjects(queryData)
            if (code === 200) {
                setObjects(prepareObjects(data.objects))
                setTotal(data.total)
            }
        } catch(error) {
            switch(error.response.status) {
                case 404:
                    setObjectsError(true)
                    break;
                default:
                    console.log(error.response)
            }
        }
    }, [])

    useEffect(() => {
        novelty.id && fetchObjects(novelty.id) 
    }, [novelty.id, fetchObjects]);

    return (
        <Box 
            sx={{bgcolor: "white.main", width: 380, height: 590, overflow: 'hidden', textAlign: "left",
                borderRadius: 10, border: 1, borderColor: "secondary.light",  
                flexDirection: 'column', display: "inline-block", m: 1}}
        >
            <Box 
                style={leftGradientStyle}
                sx={{ bgcolor: "lightgrey", width: "100%", height: "50%", position: "relative"}}
            >
                {objects[0]?.url &&
                    <img 
                        src={objects[0]?.url}
                        alt="novelty-object"
                        style={{width: "100%", height: 190, position: 'absolute',  
                        bottom: 0, objectFit: "contain"}}
                    />
                }
             
            </Box>
            <Typography variant="h6" color="black" 
                sx={{mt: 2, mx: 2, px: 2,
                    fontFamily: "MTSText", fontWeight: "bold",
                    overflow: "hidden", 
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical", height: 100
                }}
            >
                {novelty.header}
            </Typography>
            <Typography
                variant="h6"
                sx={{mx: 2, px: 2, fontFamily: "MTSText", 
                    overflow: "hidden", height: 100,
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical"}}
            >
                {novelty.summary.split("[%]")[0]}
            </Typography>
            <Button size="small" variant="contained" color="blackButton"
                onClick={() => navigate(`/news/${novelty.id}`)}
                sx={{width: 120, borderRadius: 5, mt: 1, color: "white.main",
                    ml: 3, p: 1.5, fontFamily: "MTSWide", fontWeight: "bold"
                }}
            >
                Подробнее
            </Button>
        </Box>
    )
}

export {NoveltyItem}

function NoveltiesPanel() {

    const navigate = useNavigate()

    const [novelties, setNovelties] = useState([])

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    const fetchData = useCallback(async () => {
        const queryData = {'page': 1, 'per_page': 3}
        try {
            const {data, code} = await getNovelties(queryData)
            if (code === 200) {
                setNovelties(data.novelties)
                setLoading(false)
            }
        } catch(error) {
            switch(error.response.status) {
                case 403:
                    setError(true)
                    break;
                default:
                    console.log(error.response)
            }
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData]);

    return (
        <Box sx={{}}>
            <Stack direction="row" 
                justifyContent="space-between"
                alignItems="center"
                sx={{mb: 2, mx: 2}}
            >
                <Typography variant="h4" 
                    color="black" 
                    sx={{textAlign: 'left', fontFamily: "MTSWide", fontWeight: "bold"}}
                >
                    НОВОСТИ
                </Typography>
                <Button size="large" variant="outlined"
                    sx={{borderRadius: 10, fontFamily: "MTSWide", fontWeight: "bold"}} 
                    onClick={() => navigate('/news')}
                >
                    Все новости
                </Button>
            </Stack>
            <Box 
                sx={{display: "flexbox"}}
            >
                {novelties.map((novelty, i) => 
                    <NoveltyItem key={i} novelty={novelty} />
                )}
            </Box>
        </Box>
    )
}

export default NoveltiesPanel
import { useState, useRef, useEffect, useCallback } from "react"
import { Alert, Box, CircularProgress, IconButton, LinearProgress, Link, Snackbar, Stack, Tooltip, Typography } from "@mui/material"
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Blank from "../../../assets/blank.svg"
import { deleteObject, getObjects, postObject } from "../../../middleware/api";
import Scrollbars from "react-custom-scrollbars-2";

const ALLOWED_IMAGE = ['jpeg', 'jpg', 'png']
const ALLOWED_DOCUMENT = ['pdf']
const ALLOWED_VIDEO = ['mp4', 'mov', 'wmv', 'webm']
const ALLOWED_SERVICE = ALLOWED_IMAGE

const checkFile = (destination, fullType) => {

    const fileType = fullType.split("/")[1]

    switch(destination) {
        case "service":
            if (ALLOWED_SERVICE.includes(fileType)) {
                return true
            }
            return false
        case "image":
            if (ALLOWED_IMAGE.includes(fileType)) {
                return true
            }
            return false
        case "document":
            if (ALLOWED_DOCUMENT.includes(fileType)) {
                return true
            }
            return false
        case "video":
            if (ALLOWED_VIDEO.includes(fileType)) {
                return true
            }
            return false
        default:
            return false
    }
}


const UploadFileButton = ({loading, dragIsOver, onUpload}) => {

    const inputRef = useRef()

    const handleUpload = (event) => {
        event.target.files[0] && onUpload(event.target.files[0])
    }

    return (
        <IconButton disabled={dragIsOver || loading} onClick={() => inputRef.current.click()} sx={{color: "primary.main"}}> 
            <Tooltip arrow title={
                <Typography variant="subtitle2">
                    Выбрать файл...
                </Typography>
            }>
            {loading ? <CircularProgress size={62} /> : 
                <FileUploadOutlinedIcon  
                    sx={{fontSize: 62, }}
                /> 
            }
            </Tooltip>
            <input 
                hidden
                style={{opacity: 0, width: 0, height: 0}}  
                accept="file/*, png, jpg, jpeg, pdf, mp4, mov, wmv, webm" 
                type="file" 
                multiple
                onChange={handleUpload}
                ref={inputRef}
            />
        </IconButton>
    )
}

const DragAndDropFileZone = ({destination, loading, setLoading, setFile}) => {

    const [dragIsOver, setDragIsOver] = useState(false)
    const [warning, setWarning] = useState(false)

    const handleUpload = (file) => {
    
        if (!checkFile(destination, file['type'])) {
            setWarning(true)
            return
        }

        setLoading(true)
        setFile(file)
    }
    
    const handleDragOver = (event) => {
        if (loading) {
            return
        }
        event.preventDefault()
        setDragIsOver(true)
    }

    const handleDragLeave = (event) => {
        if (loading) {
            return
        }
        event.preventDefault()
        setDragIsOver(false)
    }

    const handleDrop = (event) => {
        if (loading) {
            return
        }
        event.preventDefault()
        const files = event.dataTransfer.files
        files && files[0] && handleUpload(files[0])
        setDragIsOver(false)
    }

   
    return (
        <Box sx={{
            height: 270, width: 270, bgcolor: 'secondary.light', justifyContent: "center", display: 'flex', float: 'left'
        }}>
            <Box 
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                sx={{
                    width: "84%", height: "84%", m: "auto", border: "2px dashed", zIndex: 11,
                    borderColor: dragIsOver ? 'primary.main' : 'secondary.dark', 
                    justifyContent: "center", display: 'flex', textAlign: "center"
                }}
            >
                <Box sx={{ m: 'auto' }}>
                    <UploadFileButton loading={loading} dragIsOver={dragIsOver} onUpload={handleUpload} />
                    <Typography sx={{color: 'secondary.dark'}}>
                        Для загрузки перетяните файлы сюда
                    </Typography> 
                </Box>
            </Box>
            <Snackbar
                open={warning}
                autoHideDuration={6000}
                onClose={() => setWarning(false)}
            >
                <Alert onClose={() => setWarning(false)} severity="warning" sx={{ width: '100%' }}>
                    Недопустимый тип загружаемого файла
                </Alert>
            </Snackbar>
        </Box>
    )
}

const UploadingFile = ({file, uploadingProps, onUpload}) => {

    const {destination, ownerId, callback} = uploadingProps

    const interval = 1
    const [seconds, setSeconds] = useState(interval)
    const [percentage, setPercentage] = useState(0)

    const [uploading, setUploading] = useState(false)

    const [process, setProcess] = useState(false)
    const [warning, setWarning] = useState(false)
    const [rejected,  setRejected] = useState(false)
    const [errorOwner, setErrorOwner] = useState(false)
    const [errorLimit, setErrorLimit] = useState(false)
    const [errorExternal, setErrorExternal] = useState(false)

    const reset = () => {
        setSeconds(interval)
        setPercentage(0)
        setUploading(false)
    }

    const uploadFile = useCallback(async (newFile) => {
        setProcess(true)
        setPercentage(3)

        if (file['size'] / 1024 / 1024 > 10) {
            callback(false)
        }

        const formData = new FormData()
        formData.append('destination', destination)
        setPercentage(6)
        formData.append('owner_id', ownerId)
        setPercentage(9)
        formData.append('file', newFile)
        setPercentage(15)

        setUploading(true)

        try {
            const {code} = await postObject(formData)
            if (code === 201) {
                setUploading(false)
                setPercentage(100)
                callback(true)
                onUpload()
            }
        } catch(error) {
            switch(error.response.status) {
                case 400:
                    setWarning(true)
                    break;
                case 403:
                    setRejected(true)
                    break;
                case 404:
                    setErrorOwner(true)
                    break;
                case 405:
                    break;
                case 409:
                    setErrorLimit(true)
                    break;
                case 503:
                    setErrorExternal(true)
                    break;
                default:
                    console.log(error.response)
            }
            callback(false)
        }
    }, [destination, ownerId, callback, onUpload, file])

    useEffect(() => {
        const timer = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1)
            }
            if (seconds === 0) {
                uploading && setPercentage(percentage + 3)
                setSeconds(interval)
            }
        }, 1000)
        return () => clearInterval(timer)
    }, [uploading, seconds, percentage]);

    useEffect(() => {
        file && uploadFile(file)
        return () => {
            reset()
        };
    }, [file, uploadFile]);

    return (<>
        <Stack direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
            sx={{height: 62, width: "100%"}}
        >
            <Box sx={{mx: 1}}>
                <img 
                    src={Blank}
                    alt="uploading-item"
                    width={20}
                    height={29}
                />
            </Box>
            <Box sx={{height: "100%", width: "100%", textAlign: 'left', mx: 1}}>
                <Box 
                    sx={{width: "100%", display: "flex", mb: 0.5, 
                        textOverflow: "ellipsis", overflow: "hidden"}}>
                    <Typography noWrap color="secondary"
                        sx={{float: 'left', mx: 1, maxWidth: 300}}
                    >
                        {file['name']}
                    </Typography>
                    <Typography color="secondary"
                        sx={{float: 'left', mx: 2}}
                    >
                       ~{(file['size'] / 1024 / 1024).toFixed(1)} MB
                    </Typography>
                </Box>
                <LinearProgress 
                    variant="determinate" value={percentage} 
                />
                <Typography color="secondary"
                    sx={{mx: 1, mt: 0.5}}
                >
                    {percentage}%
                </Typography>
            </Box>
            <Box sx={{mx: 1}}>
                <CircularProgress size={20} color="secondary"/>
            </Box>
        </Stack>
        <Snackbar 
            open={process}
            autoHideDuration={6000}
            onClose={() => setProcess(false)}
        >
            <Alert severity="info"
                onClose={() => setProcess(false)}
                sx={{ width: '100%' }}
            >
                Объект проверяется и загружается
            </Alert>
        </Snackbar>
        <Snackbar 
            open={warning}
            autoHideDuration={6000}
            onClose={() => setWarning(false)}
        >
            <Alert severity="warning"
                onClose={() => setWarning(false)}
                sx={{ width: '100%' }}
            >
                Тип загружаемого объекта не поддерживается
            </Alert>
        </Snackbar>
        <Snackbar 
            open={rejected}
            autoHideDuration={6000}
            onClose={() => setRejected(false)}
        >
            <Alert severity="error"
                onClose={() => setRejected(false)}
                sx={{ width: '100%' }}
            >
                Текущий пользователь не может присвоить объект к данной сущности
            </Alert>
        </Snackbar>
        <Snackbar 
            open={errorOwner}
            autoHideDuration={6000}
            onClose={() => setErrorOwner(false)}
        >
            <Alert severity="error"
                onClose={() => setErrorOwner(false)}
                sx={{ width: '100%' }}
            >
                Указанная сущность не найдена
            </Alert>
        </Snackbar>
        <Snackbar 
            open={errorLimit}
            autoHideDuration={6000}
            onClose={() => setErrorLimit(false)}
        >
            <Alert severity="error"
                onClose={() => setErrorLimit(false)}
                sx={{ width: '100%' }}
            >
                Превышен лимит объектов для сущности
            </Alert>
        </Snackbar>
        <Snackbar 
            open={errorExternal}
            autoHideDuration={6000}
            onClose={() => setErrorExternal(false)}
        >
            <Alert severity="error"
                onClose={() => setErrorExternal(false)}
                sx={{ width: '100%' }}
            >
                Ошибка на стороне хоста хранилища
            </Alert>
        </Snackbar>
    </>)
}

const FileCard = ({object, cb, behaviorDeleting, setBehaviorDeleting}) => {

    const [deleting, setDeleting] = useState(false)

    const handleExplore = () => {
        window.open(object.url, '_blank', 'noreferrer')
    }

    const handleDelete = async () => {

        setBehaviorDeleting(true)
        setDeleting(true)

        try {
            const {code} = await deleteObject(object.id)
            if (code === 201) {
                cb()
                setBehaviorDeleting(false)
            }
        } catch(error) {
            switch(error.response.status) {
                case 403:
                    break;
                case 404:
                    break;
                case 502:
                    break;
                case 503:
                    break;
                default:
                    console.log(error.response)
            }
        }
    }

    return (
        <Stack direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
            sx={{height: 62, width: "100%"}}
        >
            <Box sx={{mx: 1}}>
                <img 
                    src={Blank}
                    alt="uploading-item"
                    width={20}
                    height={29}
                />
            </Box>
            <Box sx={{height: "100%", width: "100%", textAlign: 'left', mx: 1}}>
                <Box sx={{width: "100%", display: "flex", mb: 0.5}}>
                    <Typography color="secondary"
                        sx={{float: 'left', mx: 1}}
                    >
                        <Link color="secondary" underline="hover" onClick={handleExplore}>
                            {object.id}.{object.type}
                        </Link> 
                    </Typography>
                    <Typography color="secondary"
                        sx={{float: 'left', mx: 2}}
                    >
                       ~{(object.size / 1024 / 1024).toFixed(1)} MB
                    </Typography>
                </Box>
                <LinearProgress 
                    variant={deleting ? "indeterminate" : "determinate"}  value={100} 
                />
                <Typography color="secondary"
                    sx={{mx: 1, mt: 0.5}}
                >
                    100%
                </Typography>
            </Box>
            <Box sx={{mx: 1}}>
                {deleting ? <CircularProgress color="secondary" size={22} sx={{mx: 1.1}} /> :
                    <IconButton onClick={handleDelete} disabled={behaviorDeleting}>
                        <CloseIcon />
                    </IconButton>
                }
            </Box>
        </Stack>
    )
}

const FileStack = ({file, destination, ownerId, callback}) => {

    const uploadingProps = {
        destination, ownerId, callback
    }

    const [deleting, setDeleting] = useState(false)
    const [updated, setUpdated] = useState(true)
    const [objects, setObjects] = useState([])
    const [total, setTotal] = useState(0)

    const [rejected, setRejected] = useState(false)
    const [deleted, setDeleted] = useState(false)

    const handleObjectDelete = () => {
        setDeleted(true)
        setUpdated(true)
    }

    const fetchObjects = useCallback(async () => {

        const queryData = {
            'destination': destination,
            'owner_id': ownerId
        }

        try {
            const {data, code} = await getObjects(queryData)
            if (code === 200) {
                let reversedObjects = data.objects
                reversedObjects.reverse()
                setObjects(reversedObjects)
                setTotal(data.total)
                setUpdated(false)
            }
        } catch(error) {
            error.response.status === 404 && setRejected(true)
        }
    }, [destination, ownerId])

    useEffect(() => {
        updated && fetchObjects()
    }, [updated, fetchObjects]);

    return (<>
        <Stack direction='column' 
            sx={{width: "65%", height: "100%"}}
        >
            <Typography variant="body1" 
                sx={{textAlign: "left", ml: 1, mb: 1}} color="text"
            >
                Загрузка файлов {total !== 0 && `(${total} из 5)`}:
            </Typography>
            {file &&
                <UploadingFile 
                    file={file} 
                    uploadingProps={uploadingProps}
                    onUpload={() => setUpdated(true)}
                />
            }
            {!file && total === 0 &&
                <Box sx={{textAlign: 'left'}}>
                    <Typography variant="subtitle2" 
                        color="secondary" 
                        sx={{ml: 1}}
                    >
                        Пока никакие файлы не загружены
                    </Typography>
                </Box>
            }
            <Scrollbars
                autoHide
                style={{ width: "100%", height:  "100%"}}
            >
                {objects.map(object =>
                    <FileCard key={object.id} 
                        object={object} 
                        cb={handleObjectDelete}
                        behaviorDeleting={deleting}
                        setBehaviorDeleting={setDeleting} 
                    />
                )} 
            </Scrollbars>
        </Stack>
        <Snackbar 
            open={rejected}
            autoHideDuration={6000}
            onClose={() => setRejected(false)}
        >
            <Alert severity="error"
                onClose={() => setRejected(false)}
                sx={{ width: '100%' }}
            >
                Указанная сущность не найдена
            </Alert>
        </Snackbar>
        <Snackbar 
            open={deleted}
            autoHideDuration={6000}
            onClose={() => setDeleted(false)}
        >
            <Alert severity="success"
                onClose={() => setDeleted(false)}
                sx={{ width: '100%' }}
            >
                Объект успешно удален
            </Alert>
        </Snackbar>
    </>)
}

function UploadFileBox({destination, ownerId}) {

    const [file, setFile] = useState(null)
    const [loading, setLoading] = useState(false)
    const [errorSize, setErrorSize] = useState(false)
    const [uploaded, setUploaded] = useState(false)

    const handleUpload = (status) => {
        setFile(null)
        setLoading(false)
        status ? setUploaded(true) : setErrorSize(true)
    }

    return (<>
        <Stack direction="row" spacing={1}
            sx={{height: 270, width: "100%"}}>
            <DragAndDropFileZone 
                destination={destination} 
                loading={loading} 
                setLoading={setLoading} 
                setFile={setFile} 
            />
            <FileStack 
                file={file} 
                destination={destination} 
                ownerId={ownerId} 
                callback={handleUpload} 
            />
        </Stack>
        <Snackbar 
            open={uploaded}
            autoHideDuration={6000}
            onClose={() => setUploaded(false)}
        >
            <Alert severity="success"
                onClose={() => setUploaded(false)}
                sx={{ width: '100%' }}
            >
                Объект успешно загружен
            </Alert>
        </Snackbar>
        <Snackbar 
            open={errorSize}
            autoHideDuration={6000}
            onClose={() => setErrorSize(false)}
        >
            <Alert severity="error"
                onClose={() => setErrorSize(false)}
                sx={{ width: '100%' }}
            >
                Превышен максимальный размер файла (10 MB)
            </Alert>
        </Snackbar>
    </>)
}

export default UploadFileBox

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Menu, Button, ButtonGroup, MenuItem } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import AuthDialog from "./AuthDialog";
import { logOut, checkAccess, refreshAccess } from "../../../strore/authSlice";
import { clearProposal } from "../../../strore/competitionSlice";

const AuthedButtonGroup = () => {

    const role = useSelector(state => state.auth.role)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleNavigate = (path) => {
        navigate(path)
        handleClose()
    }

    const handleExit = () => {
        dispatch(clearProposal())
        dispatch(logOut())
    } 

    const interval = 15
    const [seconds, setSeconds] = useState(interval)

    const fullname = useSelector(state => state.auth.fullname)
    const timeToUpdate = useSelector(state => state.auth.timeToUpdate)

    useEffect(() => {
        const timer = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1)
            }
            if (seconds === 0) {
                dispatch(checkAccess())
                setSeconds(interval)
            }
        }, 1000)
        return () => clearInterval(timer)
    }, [seconds, dispatch]);

    useEffect(() => {
        timeToUpdate && dispatch(refreshAccess())
    }, [timeToUpdate, dispatch]);

    return (<>
        <Box display='flex' sx={{alignItems: 'center', justifyContent: 'center'}}>
            <SearchIcon color="primary" fontSize="large" sx={{mx: 2}} />
            <Button
                onClick={handleClick}
                color='primary'
                sx={{color: 'black', borderRadius: 5, p: 1, fontFamily: "MTSText", fontWeight: "bold"}}
            >
                {fullname}
            </Button>
        </Box>
        <Menu
            id="auth_menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            { (role === 'user' || role === 'member') &&
                <MenuItem
                    onClick={() => handleNavigate('/user-area')}
                    sx={{color: 'black', fontFamily: "MTSWide"}}
                >
                    Личный кабинет
                </MenuItem>
            }

            { (role === 'moder' || role === 'admin') &&
                <MenuItem
                    onClick={() => handleNavigate('/moder-area')}
                    sx={{color: 'black', fontFamily: "MTSWide"}}
                >
                    Личный кабинет
                </MenuItem>
            }

            { (role === 'expert') &&
                <MenuItem
                    onClick={() => handleNavigate('/expert-area')}
                    sx={{color: 'black', fontFamily: "MTSWide"}}
                >
                    Личный кабинет
                </MenuItem>
            }
      
            <MenuItem
                onClick={() => handleNavigate('/settings')}
                sx={{color: 'black', fontFamily: "MTSWide"}}
            >
                Настройки
            </MenuItem>
            <MenuItem 
                onClick={handleExit}
                sx={{color: 'primary.main', fontFamily: "MTSWide"}}
            >
                Выйти
            </MenuItem>
        </Menu>
    </>)
}

const NotAuthedButtonGroup = () => {
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState(0);

    const handleClose = () => {
        setOpen(false)
    }

    const handleLogin = () => {
        setValue(1)
        setOpen(true)
    }

    const handleSignup = () => {
        setValue(2)
        setOpen(true)
    }

    return (
        <>
            <Box display='flex' sx={{alignItems: 'center', justifyContent: 'center'}}>
                <SearchIcon color="secondary" fontSize="large" sx={{mx: 2}}/>
                <ButtonGroup color="secondary" variant="text" 
                    aria-label="text button group" sx={{ml: 0.5}}
                >
                    <Button 
                        variant="contained"
                        color="primary"
                        onClick={handleLogin}
                        sx={{boxShadow: 0, borderRadius: 5, fontFamily: "MTSCompact", fontWeight: "bold"}}
                    >
                        Личный кабинет
                    </Button>
                </ButtonGroup>
            </Box>
            <AuthDialog 
                open={open} 
                onClose={handleClose} 
                value={value}
                setValue={setValue}
            />
        </>
    )
}

function AuthButton() {

    const isAuthed = useSelector(state => state.auth.isAuthed)

    return (
        isAuthed 
            ? <AuthedButtonGroup/>
            : <NotAuthedButtonGroup />
    )
}

export default AuthButton

import { useEffect, useState } from "react"
import { Typography, Link, Stack, Grid, Snackbar, Alert, DialogTitle } from "@mui/material"
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import LoadingButton from '@mui/lab/LoadingButton'
import { BirthDateField, ConfirmField, EmailField, NameField, PasswordField, PatronymicField, RegionField, 
    RepeatPasswordField, SurnameField } from "./fields";
import dayjs from 'dayjs'
import { postUser } from "../../../middleware/api";

export default function SignupBox({callback}) {

    const [name, setName] = useState("")
    const [surname, setSurname] = useState("")
    const [patronymic, setPatronymic] = useState("")
    const [birthDate, setBirthDate] = useState(dayjs(""))
    const [regionId, setRegionId] = useState(null)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [repeatPassword, setRepeatPassword] = useState("")
    const [isConfrim, setConfirm] = useState(false)

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [rejected, setRejected] = useState(false)

    const [errors, setErrors] = useState({
        "name": false, "surname": false, "patronymic": false,
        "birthDate": false, "regionId": false, "email": false, 
        "password": false,"repeatPassword": false
    })

    const handleError = (field, value) => {
        let updatedValue = {}
        updatedValue[field] = value
        setErrors(errors => ({
            ...errors,
            ...updatedValue
        }))
    }

    const isError = () => {
        for (const key of Object.keys(errors)) {
            if (errors[key]) {
                return true
            }
        }
        return false
    }

    const resetFields = () => {
        setSurname("")
        setName("")
        setPatronymic("")
        setBirthDate(dayjs(""))
        setRegionId("")
        setEmail("")
        setPassword("")
        setRepeatPassword("")
        setConfirm(false)
    }

    const onSuccess = () => {
        setSuccess(true)
        resetFields()
        setTimeout(() => {callback(1)}, 5000);
    }

    useEffect(() => {
      
    }, [])
    

    const handleSubmit = async () => {
        setLoading(true)

        const formData = new FormData()
        formData.append('name', name)
        formData.append('surname', surname)
        patronymic !== "" && formData.append('patronymic', patronymic)
        formData.append('birth_date', birthDate.format('YYYY-MM-DD'))
        formData.append('region_id', regionId)
        formData.append('email', email)
        formData.append('password', password)

        try {
            const {code} = await postUser(formData)
            if (code === 201) {
                onSuccess()
            }
        } catch(error) {
            if (error.response.status === 409) {
                setLoading(false)
                setSuccess(false)
                handleError('username', true)
                handleError('email', true)
                handleError('contact', true)
                setRejected(true)
            }
        }
    }

    return (<>
        <DialogTitle variant="h3" align="center" mt={-5} mb={2} color="primary"
            sx={{fontFamily: "MTSWide", fontWeight: "bold"}}
        >
            РЕГИСТРАЦИЯ
        </DialogTitle>
        <Stack direction='column'
            justifyContent="center"
            alignItems="center"
            spacing={2}
            mb={2}
            mx="auto"
            width="85%"
        > 
            <Typography variant="h6" sx={{fontFamily: "MTSText"}}>
                Если вы ранее уже зарегистрировались на сайте, воспользуйтесь формой 
                для <Link underline="hover" onClick={() => callback(1)}>входа</Link>.
            </Typography>
            <NameField 
                name={name}
                setName={setName}
                disabled={loading}
                required
                error={errors['name']}
                setError={handleError}
            />
            <SurnameField 
                surname={surname} 
                setSurname={setSurname}
                required 
                disabled={loading}
                error={errors["surname"]}
                setError={handleError}   
            />
            <PatronymicField 
                patronymic={patronymic}
                setPatronymic={setPatronymic}
                disabled={loading}
            />
            <BirthDateField
                birthDate={birthDate}
                setBirthDate={setBirthDate}
                disabled={loading}
                required
                error={errors['birthDate']}
                setError={handleError}
            />
            <RegionField 
                regionId={regionId}
                setRegionId={setRegionId}
                disabled={loading}
                required
            />
            <EmailField 
                email={email}
                setEmail={setEmail}
                disabled={loading}
                required
                error={errors['email']}
                setError={handleError}
            />
            <PasswordField 
                password={password}
                setPassword={setPassword}
                disabled={loading}
                required
                error={errors['password']}
                setError={handleError}
            />
            <RepeatPasswordField
                repeatPassword={repeatPassword}
                setRepeatPassword={setRepeatPassword}
                original={password}
                disabled={loading}
                required
                error={errors['repeatPassword']}
                setError={handleError}
            />
            <ConfirmField 
                confirm={isConfrim}
                setConfirm={setConfirm}
                disabled={repeatPassword === "" || isError() || loading}
                required
            />
            <LoadingButton
                onClick={handleSubmit} 
                disabled={!isConfrim || isError()}
                variant="contained" 
                size="large" 
                sx={{borderRadius: 5, float: 'right', width: "100%", fontFamily: "MTSWide", fontWeight: "bold"}}
                endIcon={<ArrowCircleRightIcon />}
                loadingPosition="end"
                loading={loading}
            >
                Зарегистрироваться
            </LoadingButton>      
        </Stack>
        <Snackbar 
            open={success} 
            autoHideDuration={6000} 
            onClose={() => setSuccess(false)}
        >
            <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
                Вы успешно зарегистрировались, проверте почтовый ящик чтобы подтвердить аккаунт
            </Alert>
        </Snackbar>
        <Snackbar 
            open={rejected} 
            autoHideDuration={6000} 
            onClose={() => setRejected(false)}
        >
            <Alert onClose={() => setRejected(false)} severity="error" sx={{ width: '100%' }}>
                Пользовательское имя, эл. почта или моб.телефон уже есть в системе.
            </Alert>
        </Snackbar>
    </>)
}
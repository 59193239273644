import { useCallback, useEffect, useState } from "react"
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material"
import { NomiantionsPanel, ProposalDialog } from "./ModerProposalsPanel"
import Image from "../../../assets/blank.png"
import { useSelector } from "react-redux"
import { getExpertEstimates } from "../../../middleware/api"

const EstimateItem = ({estimate}) => {

    const [open, setOpen] = useState(false)

    return (<>
        <Box sx={{width: "100%", bgcolor: "white.main", borderRadius: 5, p: 5}}>
            <Stack direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                sx={{width: "100%", height: "100%", minHeight: 100}}
            >
                <Box sx={{textAlign: "left"}}>
                    <Typography>
                        Заявка на участие в конкурсе №{estimate.proposal.id}
                    </Typography>
                    <Typography variant="h6" sx={{fontWeight: "bold"}}>
                        {estimate.proposal.title}
                    </Typography>
                </Box>
                <Typography variant="h5" color="primary.main" sx={{fontWeight: "bold"}}>
                    {estimate.grade}
                </Typography>
            </Stack>
        </Box>
    </>)
}

export default function ExpertMarkPanel() {
    const expertId = useSelector(state => state.auth.userId)
   
    const [nominationId, setNominationId] = useState("")
    const [estimates, setEstimates] = useState([])

    const [loading, setLoading] = useState(false)

    const filterEstimates = useCallback((newEstimates) => {
        let filteredEstimates = []
        newEstimates.map(newEstimate => 
            (newEstimate.proposal.nomination_id === nominationId || nominationId === "") 
            && filteredEstimates.push(newEstimate))
        window.scrollTo({ top: 0, behavior: "smooth" })
        return filteredEstimates
    }, [nominationId])

    useEffect(() => {
        document.title = "Мы услышим • Оцененные заявки"
    }, [nominationId])

    const fetchData = useCallback(async (eId) => {

        setLoading(true)

        const queryData = {status: "closed", per_page: 1000}

        try {
            const {data, code} = await getExpertEstimates(eId, queryData)
            if (code === 200) {
                setEstimates(data.estimates)
            }
        } catch(error) {
            console.log(error)
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        fetchData(expertId)
    }, [expertId, nominationId, fetchData])

    return (
        <Stack direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}

        >
            <Stack direction="row" 
                justifyContent="space-between"

                sx={{width: "100%"}}
            >
                <NomiantionsPanel 
                    nominationId={nominationId}
                    setNominationId={setNominationId}
                />
            </Stack>
            <Stack direction="column"
                spacing={2}
                sx={{width: "100%"}}
            >
                {loading 
                    ? <Box>
                        <CircularProgress />
                    </Box>
                    : <>
                        {filterEstimates(estimates).map((estimate, i) =>
                            <EstimateItem key={i}
                                estimate={estimate}
                            />
                        )}
                    </>
                }
                { filterEstimates(estimates).length === 0 && <>
                    <br />
                    <img 
                        src={Image} 
                        alt="empty-estimates" 
                        height={140}
                        style={{
                            marginLeft: "auto", 
                            marginRight: "auto", 
                            display: "block", 
                            marginTop: 25,
                            objectFit: "fill"
                        }}
                    />
                    <br />
                    <Typography variant="h6" color="secondary.main">
                        У вас пока нет ни одной заявки на рассмотрении
                    </Typography>
                    <br />
                </>}
            </Stack>
        </Stack>
    )  
}
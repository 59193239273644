import { useState } from "react"
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton"
import { Alert, Box, Button, DialogTitle, Snackbar, Stack, Typography } from "@mui/material"
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { EmailField } from "./fields"
import { freezeUser } from "../../../middleware/api";

function FreezeBox({callback}) {

    const [email, setEmail] = useState("")

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [err, setErr] = useState(false)
    const [rejected, setRejected] = useState(false)

    const [errors, setErrors] = useState({
        'email': false
    })

    const isError = () => {
        for (const key of Object.keys(errors)) {
            if (errors[key]) {
                return true
            }
        }
        return false
    }

    const handleError = (field, value) => {
        let updatedValue = {}
        updatedValue[field] = value
        setErrors(errors => ({
            ...errors,
            ...updatedValue
        }))
    }

    const handleSubmit = async () => {
        setLoading(true)
        const formData = new FormData()
        formData.append('email', email)
        
        try {
           const {code} = await freezeUser(formData) 
           if (code === 204) {
                setSuccess(true)
                setEmail("")
                setTimeout(() => {callback(1)}, 5000);
           }
        } catch(error) {
            switch(error.response.status) {
                case 404:
                    setErr(true)
                    break;
                case 409:
                    setRejected(true)
                    break;
                default:
                    console.log(error.response)
            }
            setLoading(false)
            setEmail("")
        }
    }

    return (<>
        <DialogTitle variant="h3" align="center" mt={-3} mb={2} color="primary"
            sx={{fontFamily: "MTSWide", fontWeight: "bold"}}
        >
            ВОССТАНОВЛЕНИЕ ПАРОЛЯ
        </DialogTitle>
        <Stack
            direction='column'
            justifyContent="center"
            alignItems="center"
            spacing={2}
            my={2}
            mx="auto"
            width="85%"
        >
            <EmailField 
                email={email}
                setEmail={setEmail}
                reqired
                disabled={loading}
                error={errors['email']}
                setError={handleError}
            />
            <br />
            <Box sx={{width: "100%", position: "relative", textAlign: "right"}}>
                <Button
                    disabled={loading}
                    size="small" 
                    sx={{right: 0}}
                    color="secondary"
                    onClick={() => callback(1)}
                >
                    Вспомнили пароль?
                </Button>
            </Box>
            <LoadingButton 
                onClick={handleSubmit} 
                disabled={email === "" || isError()}
                variant="contained" 
                size="large" 
                sx={{borderRadius: 5, width: "100%", my: 2, fontFamily: "MTSWide", fontWeight: "bold"}}
                endIcon={<ArrowCircleRightIcon />}
                loadingPosition="end"
                loading={loading}
            >
                Восстановить пароль
            </LoadingButton>
        </Stack>
        <Snackbar 
            open={success} 
            autoHideDuration={6000} 
            onClose={() => setSuccess(false)}
        >
            <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
                Вы заморозили свой аккаунт, проверте почтовый ящик для изменения пароля
            </Alert>
        </Snackbar>
        <Snackbar 
            open={err} 
            autoHideDuration={6000} 
            onClose={() => setErr(false)}
        >
            <Alert onClose={() => setErr(false)} severity="error" sx={{ width: '100%' }}>
                Указанная электронная почта не зарегистрирована
            </Alert>
        </Snackbar>
        <Snackbar 
            open={rejected} 
            autoHideDuration={6000} 
            onClose={() => setRejected(false)}
        >
            <Alert onClose={() => setRejected(false)} severity="error" sx={{ width: '100%' }}>
                Пользователь уже заморожен или удален
            </Alert>
        </Snackbar>
    </>)
}

export default FreezeBox
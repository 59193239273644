import { useState, useEffect, useCallback, useRef, forwardRef, useMemo, memo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Button, AppBar, Container, Toolbar, Menu, MenuItem, 
    Typography, Stack, Avatar, Snackbar, Alert, IconButton, Tooltip } from "@mui/material";
import ListIcon from '@mui/icons-material/List';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CallIcon from '@mui/icons-material/Call';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getActiveContest, getContestExperts, getGuestCriteria, getObjects } from "../../middleware/api";
import { TasksBox, PartnersPanel } from "../main/index"
import StagesPanel from "./components/StagesPanel";
import { ContactPanel, WorkInCompanyPanel } from "../../layouts/Footer";
import { AuthDialog } from "../auth";

import RegulationDocumant from "../../documents/regulations_form.pdf"
import CriteriaDocument from "../../documents/competition_criteria.pdf"

import TestImage from "../../assets/homeshell.png"
import GuestCriteriaBanner from "./components/GuestCriteriaBanner";
import { GeneralBanner } from "../main/HomePage";
import EducationBanner from "../main/components/EducationBanner";

const MAX_WIDTH = 1280

const NominationItem = ({contestId, nomination, object}) => {

    const [criteriaOpen, setCriteriaOpen] = useState(false)

    const [criteria, setCriteria] = useState([])
    const [nominationTitle, setNominationTitle] = useState("")

    const [loaded, setLoaded] = useState(false)

    const fetchData = useCallback(async (cId, nominationId) => {
        const queryData = {
            "contest_id": (cId),
            "nomination_id": nominationId
        }
        try {
            const {data, code} = await getGuestCriteria(queryData)
            if (code === 200) {
                setCriteria(data.criteria)
                setLoaded(true)
            }
        } catch(error) {
            console.log(error.response)
            setLoaded(false)
        }
    }, [])

    useEffect(() => {
      contestId && nomination.id && fetchData(contestId, nomination.id)
      nomination.title && setNominationTitle(nomination.title)
    }, [contestId, nomination, fetchData])
    

    return (
        <Box  
            sx={{bgcolor: 'white.main',  width: "100%", borderRadius: 10,
                overflow: "hidden", position: 'relative', boxShadow: 2}}
        >
            <Box sx={{display: 'flex', position: "static",
                justifyContent: 'center',
                alignItems: 'center', maxWidth: MAX_WIDTH, mx: 'auto'}}
            >
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }}}>
                    <Stack direction="column"
                        alignItems="left"
                        justifyContent="center"
                        spacing={6}
                        sx={{ mx: 3, height: "100%", p: 4, mt: 2}}
                    >
                        <Box>
                            {object?.url &&
                                <img 
                                    src={object?.url}
                                    alt="nomination-image"
                                    style={{width: 200, height: 200, objectFit: "contain"}}
                                />
                            }
                        </Box>
                        <Box>
                            <Typography variant="h5" color="black" sx={{fontFamily: "MTSText", fontWeight: "bold"}}>
                                {nomination.title}
                            </Typography>
                            <Typography color="black" sx={{
                                mt: 2, maxWidth: "100%", fontFamily: "MTSText"}}
                                variant='h6'
                            >
                                {nomination.details}
                            </Typography>
                            <Button size="large" color="secondary"
                                sx={{mt: 2, fontFamily: "MTSText"}} 
                                disabled={!loaded} 
                                onClick={() => setCriteriaOpen(true)}
                            >
                                Показать критерии оценки
                            </Button>
                        </Box>
                    </Stack>
                </Box>
                <Box sx={{ ml: 2, flexGrow: 1, display: { xs: 'none', md: 'flex'}, width: "100%"}}>
                    <Stack direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={6}
                        sx={{ mx: 3, height: "100%", p: 4}}
                    >
                        <Box>
                            {object?.url &&
                                <img 
                                    src={object?.url}
                                    alt="nomination-image"
                                    style={{width: 150, height: 150, objectFit: "contain"}}
                                />
                            }
                        </Box>
                        <Box>
                            <Typography variant="h5" color="black" sx={{fontFamily: "MTSText", fontWeight: "bold"}}>
                                {nomination.title}
                            </Typography>
                            <Typography color="black" sx={{
                                mt: 2, maxWidth: "70%", fontFamily: "MTSText"}}
                                variant='h6'
                            >
                                {nomination.details}
                            </Typography>
                            <Button color="secondary" size="large"
                                sx={{mt: 2, fontFamily: "MTSText"}} 
                                disabled={!loaded} 
                                onClick={() => setCriteriaOpen(true)}
                            >
                                Показать критерии оценки
                            </Button>
                        </Box>
                    </Stack>
                </Box>
            </Box> 
   
           <GuestCriteriaBanner 
                open={criteriaOpen}
                setOpen={setCriteriaOpen}
                criteria={criteria}
                nominationTitle={nominationTitle}
           />
        </Box>
    )
}

const NominationsPanel = ({nominations, objects, contestId}) => {
    return (
        <Box sx={{maxWidth: MAX_WIDTH, mx: "auto", textAlign: "left", my: 2}}>
            <Typography variant="h4" color="black" my={4} mx={2} sx={{fontFamily: "MTSWide", fontWeight: "bold"}}>
                НОМИНАЦИИ
            </Typography>
                <Stack direction="column" 
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={4}
                    maxWidth={MAX_WIDTH}
                >
                    {nominations.map((nomination, i) =>
                        <NominationItem key={i} 
                            contestId={contestId}
                            nomination={nomination} 
                            object={objects[1+i]}
                        />
                    )}
                </Stack>
        </Box>
    )
}

const prepareObjects = (objects) => {
    
    let preparedObjects = {}
    objects.map(object => preparedObjects[object.position] = object)

    return preparedObjects
}

const ExpertItem = ({expert}) => {

    const [objects, setObjects] = useState({})

    const [loading, setLoading] = useState(true)
    const [objectsErr, setObjectsErr] = useState(false)

    const fetchObjects = useCallback(async (expertId) => {
        const queryData = {
            'owner_id': expertId,
            'destination': "image"
        }
        try {
            const {data, code} = await getObjects(queryData)
            if (code === 200) {
                setObjects(prepareObjects(data.objects))
                setLoading(false)
            }
        } catch(error) {
            switch(error.response.status) {
                case 404:
                    setObjectsErr(true)
                    break;
                default:
                    setLoading(false)
            }
        }
    }, [])

    useEffect(() => {
        expert.id && fetchObjects(expert.id)
    }, [expert.id]);

    return (
        <Stack 
            sx={{
                width: 370, 
                display: "inline-block", m: 1, position: "relative",
                height: 370
            }}
        >
            <Box 
                sx={{borderRadius: 4, width: 380, height: 380, 
                     
                    overflow: "hidden", position: "relative"}}
            >
                <Avatar variant="square"
                    src={objects[0]?.url}
                    sx={{width: "100%", height: "100%"}} 
                />
                <Box style={{background: 'linear-gradient(transparent, black)'}}
                    sx={{position: 'absolute', bottom: 0, 
                    width: "100%", textAlign: "left", p: 1.5, minHeight: 150}}
                >
                    <Typography variant="h5" 
                        color="white.main"
                        sx={{my: 1, mx: 2, fontFamily: "MTSText", fontWeight: "bold"}}
                    >
                        {expert.fullname}
                    </Typography>
                    <Typography sx={{mx: 2, fontFamily: "MTSText", color: "white.main", mb: 2,
                         overflow: "hidden",
                         textOverflow: "ellipsis",
                         display: "-webkit-box",
                         WebkitLineClamp: 4,
                         WebkitBoxOrient: "vertical", lineHeight: 1.1
                    }}>
                        {expert.about}
                    </Typography>
                </Box>
            </Box>
        </Stack>
    )
}

const ExpertsPanel = forwardRef(({contestId}, ref) => {

    const [page, setPage] = useState(0)

    const [experts, setExperts] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    const fetchData = useCallback(async () => {
        try {
            const {data, code} = await getContestExperts(contestId)
            if (code === 200) {
                setExperts(data.experts)
                setLoading(false)
            }
        } catch(error) {
            switch(error.response.status) {
                case 404:                    
                    setError(true)
                    break;
                default:
                    setLoading(false)
            }
        }
    }, [contestId])

    useEffect(() => {
        contestId && fetchData()
    }, [contestId, fetchData]);

    const nextPage = () => {
        page === experts.length - 2 ? setPage(0) : setPage(page+1)
    }

    const prevPage = () => {
        page === 0 ? setPage(experts.length - 2) : setPage(page-1)
    }

    return (
        <Box ref={ref} sx={{width: "100%", bgcolor: "secondary.light"}}>
            <Typography variant="h4" color="black" sx={{mb: 2, textAlign: 'left', ml: 2, fontFamily: "MTSWide", fontWeight: "bold"}}>
                ЭКСПЕРТЫ
            </Typography>
            <Stack direction="row" justifyContent="center" >
                <Stack direction="column" justifyContent="center" alignItems="center"
                    sx={{width: 50}}
                >
                    <IconButton onClick={prevPage}
                        disabled={experts.length === 0}
                    >
                        <ArrowBackIosNewIcon />
                    </IconButton>
                </Stack>
                <Box sx={{display: 'flexbox'}}>
                    {experts.slice(page, page+3).map(expert => 
                        <ExpertItem key={expert.id} expert={expert}/>
                    )}
                </Box>
                <Stack direction="column" justifyContent="center" alignItems="center"
                    sx={{width: 50}}
                >
                    <IconButton onClick={nextPage}
                        disabled={experts.length === 0}
                    >
                        <ArrowForwardIosIcon />
                    </IconButton>
                </Stack>
            </Stack>
        </Box>
    )
})

const PreparedDates = ({startDate, endDate}) => {

    const prepareDate = (date) => {
        let dt = date.split('-')
        dt = dt.reverse()
        return dt.join(".")
    }

    return (
        <Typography variant="h6" color="white.main" >
            с {prepareDate(startDate)} по {prepareDate(endDate)}
        </Typography>
    )
}

const DocumentButtonsGroup = () => {

    const documents = {
        regulation: {
            url: RegulationDocumant,
            href: "regulation_form.pdf"
        },
        criteria: {
            url: CriteriaDocument,
            href: "competiotion_criteria.pdf"
        }
    }

    const downloadDocument = (docs) => {
        fetch(docs.url).then((response) => {
            response.blob().then((blob) => {
                let alink = document.createElement("a")
                alink.href = window.URL.createObjectURL(blob)
                alink.click()
                alink.download = docs.href 
                document.body.appendChild(alink)
                alink.click()
                document.body.removeChild(alink)
            })
        })
    }

    const downloadRegulations = () => {
        downloadDocument(documents.regulation)
    }

    const downloadCriteria = () => {
        downloadDocument(documents.criteria)
    }

    return (
        <Stack
            direction='row' 
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{maxWidth: MAX_WIDTH, mx: "auto", my: 6, px: 2}}
        >
            <Button variant="contained"
                color="blackButton"
                onClick={downloadRegulations}
                size="large" 
                sx={{color: "white.main", borderRadius: 5, fontFamily: "MTSWide", fontWeight: "bold"}}
            >
                Скачать положение
            </Button>
            <Button variant="contained"
                onClick={downloadCriteria} 
                size="large" color="blackButton"
                sx={{color: "white.main", borderRadius: 5, fontFamily: "MTSWide", fontWeight: "bold"}}
            >
                Скачать критерии
            </Button>
        </Stack>
    )
}

export default memo(function CurrentContestPage() {

    const isAuthed = useSelector(state => state.auth.isAuthed)

    const startRef = useRef(null)
    const contestRef = useRef(null)
    const expertsRef = useRef(null)
    const rulesRef = useRef(null)
    const partnersRef = useRef(null)
    const contactsRef = useRef(null)

    const navigate = useNavigate()

    const [contestId, setContestId] = useState(null)
    const [title, setTitle] = useState("")
    const [tagline, setTagline] = useState("")
    const [description, setDescriotin] = useState("")
    const [nominations, setNominations] = useState([])
    const [status, setStatus] = useState("")
    const [proposalCount, setProposalCount] = useState(0)
    const [stages, setStages] = useState([])
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")

    const [objects, setObjects] = useState({0: "", 1: "", 2: "", 3: "", 4: "", 5: "", 6: "", 7: "", 8: ""})

    const [dataError, setDataError] = useState(false)
    const [objectsError, setObjectsError] = useState(false)
    const [authError, setAuthError] = useState(false)
    const [value, setValue] = useState(1);
 
    const handleNavigate = () => {
        isAuthed 
            ? navigate('/user-area')
            : setAuthError(true)
    }

    const fetchData = useCallback(async () => {
        try {
            const {data, code} = await getActiveContest()
            if (code === 200) {
                setContestId(data.id)
                setTitle(data.title)
                setTagline(data.tagline)
                setDescriotin(data.description)            
                setNominations(data.nominations)
                setProposalCount(data.proposal_count)
                setStages(data.stages)
                setStatus(data.status)
                setStartDate(data.start_date)
                setEndDate(data.end_date)
               
            }
        } catch(error) {
            switch(error.response.status) {
                case 404:
                    setDataError(true)
                    break;
                default:
                    console.log(error.response)
            }
        }
    }, [])

    const fetchObjects = useCallback(async (ownerId) => {
        const queryData = {
            'owner_id': ownerId,
            'destination': "service"
        }
        try {
            const {data, code} = await getObjects(queryData)
            if (code === 200) {
                setObjects(prepareObjects(data.objects))
            }
        } catch(error) {
            switch(error.response.status) {
                case 404:
                    setObjectsError(true)
                    break;
                default:
                    console.log(error.response)
            }
        }
    }, [])

    useEffect(() => {
        document.title = "Мы услышим • Конкурс"
        fetchData()
    }, [fetchData]);

    useEffect(() => {
        contestId && fetchObjects(contestId)
    }, [contestId, fetchObjects]);

    return (
        <Box sx={{bgcolor: "secondary.light"}}>
            <GeneralBanner
                title={title.toUpperCase()}
                // description={"Приëм заявок до 10 мая"}
                tagline={tagline}
                detials={"Конкурс проектов и идей"}
                imgUrl={objects[0]?.url}
            />
            <Box ref={contestRef}
                sx={{my: 4, textAlign: "left", maxWidth: MAX_WIDTH, mx: "auto", px: 2}}
            >
                <Typography variant="h6" sx={{my: 0, fontFamily: "MTSText"}}>
                    {description}
                </Typography>
            </Box>
            <NominationsPanel 
                nominations={nominations} 
                objects={objects} 
                contestId={contestId}
            />
            <DocumentButtonsGroup />
            {/* <StagesPanel stages={stages} maxWidth={MAX_WIDTH} /> */}
            {/* <Button onClick={handleNavigate}
                variant="contained" color="primary"
                sx={{borderRadius: 50, height: 60, mt: 8, width: 380, fontFamily: "MTSWide", fontWeight: "bold"}} 
            > 
                Подать заявку
            </Button> */}
            <Box
                sx={{maxWidth: MAX_WIDTH,  mx: "auto", mt: 12}}
            >
                <ExpertsPanel ref={expertsRef} contestId={contestId} />
                <PartnersPanel ref={partnersRef} contestId={contestId} />
                <EducationBanner />
                <WorkInCompanyPanel />
                <br />
                <br />
                <ContactPanel maxWidth={MAX_WIDTH} />
            </Box>
         
            <Box sx={{width: "100%", height: 20, bgcolor: "secondary.light"}} />
            <AuthDialog 
                open={authError}
                onClose={() => setAuthError(false)}
                value={value}
                setValue={setValue}
            />
        </Box>
    )
})
import HomePage from "./HomePage";
import ShowCinemaPage from "./ShowCinemaPage";
import InclusiveLecturePage from "./InclusiveLecturePage";
import NewsPage from "./NewsPage"
import UploadFileBox from "./components/UploadFileBox";
import TasksBox from "./components/TasksBox";
import NoveltiesPanel from "./components/NoveltiesPanel";
import NoveltyPage from "./NoveltyPage";
import PartnersPanel from "../competition/components/PartnersPanel";

export {HomePage}
export {ShowCinemaPage}
export {InclusiveLecturePage}
export {NewsPage}
export {NoveltyPage}
export {UploadFileBox}
export {TasksBox}
export {NoveltiesPanel}
export {PartnersPanel}

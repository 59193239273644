import { createSlice } from "@reduxjs/toolkit";

const competitionSlice = createSlice({
    name: "competition",
    initialState: {
        isSaved: false, proposalNominationId: "", proposalTitle: "", proposalReferral: "",
        proposalDescription: "", proposalAbout: "", proposalPurpose: "", 
        proposalLink: ""
    },
    reducers: {
        saveProposal(state, action) {
            const payload = action.payload
            state.proposalNominationId = payload.nominationId
            state.proposalTitle = payload.title
            state.proposalReferral = payload.referral
            state.proposalDescription = payload.description
            state.proposalAbout = payload.about
            state.proposalPurpose = payload.socialPurpose
            state.proposalLink = payload.link
            state.isSaved = true
        },
        clearProposal(state) {
            state.proposalNominationId = ""
            state.proposalTitle = ""
            state.proposalReferral = ""
            state.proposalDescription = ""
            state.proposalAbout = ""
            state.proposalPurpose = ""
            state.proposalLink = ""
            state.isSaved = false
        }
    }
})

export const { saveProposal, clearProposal } = competitionSlice.actions
export default competitionSlice.reducer

import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { getUser, putUser } from '../../middleware/api';
import { updateFullname } from '../../strore/authSlice';
import { Box, Typography, Stack, Divider, Button, Snackbar, Alert } from "@mui/material"
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import LoadingButton from '@mui/lab/LoadingButton'
import { ActivityField, ActivityPlaceField, CityField, ContactField, DeleteUserButton, EmailField, NameField,
    PasswordField, PatronymicField, RegionField, RepeatPasswordField, SurnameField, 
    UsernameField } from "./components/fields";
import CenterBox from '../../layouts/CenterBox';
import HelloPanel from '../main/components/HelloPanel';
import { ContactPanel } from '../../layouts/Footer';

const MAX_WIDTH = 1280

function SettingsPage() {

    const dispatch = useDispatch()
    const userId = useSelector(state => state.auth.userId)

    const [name, setName] = useState("")
    const [surname, setSurname] = useState("")
    const [patronymic, setPatronymic] = useState("")
    const [regionId, setRegionId] = useState(null)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [repeatPassword, setRepeatPassword] = useState("")

    const [empty, setEmpty] = useState(true)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [rejected, setRejected] = useState(false)
    const [warning, setWarning] = useState(false)
    const [error, setError] = useState(false)

    const [errors, setErrors] = useState({
        "region": false, "email": false,
        "password": false, "repeatPassword": false
    })

    const handleError = (field, value) => {
        let updatedValue = {}
        updatedValue[field] = value
        setErrors(errors => ({
            ...errors,
            ...updatedValue
        }))
    }

    const isError = () => {
        for (const key of Object.keys(errors)) {
            if (errors[key]) {
                return true
            }
        }
        return false
    }

    useEffect(() => {
        if (surname === "" && name === "" && patronymic === "" && regionId === null 
        && email === "" && password === "" && repeatPassword === "") {
            setEmpty(true)
        } else {
            setEmpty(false)
        }
    }, [surname, name, patronymic, regionId, 
        email, password, repeatPassword
    ]);

    const prepareName = (fullname) => {
        const fname = fullname.split(" ")
        const firstname = fname[1]
        const lastname = fname[0]
        let patro = ""
        if (fname.length === 3) {
            patro = fname[2]
        } 
       return {firstname, lastname, patro}
    }

    const fetchData = useCallback(async (uId) => {
        try {
            const {data, code} = await getUser(uId)
            if (code === 200) {
                const {firstname, lastname, patro} = prepareName(data.fullname)
                setSurname(lastname)
                setName(firstname)
                setPatronymic(patro)
                setRegionId(data.region.id)
                setEmail(data.email)
                setPassword("")
                setRepeatPassword("")
            }
        } catch(error) {

        }
    })

    useEffect(() => {
        userId && fetchData(userId)
    }, [userId])
    

    useEffect(() => {
        document.title = "Настройки пользователя"
    }, []);

    const resetFields = () => {
        setSurname("")
        setName("")
        setPatronymic("")
        setRegionId(null)
        setEmail("")
        setPassword("")
        setRepeatPassword("")
    }

    const onSuccess = () => {
        setSuccess(true)
        resetFields()
        setLoading(false)
        fetchData(userId)
        // setTimeout(() => {callback()}, 5000);
    }

    const handleSubmit = async () => {
        setLoading(true)

        const formData = new FormData()
        surname !== "" && formData.append('surname', surname)
        name !== "" && formData.append('name', name)
        patronymic !== "" && formData.append('patronymic', patronymic)
        regionId && formData.append('region_id', regionId)
        // email !== "" && formData.append('email', email)
        password !== "" && formData.append('password', password)

        try {
            const {data, code} = await putUser(userId, formData)
            if (code === 201) {
                dispatch(updateFullname(data.fullname))
                onSuccess()
            }
        } catch(error) {
            switch(error.response.status) {
                case 403:
                    handleError('surname', true)
                    handleError('name', true)
                    setWarning(true)
                    break;
                case 404:
                    handleError('surname', true)
                    handleError('region', true)
                    setError(true)
                    break;
                case 409:
                    email !== "" && handleError('email', true)
                    setRejected(true)
                    break;
                default:
                    console.log(error)
            }
            setSuccess(false)
            setLoading(false)
        }
    }

    return (
        <Box sx={{bgcolor: 'secondary.light'}} >
            <Box maxWidth={MAX_WIDTH} sx={{mx: "auto"}}>
                <br />
                <HelloPanel />
                <br />
                <Divider variant='middle' textAlign="left" sx={{my: 1}}>
                    <Typography color="secondary">
                        Личные данные
                    </Typography>
                </Divider>
                <Stack spacing={2} sx={{textAlign: "left", mx: 2}}>
                    <NameField 
                        name={name}
                        setName={setName}
                        disabled={loading}
                        error={errors['name']}
                        setError={handleError}
                    />
                    <SurnameField 
                        surname={surname} 
                        setSurname={setSurname}
                        disabled={loading}
                        error={errors["surname"]}
                        setError={handleError}   
                    />
                    <PatronymicField 
                        patronymic={patronymic}
                        setPatronymic={setPatronymic}
                        disabled={loading}
                    />
                    <RegionField 
                        regionId={regionId}
                        setRegionId={setRegionId}
                        disabled={loading}
                    />
                    <EmailField 
                        email={email}
                        setEmail={setEmail}
                        disabled={true || loading}
                        error={errors['email']}
                        setError={handleError}
                    />
                    <PasswordField 
                        password={password}
                        setPassword={setPassword}
                        disabled={loading}
                        error={errors['password']}
                        setError={handleError}
                    />
                    <RepeatPasswordField
                        repeatPassword={repeatPassword}
                        setRepeatPassword={setRepeatPassword}
                        original={password}
                        disabled={password === "" || loading}
                        error={errors['repeatPassword']}
                        setError={handleError}
                    />
                </Stack>
                <Box sx={{height: 80}}>
                    <Button
                        onClick={resetFields}
                        variant='text'
                        size="large" 
                        sx={{float: 'left', mt: 2}}
                        disabled={empty}
                    >
                        Сбросить
                    </Button>
                    <LoadingButton
                        onClick={handleSubmit} 
                        disabled={isError() || empty}
                        variant="outlined" 
                        size="large" 
                        sx={{borderRadius: 5, float: 'right', mt: 2}}
                        endIcon={<ArrowCircleRightIcon />}
                        loadingPosition="end"
                        loading={loading}
                    >
                        Изменить
                    </LoadingButton>
                </Box>
                <ContactPanel />
                <br />
                <Snackbar 
                    open={success} 
                    autoHideDuration={6000} 
                    onClose={() => setSuccess(false)}
                >
                    <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
                        Вы успешно изменили свои пользовательские данные
                    </Alert>
                </Snackbar>
                <Snackbar 
                    open={rejected} 
                    autoHideDuration={6000} 
                    onClose={() => setRejected(false)}
                >
                    <Alert onClose={() => setRejected(false)} severity="error" sx={{ width: '100%' }}>
                        Пользовательское имя, эл. почта или моб.телефон уже есть в системе.
                    </Alert>
                </Snackbar>
                <Snackbar 
                    open={warning} 
                    autoHideDuration={6000} 
                    onClose={() => setWarning(false)}
                >
                    <Alert onClose={() => setWarning(false)} severity="warning" sx={{ width: '100%' }}>
                        Вы не имеете права доступа изменять данные этого пользователя
                    </Alert>
                </Snackbar>
                <Snackbar 
                    open={error} 
                    autoHideDuration={6000} 
                    onClose={() => setError(false)}
                >
                    <Alert onClose={() => setError(false)} severity="warning" sx={{ width: '100%' }}>
                        Указанный вами пользователь не существует 
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
    )
}

export default SettingsPage